import { createAsyncThunk } from '@reduxjs/toolkit';
import mainRequest from '../../utils/mainRequestUtils';

export const leaderboardRequest = {
  getData: createAsyncThunk(
    'leaderboard/getData',
    async function (_, { rejectWithValue }) {
      try {
        const response = await mainRequest.get('/user-points/global-leaderboard');

        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
};
