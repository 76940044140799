import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import mascotAirdrop from '../../../assets/images/mascot/mascotAirdrop.svg';
import { GuideFieldTypes } from '../../../core/constants/Guide';
import { PageNames } from '../../../core/constants/PageNames';
import { StatusTypes } from '../../../core/constants/StatusTypes';
import { STATIC_TEXT } from '../../../core/constants/staticText';
import { useRequestStaticText } from '../../../core/hooks/useRequestStaticText';
import {
  modalWindowState,
  showGuideInfoWindow,
} from '../../../core/store/slices/modalWindowStateSlice';
import { ModalWithBorderShadow, ModalWithClose } from '../../ui/modals/windows';
import s from './style.module.scss';

export const displayedGuideInfoFields = [
  GuideFieldTypes.Partner,
  GuideFieldTypes.Description,
  GuideFieldTypes.Date,
  GuideFieldTypes.Reward,
  GuideFieldTypes.Total,
  GuideFieldTypes.Investor,
  GuideFieldTypes.PartnerLink,
];
const staticText = STATIC_TEXT[PageNames.GuideInfo];

export function GuideInfoPopup({ id }) {
  const dispatch = useDispatch();

  const { text, textStatus } = useRequestStaticText(PageNames.GuideInfo);
  const { modalGuideInfo } = useSelector(modalWindowState);

  return (
    <>
      {modalGuideInfo.opened && (
        <ModalWithClose
          Component={ModalWithBorderShadow}
          className={s.guideInfo}
          onClose={() => dispatch(showGuideInfoWindow(false))}
          closeClassName={s.guideInfo__btnClose}>
          <div className={s.guideInfo__container}>
            <div className={s.guideInfo__imgContainer}>
              <img src={mascotAirdrop} className={s.guideInfo__img} alt={'mascot'} />
            </div>
            <div
              className={cn(
                s.info,
                textStatus === StatusTypes.Loading && s.info__loading
              )}>
              {textStatus === StatusTypes.Loading && (
                <div className={cn(s.info__loader, 'iconLoader')} />
              )}
              {text && (
                <>
                  <div className={s.info__title}>
                    {text?.[`q${id}`]?.[GuideFieldTypes.Title]}
                  </div>
                  {displayedGuideInfoFields.map((field) => (
                    <p key={field} className={s.info__text}>
                      <span className={s.info__grey}>{`${
                        text?.[field] || staticText[field]
                      }:`}</span>
                      {field !== GuideFieldTypes.PartnerLink && (
                        <span>{text?.[`q${id}`]?.[field]}</span>
                      )}
                      {field === GuideFieldTypes.PartnerLink && (
                        <a
                          href={text[`q${id}`]?.[field]}
                          target={'_blank'}
                          className={s.info__link}
                          rel='noreferrer'>
                          {text?.[`q${id}`]?.[field]}
                        </a>
                      )}
                    </p>
                  ))}
                </>
              )}
            </div>
          </div>
        </ModalWithClose>
      )}
    </>
  );
}
