export const PageNames = {
  Hello: 'Hello',
  Auth: 'Auth',
  Metamask: 'Metamask',
  MailAuthorization: 'MailAuthorization',
  Header: 'Header',
  Profile: 'Profile',
  ProfileSettings: 'ProfileSettings',
  ProfileWallet: 'ProfileWallet',
  GuidesGallery: 'GuidesGallery',
  GuidesChat: 'GuidesChat',
  GuideInfo: 'GuideInfo',
  GuideResults: 'GuideResults',
  Claim: 'Claim',
  ClaimReward: 'ClaimReward',
  History: 'History',
  TransactionStatus: 'TransactionStatus',
  SocialsOauth: 'SocialsOauth',
  Support: 'Support',
  ReferralLink: 'ReferralLink',
  TemporaryMobileError: 'TemporaryMobileError',
  PageNotFound: 'PageNotFound',
  Referrals: 'Referrals',
  Reward: 'Reward',
  Game: 'Game',
  Navigation: 'Navigation',
  GamePopup: 'GamePopup',
};

Object.freeze(PageNames);
