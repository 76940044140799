import cn from 'classnames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthorizationTypes } from '../../core/constants/AuthorizationTypes';
import { PageNames } from '../../core/constants/PageNames';
import { ScreenSizes } from '../../core/constants/Size';
import { MetamaskConnectionStatuses } from '../../core/constants/StatusTypes';
import { STATIC_TEXT } from '../../core/constants/staticText';
import { isMobileDevice } from '../../core/helpers/isMobileDevice';
import { useCheckAuthorization } from '../../core/hooks/useCheckAuthorization';
import { useRequestStaticText } from '../../core/hooks/useRequestStaticText';
import { useScreenSizes } from '../../core/hooks/useScreenSizes';
import { allAuth, setCurrentAuthorizationType } from '../../core/store/auth/slice';
import { setMetamaskConnectionStatus } from '../../core/store/metamask/slice';
import { showAuthorizationWindow } from '../../core/store/slices/modalWindowStateSlice';
import { ModalWithBorderShadow, ModalWithClose } from '../ui/modals/windows';
import { AllAuthorizaitions } from './AllAuthorizaitions';
import { MetamaskView } from './Metamask';
import s from './style.module.scss';

const displayedAuthTypes = [
  AuthorizationTypes.NotChosen,
  AuthorizationTypes.AuthTwitter,
  AuthorizationTypes.AuthGoogle,
  AuthorizationTypes.AuthTelegram,
  AuthorizationTypes.AuthConnectWallet,
];

const metamaskAuthTypes = [
  AuthorizationTypes.AuthMetaMask,
  AuthorizationTypes.LinkMetamask,
];

export function Auth() {
  const dispatch = useDispatch();
  const { text } = useRequestStaticText(PageNames.Auth);
  const { currentAuthorizationType } = useSelector(allAuth);
  const { isUserAuthorized } = useCheckAuthorization();
  const { height } = useScreenSizes();

  const onClose = () => {
    if (currentAuthorizationType === AuthorizationTypes.AuthMetaMask) {
      dispatch(setMetamaskConnectionStatus(MetamaskConnectionStatuses.Connecting));
    }
    if (
      !isUserAuthorized ||
      currentAuthorizationType === AuthorizationTypes.LinkMetamask
    ) {
      dispatch(showAuthorizationWindow(false));
    }
  };

  useEffect(() => {
    if (!AuthorizationTypes.LinkMetamask) {
      dispatch(setCurrentAuthorizationType(AuthorizationTypes.NotChosen));
    }

    return () => {
      dispatch(setCurrentAuthorizationType(AuthorizationTypes.NotChosen));
      dispatch(setMetamaskConnectionStatus(MetamaskConnectionStatuses.Connecting));
    };
  }, []);

  return (
    <ModalWithClose
      Component={ModalWithBorderShadow}
      onClose={onClose}
      closeClassName={cn(s.auth__btnClose)}
      className={s.auth}
      style={
        isMobileDevice() && window.innerWidth <= ScreenSizes.M
          ? { ...{ height: height + 'px' } }
          : null
      }>
      {text && (
        <>
          {(displayedAuthTypes.includes(currentAuthorizationType) ||
            currentAuthorizationType === AuthorizationTypes.AuthMail) && (
            <AuthHeader text={text} />
          )}
          {displayedAuthTypes.includes(currentAuthorizationType) && (
            <AllAuthorizaitions />
          )}
          {metamaskAuthTypes.includes(currentAuthorizationType) && (
            <MetamaskView authType={currentAuthorizationType} />
          )}
        </>
      )}
    </ModalWithClose>
  );
}

export function AuthHeader({ text }) {
  return (
    <>
      <div className={s.auth__header}>
        <div className={s.auth__header_row}>
          <div className={s.auth__space} />
          <div className={s.auth__title}>
            {text.title || STATIC_TEXT[PageNames.Auth].title}
          </div>
        </div>
      </div>
    </>
  );
}
