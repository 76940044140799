import { createAsyncThunk } from '@reduxjs/toolkit';
import mainRequest from '../../utils/mainRequestUtils';

export const userRequest = {
  getCurrentLanguage: createAsyncThunk(
    'user/getCurrentLanguage',
    async function (_, { rejectWithValue }) {
      try {
        const response = await mainRequest.get('/user/lang');

        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  getPoints: createAsyncThunk('user/getPoints', async function (_, { rejectWithValue }) {
    try {
      const response = await mainRequest.get('/user-points');

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }),
  changeLanguage: createAsyncThunk(
    'user/changeLanguage',
    async function (lang, { rejectWithValue }) {
      try {
        const response = await mainRequest.get(`/user/update-language/${lang}`);

        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  changeUserName: createAsyncThunk(
    'user/changeUserName',
    async function ({ userName }, { rejectWithValue }) {
      try {
        const response = await mainRequest.get(`/user/update-name/${userName}`);

        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  getUserData: createAsyncThunk(
    'user/getUserData',
    async function (_, { rejectWithValue }) {
      try {
        const response = await mainRequest.get('/user/account');

        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  getReferralData: createAsyncThunk(
    'user/getReferralData',
    async function (_, { rejectWithValue }) {
      try {
        const response = await mainRequest.get('/user/referral-reward');

        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  getGuidesData: createAsyncThunk(
    'user/getGuidesData',
    async function (_, { rejectWithValue }) {
      try {
        const response = await mainRequest.get('/user/quest-reward');

        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  preferredSocialNetwork: createAsyncThunk(
    'authorization/preferredSocialNetwork',
    async function (socialNetwork, { rejectWithValue }) {
      try {
        const response = await mainRequest.get('/user/preference/' + socialNetwork);

        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
};
