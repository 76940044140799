import { createSlice } from '@reduxjs/toolkit';
import { ItemNum } from '../../constants/ItemNum';
import { LocalStorageItems } from '../../constants/LocalStorageItems';
import { StatusTypes, UserReferralStatus } from '../../constants/StatusTypes';
import { LANGUAGES } from '../../constants/languages';
import { userRequest } from './thunk';

const DEFAULT_LANGUAGE = 'EN';

const initialState = {
  language:
    localStorage[LocalStorageItems.Language] ||
    (LANGUAGES.find(
      (lang) => lang.locale === navigator.language.split('-')[ItemNum.One]
    ) &&
      navigator.language.split('-')[ItemNum.One]) ||
    DEFAULT_LANGUAGE,
  languageStatus: null,
  languageError: null,
  userName: null,
  userGuidesData: {},
  userReferralData: {},
  userData: {},
  userDataStatus: null,
  userDataError: null,
  userPoints: null,
  userPointsStatus: null,
  userReferral: UserReferralStatus.Referral,
  status: null,
  error: null,
};

const handlePending = ({ state }) => {
  state.status = StatusTypes.Loading;
  state.error = null;
};

const handleFulfilled = ({ state }) => {
  state.status = StatusTypes.Resolved;
};

const handleRejected = ({ state, action }) => {
  state.status = StatusTypes.Rejected;
  state.error = action.payload.message;
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem(LocalStorageItems.Language, action.payload);
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    clearUserStatus: (state) => {
      state.status = null;
    },
    resetUserState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(userRequest.getCurrentLanguage.pending, (state) => {
        state.languageStatus = StatusTypes.Resolved;
        state.languageError = null;
      })
      .addCase(userRequest.getCurrentLanguage.fulfilled, (state, action) => {
        state.languageStatus = StatusTypes.Resolved;
        state.language = action.payload.language;
        localStorage.setItem(LocalStorageItems.Language, action.payload.language);
      })
      .addCase(userRequest.getCurrentLanguage.rejected, (state, action) => {
        state.languageStatus = StatusTypes.Rejected;
        state.languageError = action.payload.message;
      })
      .addCase(userRequest.getPoints.pending, (state) => {
        state.userPointsStatus = StatusTypes.Resolved;
        state.error = null;
      })
      .addCase(userRequest.getPoints.fulfilled, (state, action) => {
        state.userPointsStatus = StatusTypes.Resolved;
        state.userPoints = action.payload.points;
      })
      .addCase(userRequest.getPoints.rejected, (state, action) => {
        state.userPointsStatus = StatusTypes.Rejected;
        state.error = action.payload.message;
      })
      .addCase(userRequest.changeLanguage.pending, (state) => handlePending({ state }))
      .addCase(userRequest.changeLanguage.fulfilled, (state, action) => {
        handleFulfilled({ state });
        state.language = action.meta.arg;
        localStorage.setItem(LocalStorageItems.Language, action.meta.arg);
      })
      .addCase(userRequest.changeLanguage.rejected, (state, action) =>
        handleRejected({ state, action })
      )
      .addCase(userRequest.changeUserName.pending, (state) => handlePending({ state }))
      .addCase(userRequest.changeUserName.fulfilled, (state, action) => {
        handleFulfilled({ state });
        state.userName = action.meta.arg;
      })
      .addCase(userRequest.changeUserName.rejected, (state, action) =>
        handleRejected({ state, action })
      )
      .addCase(userRequest.getUserData.pending, (state) => {
        state.userDataStatus = StatusTypes.Loading;
        state.userDataError = null;
      })
      .addCase(userRequest.getUserData.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.userDataStatus = StatusTypes.Resolved;
      })
      .addCase(userRequest.getUserData.rejected, (state, action) => {
        state.userDataStatus = StatusTypes.Rejected;
        state.userDataError = action.payload.message;
      })
      .addCase(userRequest.getReferralData.pending, (state) => handlePending({ state }))
      .addCase(userRequest.getReferralData.fulfilled, (state, action) => {
        handleFulfilled({ state });
        state.userReferralData = action.payload;
      })
      .addCase(userRequest.getReferralData.rejected, (state, action) =>
        handleRejected({ state, action })
      )
      .addCase(userRequest.getGuidesData.pending, (state) => handlePending({ state }))
      .addCase(userRequest.getGuidesData.fulfilled, (state, action) => {
        handleFulfilled({ state });
        state.userGuidesData = action.payload;
      })
      .addCase(userRequest.getGuidesData.rejected, (state, action) =>
        handleRejected({ state, action })
      )

      .addCase(userRequest.preferredSocialNetwork.pending, (state) =>
        handlePending({ state })
      )
      .addCase(userRequest.preferredSocialNetwork.fulfilled, (state) => {
        handleFulfilled({ state });
      })
      .addCase(userRequest.preferredSocialNetwork.rejected, (state, action) =>
        handleRejected({ state, action })
      );
  },
});

export const { setLanguage, setUserName, resetUserState, clearUserStatus } =
  userSlice.actions;

/**
 *
 * @param {*} state
 * @returns {initialState}
 */
export const userState = (state) => state.user;
export const currentUserLanguage = (state) => state.user.language;

export default userSlice.reducer;
