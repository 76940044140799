import { createSlice } from '@reduxjs/toolkit';
import { StatusTypes } from '../../constants/StatusTypes';

const initialState = {
  savedStaticAudio: [],
};

export const staticAudioSlice = createSlice({
  name: 'staticAudio',
  initialState,
  reducers: {
    resetStaticAudioState: () => initialState,
    removeUnusedStaticAudio: (state, action) => {
      const statusState = 'staticAudioStatus' + action.payload;
      const audioState = 'staticAudio' + action.payload;
      const errorState = 'staticAudioError' + action.payload;

      state[statusState] = null;
      state[audioState] = null;
      state[errorState] = null;
    },
  },

  extraReducers: (builder) => {
    const firstItem = 0;

    builder
      .addMatcher(
        (action) => /staticAudio\/.*\/pending$/.test(action.type),
        (state, action) => {
          const stateName = action.meta.arg[firstItem].match(/[^_.]*/)[firstItem];
          const errorState = 'staticAudioError' + stateName;
          const statusState = 'staticAudioStatus' + stateName;

          state[statusState] = StatusTypes.Loading;
          state[errorState] = null;
        }
      )
      .addMatcher(
        (action) => /staticAudio\/.*\/fulfilled$/.test(action.type),
        (state, action) => {
          const stateName = action.meta.arg[firstItem].match(/[^_.]*/)[firstItem];
          const audioState = 'staticAudio' + stateName;
          const statusState = 'staticAudioStatus' + stateName;

          state[statusState] = StatusTypes.Resolved;
          state[audioState] = action.payload;
        }
      )
      .addMatcher(
        (action) => /staticAudio\/.*\/rejected$/.test(action.type),
        (state, action) => {
          const stateName = action.meta.arg[firstItem].match(/[^_.]*/)[firstItem];
          const errorState = 'staticAudioError' + stateName;
          const statusState = 'staticAudioStatus' + stateName;

          state[statusState] = StatusTypes.Rejected;
          state[errorState] = action.payload;
        }
      );
  },
});

export const { resetStaticAudioState, removeUnusedStaticAudio } =
  staticAudioSlice.actions;
export const staticAudioState = (state) => state.staticAudio;

export default staticAudioSlice.reducer;
