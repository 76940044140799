import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { modalWindowState } from '../../core/store/slices/modalWindowStateSlice';
import { Auth } from '../Auth';
import { FallingCoins } from '../animated/FallingCoins';
import { ClaimRewardPopup } from '../popups/ClaimRewardPopup.jsx';
import { GamePopup } from '../popups/GamePopup/GamePopup';
import { GuideInfoPopup } from '../popups/GuideInfoPopup';
import { ReferralLinkPopup } from '../popups/ReferralLinkPopup';
import { RewardPopup } from '../popups/RewardPopup';

const ANIMATION_DURATION = 8000;

export function PageWrapper({ children }) {
  const {
    modalAuthorization,
    modalReferralLink,
    modalGuideInfo,
    modalReward,
    modalClaim,
    modalGamePopup,
  } = useSelector(modalWindowState);

  const [showCoinRain, setShowCoinRain] = useState(false);

  useEffect(() => {
    if (showCoinRain) {
      const timer = setTimeout(() => {
        setShowCoinRain(false);
      }, ANIMATION_DURATION);

      return () => clearTimeout(timer);
    }
  }, [showCoinRain]);

  return (
    <div>
      {children}
      {modalAuthorization && <Auth />}
      {modalReferralLink && <ReferralLinkPopup />}
      {modalGuideInfo.opened && modalGuideInfo.id && (
        <GuideInfoPopup id={modalGuideInfo.id} />
      )}
      {modalClaim.opened && modalClaim.id && (
        <ClaimRewardPopup guideId={modalClaim.id} rewardChainId={modalClaim.chainId} />
      )}
      {modalReward.opened && modalReward.id && (
        <RewardPopup onStartAnimation={() => setShowCoinRain(true)} id={modalReward.id} />
      )}
      {showCoinRain && <FallingCoins />}
      {modalGamePopup && <GamePopup />}
    </div>
  );
}
