import { createSlice } from '@reduxjs/toolkit';
import { AuthorizationTypes } from '../../constants/AuthorizationTypes';
import { ErrorTypes, ErrorTypesToMessages } from '../../constants/ErrorMessages';
import { LocalStorageItems } from '../../constants/LocalStorageItems';
import { StatusTypes } from '../../constants/StatusTypes';

const initialState = {
  authStatus: null,
  linkStatus: null,
  getMessageStatus: null,
  status: null,
  errorType: null,
  currentAuthorizationType: AuthorizationTypes.NotChosen,
  token: localStorage.getItem(LocalStorageItems.AuthorizationToken),
  idTokenGoogle: null,
  signMessage: null,
  isReadyToSignMessage: false,
};

const processError = ({ payload }) => {
  const errorType = payload?.type;
  const errorMessage = payload?.errorMessage || '';

  return errorType === ErrorTypes.WALLET_ALREADY_EXISTS
    ? errorType
    : Object.keys(ErrorTypes).find((type) =>
        errorMessage.includes(ErrorTypesToMessages[type])
      ) || 'ERROR';
};

const getStatusField = (actionType) => {
  if (actionType.includes('getIdTokenGoogle') || actionType.includes('getSignMessage')) {
    return 'status';
  } else if (
    actionType.includes('google') ||
    actionType.includes('twitter') ||
    actionType.includes('telegram') ||
    actionType.includes('connectWallet') ||
    actionType.includes('telegramDesktop')
  ) {
    return 'authStatus';
  } else if (
    actionType.includes('linkGoogle') ||
    actionType.includes('linkTwitter') ||
    actionType.includes('linkTelegram') ||
    actionType.includes('linkWallet') ||
    actionType.includes('linkTelegramDesktop') ||
    actionType.includes('linkTelegramByBot')
  ) {
    return 'linkStatus';
  }
};

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setCurrentAuthorizationType: (state, action) => {
      state.currentAuthorizationType = action.payload;
    },
    setAuthorizationToken: (state, action) => {
      state.token = action.payload;
    },
    setReadyToSignMessage: (state, action) => {
      state.isReadyToSignMessage = action.payload;
    },
    clearAuthorizationToken: (state) => {
      state.token = null;
    },
    clearStatusesAndErrors: (state) => {
      state.status = null;
      state.linkStatus = null;
      state.authStatus = null;
      state.errorType = null;
    },
    clearSignMessage: (state) => {
      state.signMessage = null;
    },
  },
  extraReducers: (builder) => {
    const pendingMatcher = (action) => action.type.endsWith('/pending');

    const fulfilledMatcher = (action) => action.type.endsWith('/fulfilled');

    const rejectedMatcher = (action) => action.type.endsWith('/rejected');

    builder
      .addMatcher(pendingMatcher, (state, action) => {
        const statusField = getStatusField(action.type);
        state[statusField] = StatusTypes.Loading;
        state.errorType = null;
      })
      .addMatcher(fulfilledMatcher, (state, action) => {
        const statusField = getStatusField(action.type);
        state[statusField] = StatusTypes.Resolved;
        if (action.type.includes('getIdTokenGoogle')) {
          state.idTokenGoogle = action.payload;
        } else if (action.type.includes('getSignMessage')) {
          state.signMessage = action.payload;
        } else if (
          action.type.includes('google') ||
          action.type.includes('twitter') ||
          action.type.includes('telegram') ||
          action.type.includes('connectWallet') ||
          action.type.includes('telegramDesktop')
        ) {
          state.token = action.payload.token;
        }
      })
      .addMatcher(rejectedMatcher, (state, action) => {
        const statusField = getStatusField(action.type);
        state[statusField] = StatusTypes.Rejected;
        state.errorType = processError({ payload: action.payload });
      });
  },
});

export const {
  setCurrentAuthorizationType,
  setAuthorizationToken,
  setReadyToSignMessage,
  clearAuthorizationToken,
  clearStatusesAndErrors,
  clearSignMessage,
} = authorizationSlice.actions;
export const allAuth = (state) => state.authorization;
export const authToken = (state) => state.authorization.token;

export default authorizationSlice.reducer;
