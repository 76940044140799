import cn from 'classnames';
import s from './style.module.scss';

/*
 backgrounds
   - primary (transparent)
   - secondary (gradient)
   - black 

 borderColor
   - primary (gold)
   - secondary (grey)
   custom:
   - green
   - red
*/
const DEFAULT_BORDER_RADIUS = 16;

function ContentPanel({
  className,
  background = 'primary',
  borderColor = 'primary',
  borderRadius = DEFAULT_BORDER_RADIUS,
  children,
}) {
  return (
    <div
      className={cn(
        s.panel,
        background === 'secondary' && s.bgSecondary,
        background === 'black' && s.bgBlack,
        borderColor === 'secondary' && s.borderSecondary,
        borderColor === 'green' && s.borderGreen,
        borderColor === 'red' && s.borderRed,
        className
      )}
      style={{ borderRadius: `${borderRadius}px` }}>
      {children}
    </div>
  );
}

export default ContentPanel;
