import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusTypes } from '../constants/StatusTypes';
import { TEXT_KEYS } from '../constants/textKeys';
import { removeUnusedStaticText } from '../store/staticText/slice';
import { getStaticText } from '../store/staticText/thunk';
import { userState } from '../store/user/slice';
import { useStaticText } from './useStaticText';

export function useRequestStaticText(pageName) {
  const dispatch = useDispatch();
  const { text, textStatus } = useStaticText(pageName);
  const { language, languageStatus } = useSelector(userState);

  useEffect(() => {
    if (language && languageStatus !== StatusTypes.Loading) {
      dispatch(getStaticText.basic({ keys: TEXT_KEYS[pageName], lang: language }));
    }

    return () => {
      dispatch(removeUnusedStaticText(pageName));
    };
  }, [language, languageStatus]);

  return {
    text,
    textStatus,
  };
}
