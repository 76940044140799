import cn from 'classnames';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useDisconnect } from 'wagmi';
import { ReactComponent as SettingsIcon } from '../../../../assets/images/menu/settings.svg';
import menuImg from '../../../../assets/images/menuImg.png';
import { PageNames } from '../../../../core/constants/PageNames';
import { RoutesName } from '../../../../core/constants/Routes';
import { CookieNames } from '../../../../core/constants/cookies';
import { STATIC_TEXT } from '../../../../core/constants/staticText';
import {
  clearLocalStorageItems,
  clearRestLocalStorageItems,
} from '../../../../core/helpers/clearLocalStorage';
import { removeCookie } from '../../../../core/helpers/cookie';
import { clearAuthorizationToken } from '../../../../core/store/auth/slice';
import { resetMetamaskState } from '../../../../core/store/metamask/slice';
import { resetUserState } from '../../../../core/store/user/slice';
import { LanguageSelector } from '../../../language/LanguageSelector';
import { Button } from '../../../ui/buttons/Button';
import s from './style.module.scss';

const menuItems = {
  accountSettings: {
    type: 'menuAccountSettings',
    Icon: SettingsIcon,
    route: RoutesName.ProfileSettings,
  },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

export function MenuBurger({ className, text }) {
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onLogoutClick = () => {
    disconnect();
    clearLocalStorageItems();
    clearRestLocalStorageItems();
    removeCookie(CookieNames.SessionID);

    dispatch(clearAuthorizationToken());
    dispatch(resetMetamaskState());
    dispatch(resetUserState());
    navigate(RoutesName.Root);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={menuRef} className={cn(s.menu, className)}>
      <motion.div
        whileTap={{ scale: 0.97 }}
        className={cn(s.menu__img)}
        role='button'
        onClick={toggleMenu}>
        <img src={menuImg} alt={'menu image'} />
      </motion.div>
      {isMenuOpen && (
        <>
          <motion.ul
            className={cn(s.menu__list)}
            animate={isMenuOpen ? 'open' : 'closed'}
            initial='closed'
            variants={{
              open: {
                clipPath: 'inset(-10% 0% -100% 0% round 10px)',
                transition: {
                  type: 'spring',
                  bounce: 0,
                  duration: 0.5,
                  delayChildren: 0.3,
                  staggerChildren: 0.05,
                },
              },
              closed: {
                clipPath: 'inset(10% 50% 90% 50% round 10px)',
                transition: {
                  type: 'spring',
                  bounce: 0,
                  duration: 0.3,
                },
              },
            }}>
            {Object.entries(menuItems).map(([key, item]) => (
              <motion.li
                variants={itemVariants}
                key={key}
                role='button'
                onClick={toggleMenu}>
                {item.route && (
                  <Link className={cn(s.menu__item, s.item, s.item__row)} to={item.route}>
                    <MenuItem item={item} itemKey={key} text={text} />
                  </Link>
                )}
              </motion.li>
            ))}
            <motion.li variants={itemVariants} key='lang'>
              <LanguageSelector
                className={cn(s.menu__item, s.item, s.item__row)}
                onCloseMenu={() => setIsMenuOpen(!isMenuOpen)}
              />
            </motion.li>
            <motion.div variants={itemVariants} className={s.menu__logout}>
              <Button
                className={cn(s.menu__logout__button, 'iconLogout_after')}
                onClick={onLogoutClick}>
                {text?.buttonLogout || STATIC_TEXT[PageNames.Header].buttonLogout}
              </Button>
            </motion.div>
          </motion.ul>
        </>
      )}
    </div>
  );
}

function MenuItem({ item, text }) {
  return (
    <>
      <div className={s.menu__item_main}>
        <div className={s.item__icon}>
          <item.Icon />
        </div>
        <div className={s.item__title}>
          {text?.[item.type] || STATIC_TEXT[PageNames.Header][item.type]}
        </div>
      </div>
    </>
  );
}
