import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Web3 from 'web3';
import { AuthorizationTypes } from '../../../core/constants/AuthorizationTypes';
import { MetamaskConnectionStatuses } from '../../../core/constants/StatusTypes';
import useLocationQuery from '../../../core/helpers/useLocationQueryHelper';
import {
  metamaskStore,
  setFirstHighlightedItem,
  setMetamaskMessage,
} from '../../../core/store/metamask/slice';
import { metamaskRequest } from '../../../core/store/metamask/thunk';
import s from './style.module.scss';

const firstItem = 0;
const secondItem = 1;

export function MetamaskConnection({ text, authType }) {
  const dispatch = useDispatch();
  const web3Ref = useRef(new Web3(window.ethereum));
  const { mentorId } = useLocationQuery();
  const { connectionStatus, message, signedMessage } = useSelector(metamaskStore);

  useEffect(() => {
    if (connectionStatus === MetamaskConnectionStatuses.Connecting) {
      dispatch(metamaskRequest.getAccount(web3Ref.current));
      dispatch(setFirstHighlightedItem(secondItem));
    } else if (connectionStatus === MetamaskConnectionStatuses.Sign) {
      const randomMessage = crypto.randomUUID();

      dispatch(setMetamaskMessage(randomMessage));
      dispatch(metamaskRequest.signMessage(web3Ref.current));
      dispatch(setFirstHighlightedItem(secondItem));
    } else if (connectionStatus === MetamaskConnectionStatuses.Connected) {
      const authorizationData = {
        signature: signedMessage,
        uuid: message,
      };

      dispatch(setFirstHighlightedItem(firstItem));
      if (authType === AuthorizationTypes.LinkMetamask) {
        dispatch(metamaskRequest.linkMetamask(authorizationData));
      } else {
        dispatch(metamaskRequest.sendAuthData({ data: authorizationData, mentorId }));
      }
    }
  }, [connectionStatus]);

  return <div className={s.statusText}>{text}</div>;
}
