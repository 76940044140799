import { createAsyncThunk } from '@reduxjs/toolkit';
import { collectSearchParams } from '../../helpers/collectSearchParams';
import mainRequest, { mainPublicRequest } from '../../utils/mainRequestUtils';

export const guideRequest = {
  guidesGallery: createAsyncThunk(
    'showcase/guidesGallery',
    async function (_, { rejectWithValue }) {
      try {
        const response = await mainRequest.get('/quest/user');

        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
  guidesAvailable: createAsyncThunk(
    'showcase/guidesAvailable',
    async function (_, { rejectWithValue }) {
      try {
        const response = await mainRequest.get('/quest/available');

        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
  guidesArchived: createAsyncThunk(
    'showcase/guidesArchived',
    async function ({ language }, { rejectWithValue }) {
      try {
        const response = await mainPublicRequest.get(
          `/quest/archived?languageCode=${language}`
        );

        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
  guidesAll: createAsyncThunk(
    'showcase/guidesAll',
    async function (_, { rejectWithValue, getState }) {
      const state = getState();
      const lang = state.user.language;
      const param = collectSearchParams({ language: lang || 'EN' });

      try {
        const response = await mainPublicRequest.get(`/quest/all?${param}`);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
  getData: createAsyncThunk('showcase/getData', async function (_, { rejectWithValue }) {
    try {
      const response = await mainRequest.get('/showcase');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }),

  updatePoints: createAsyncThunk(
    'showcase/updatePoints',
    async function ({ points }, { rejectWithValue }) {
      try {
        const requestData = {
          points,
        };
        const response = await mainRequest.post(
          '/user-points/add-user-points',
          requestData
        );

        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
  getResultsById: createAsyncThunk(
    'showcase/getResultsById',
    async function (id, { rejectWithValue }) {
      try {
        const response = await mainPublicRequest.get('/quest-winners/' + id);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
};
