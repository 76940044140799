import indonesia from '../../assets/images/flag/flagBi.svg';
import china from '../../assets/images/flag/flagCn.svg';
import eng from '../../assets/images/flag/flagEng.svg';
import spain from '../../assets/images/flag/flagEs.svg';
import russia from '../../assets/images/flag/flagRu.svg';

export const LanguageCodes = {
  English: 'EN',
  Indonesian: 'ID',
  Chinese: 'ZH',
  Russian: 'RU',
  Spanish: 'ES',
};

export const LANGUAGES = [
  { lang: 'English', icon: eng, locale: LanguageCodes.English, shortLabel: 'Eng' },
  {
    lang: 'Bahasa Indonesia',
    icon: indonesia,
    locale: LanguageCodes.Indonesian,
    shortLabel: 'Indo',
  },
  { lang: '中文', icon: china, locale: LanguageCodes.Chinese, shortLabel: '中' },
  { lang: 'Русский', icon: russia, locale: LanguageCodes.Russian, shortLabel: 'Рус' },
  { lang: 'Español', icon: spain, locale: LanguageCodes.Spanish, shortLabel: 'Esp' },
];
