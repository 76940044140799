import axios from 'axios';
import { LocalStorageItems } from '../constants/LocalStorageItems';
import { RoutesName } from '../constants/Routes';
import { StatusCode } from '../constants/StatusTypes';
import { CookieNames } from '../constants/cookies';
import {
  clearLocalStorageItems,
  clearRestLocalStorageItems,
} from '../helpers/clearLocalStorage';
import { getCookie, removeCookie } from '../helpers/cookie';

const isPublic = true;

function createMainRequest(isPublic = false) {
  const mainRequest = axios.create();

  mainRequest.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  mainRequest.defaults.headers.common['Accept'] = 'application/json';
  mainRequest.defaults.headers.common['Content-Type'] = 'application/json';

  mainRequest.interceptors.request.use((request) => {
    if (!isPublic) {
      const sessionId = getCookie(CookieNames.SessionID);
      if (sessionId) {
        request.headers['X-Session-ID'] = sessionId;
      }
      request.headers.Authorization = `Bearer ${localStorage.getItem(
        LocalStorageItems.AuthorizationToken
      )}`;
    }

    return request;
  });

  mainRequest.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response && error.response.status === StatusCode.Unauthorized) {
        clearLocalStorageItems();
        clearRestLocalStorageItems();
        removeCookie(CookieNames.SessionID);
        window.location.href = RoutesName.Root;
      }

      return Promise.reject(error);
    }
  );

  return mainRequest;
}

const mainRequest = createMainRequest();
export const mainPublicRequest = createMainRequest(isPublic);

export default mainRequest;
