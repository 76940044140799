export const AudioVolume = {
  Off: 0,
  On: 1,
};

export const AudioRate = {
  Normal: 1,
  Medium: 1.5,
  Fast: 2,
};

export const AudioState = {
  Pause: 0,
  Play: 1,
  Stop: 2,
};

export const AudioAutoplay = {
  Off: 0,
  On: 1,
};

Object.freeze(AudioState);
Object.freeze(AudioRate);
Object.freeze(AudioVolume);
