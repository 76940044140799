import pdfFile from './../../assets/documents/Terms.pdf';
import s from './style.module.scss';

export function TermsAndConditions() {
  return (
    <main className={s.content}>
      <object className={s.content_object} data={pdfFile} type='application/pdf'>
        <p>
          Your browser does not support PDFs. <a href={pdfFile}>Download the PDF</a>.
        </p>
      </object>
    </main>
  );
}
