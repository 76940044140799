import { createAsyncThunk } from '@reduxjs/toolkit';
import { staticTextHelper } from '../../helpers/staticTextHelper';
import { mainPublicRequest } from '../../utils/mainRequestUtils';

export const getStaticText = {
  basic: createAsyncThunk(
    'staticText/basic',
    async function ({ keys, lang }, { rejectWithValue }) {
      const data = { keys, language: lang || 'EN' };

      try {
        const response = await mainPublicRequest.post(
          '/translation/text',
          JSON.stringify(data)
        );

        return staticTextHelper.convertKeys(response.data);
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
  helloPage: createAsyncThunk(
    'staticText/helloPage',
    async function (_, { rejectWithValue }) {
      try {
        const response = await mainPublicRequest.post('/translation/hello-page');

        return {
          text: staticTextHelper.convertKeysHelloPage(response.data.text),
          audio: response.data.voice,
        };
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
};
