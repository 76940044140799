export const Socials = {
  Google: 'Google',
  Twitter: 'Twitter',
  Telegram: 'Telegram',
};

export const googleAuthWindowParams = {
  redirect_uri: `${process.env.REACT_APP_BASE_URL_CLIENT}${process.env.REACT_APP_GOOGLE_REDIRECT_URI}`,
  client_id: atob(process.env.REACT_APP_GOOGLE_CLIENT_ID),
  access_type: 'offline',
  response_type: 'code',
  prompt: 'consent',
  scope: [
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/userinfo.email',
  ].join(' '),
};

export const googleAuthRequestParams = {
  client_id: atob(process.env.REACT_APP_GOOGLE_CLIENT_ID),
  client_secret: atob(process.env.REACT_APP_GOOGLE_SECRET_KEY),
  redirect_uri: `${process.env.REACT_APP_BASE_URL_CLIENT}${process.env.REACT_APP_GOOGLE_REDIRECT_URI}`,
  grant_type: 'authorization_code',
};

Object.freeze(Socials);
Object.freeze(googleAuthWindowParams);
Object.freeze(googleAuthRequestParams);
