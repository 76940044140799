export const ItemNum = {
  First: 0,
  Second: 1,
  Third: 2,
  One: 1,
  Two: 2,
  Three: 3,
  Last: 1,
  None: 0,
  Hexidecimal: 16,
};

Object.freeze(ItemNum);
