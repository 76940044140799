import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SocialConnectionTypes } from '../../../core/constants/AuthorizationTypes';
import { LocalStorageItems } from '../../../core/constants/LocalStorageItems';
import { setWindowOptions } from '../../../core/helpers/forWindow';
import { isMobileDevice } from '../../../core/helpers/isMobileDevice';
import { useCheckAuthorization } from '../../../core/hooks/useCheckAuthorization';
import { setAuthorizationToken } from '../../../core/store/auth/slice';
import { authRequest } from '../../../core/store/auth/thunk';
import { showAuthorizationWindow } from '../../../core/store/slices/modalWindowStateSlice';
import { userRequest } from '../../../core/store/user/thunk';

const randomStringLength = 11;

export function TelegramAuthProvider({
  children,
  setAuthType,
  type = SocialConnectionTypes.AUTH,
}) {
  const dispatch = useDispatch();
  const { isUserAuthorized } = useCheckAuthorization();
  const [isWebsocketConnecting, setIsWebsocketConnecting] = useState(false);

  const redirectToTelegramAuthorization = async () => {
    setIsWebsocketConnecting(true);
    if (!isUserAuthorized) {
      setConnection().then((websocket) => {
        const mentorId = localStorage.getItem(LocalStorageItems.MentorID);
        const mentorIdParam = mentorId ? 'm=' + mentorId : '';
        const mobileMark = isMobileDevice() ? '=' : '';
        const randomString = 'a' + generateRandomString(randomStringLength);

        websocket.send(randomString);

        const telegramAuthParams = {
          domain: process.env.REACT_APP_TELEGRAM_BOT_NAME,
          start: randomString + mentorIdParam,
        };
        const searchParams = new URLSearchParams(telegramAuthParams);

        window.open(
          `${process.env.REACT_APP_TELEGRAM_BOT_URL}?${searchParams}${mobileMark}`,
          '_self',
          setWindowOptions
        );
      });
    } else {
      // need to add websocket on server
      // setConnection().then((websocket) => {
      dispatch(authRequest.getTelegramLink()).then((response) => {
        const tgUrl = response.payload;

        if (tgUrl) {
          // const urlParams = new URLSearchParams(tgUrl.split('?')[ItemNum.One]);
          // const startParam = urlParams.get('start');

          // websocket.send(startParam);

          window.open(tgUrl, '_self', setWindowOptions);
        }
      });
      // });
    }
  };

  function setConnection() {
    return new Promise(function (resolve, reject) {
      const websocket = new WebSocket(process.env.REACT_APP_TELEGRAM_WEBSOCKET);
      websocket.onopen = function () {
        setIsWebsocketConnecting(false);
        resolve(websocket);
      };

      websocket.onerror = () => {
        setIsWebsocketConnecting(false);
        reject();
      };

      websocket.onmessage = (e) => {
        if (!isUserAuthorized) {
          dispatch(setAuthorizationToken(e.data));
          dispatch(showAuthorizationWindow(false));
        } else {
          dispatch(userRequest.getUserData());
        }
      };
    });
  }

  function generateRandomString(length) {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';

    for (let i = 0; i < length; i++) {
      result += letters.charAt(Math.floor(Math.random() * letters.length));
    }

    return result;
  }

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          disabled: isWebsocketConnecting,
          onClick: () => {
            redirectToTelegramAuthorization();
            if (!isUserAuthorized && type === SocialConnectionTypes.AUTH) {
              dispatch(setAuthType());
            }
          },
        });
      })}
    </>
  );
}
