import { createAsyncThunk } from '@reduxjs/toolkit';
import mainRequest from '../../utils/mainRequestUtils';

export const rewardsRequest = {
  claim: createAsyncThunk(
    'rewards/claim',
    async function ({ token, questId }, { rejectWithValue }) {
      try {
        const requestData = {
          questId,
          captchaToken: token,
        };
        const response = await mainRequest.post('/rewards/claim', requestData);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data.type);
      }
    }
  ),
  getRewards: createAsyncThunk(
    'rewards/getRewards',
    async function ({ questId }, { rejectWithValue }) {
      try {
        const response = await mainRequest.get(`/rewards/${questId}`);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data.type);
      }
    }
  ),
  signClaimRewards: createAsyncThunk(
    'rewards/signClaimRewards',
    async function ({ questId, token, walletAddress }, { rejectWithValue }) {
      try {
        const response = await mainRequest.post(
          `/claim/questId/${questId}/airdrop`,
          null,
          {
            params: { token, wallet: walletAddress },
          }
        );

        return response.data;
      } catch (error) {
        console.error('[CLAIM:AIRDROP] Error : ', error);

        return rejectWithValue(error.response.data.type);
      }
    }
  ),
  updateTransactionStatus: createAsyncThunk(
    'rewards/updateTransactionStatus',
    async function ({ guideId, token, status }, { rejectWithValue }) {
      try {
        const response = await mainRequest.post(
          `/claim/questId/${guideId}/status`,
          null,
          {
            params: { token, status },
          }
        );

        return response.data;
      } catch (error) {
        console.error('[CLAIM:AIRDROP] Error : ', error);

        return rejectWithValue(error.response.data.type);
      }
    }
  ),
};
