export const Size = {
  X: 'x',
  M: 'm',
  L: 'l',
  Xl: 'xl',
};

export const ScreenSizes = {
  XXL: 1440,
  XL: 1280,
  L: 1024,
  M: 768,
  S: 480,
  XS: 360,
};

Object.freeze(Size);
