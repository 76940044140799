import { linea, polygon, sepolia } from 'wagmi/chains';
import { bobChain } from '../core/constants/networks';

// contractName by chainId to contractAddress
export default {
  referralProgram: {
    [sepolia.id]: '0x7EdE8834A412bDd0b73e53f08d56e46f23389a6B',
    [bobChain.id]: '0x0CAA4aFcce0A53B5f478e84CcBd6cAcA6660b6cA',
    [polygon.id]: '0x7a4b9a2EcDa88d57b9C8a8732eE17faf0CDdf99F',
    [linea.id]: '0x7a4b9a2EcDa88d57b9C8a8732eE17faf0CDdf99F',
  },
  firstProjectVault: {
    [sepolia.id]: '0x879d429776e9744C8fD7de8eCc8360ecB0665467',
    [bobChain.id]: '0x75Fc4B7D9d1F6354c0d6d39EEb76119F0584bc98',
    [polygon.id]: '0x54B114Aa09bA00260b69D7E706b664659a8e7b69',
    [linea.id]: '0x54B114Aa09bA00260b69D7E706b664659a8e7b69',
  },
};
