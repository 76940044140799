import cn from 'classnames';
import s from './style.module.scss';

/*colors
 - default (white)
 - error (red)
*/

function PopupActions({ className, children }) {
  return <h2 className={cn(s.actions, className)}>{children}</h2>;
}

export default PopupActions;
