import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CoinImg from '../../../assets/images/icons/coin.svg';
import StarsImg from '../../../assets/images/icons/starsGold.svg';
import MascotWithBoxImg from '../../../assets/images/mascot/mascotWithBox.svg';
import { PageNames } from '../../../core/constants/PageNames';
import { RoutesName } from '../../../core/constants/Routes';
import { STATIC_TEXT } from '../../../core/constants/staticText';
import { useRequestStaticText } from '../../../core/hooks/useRequestStaticText';
import { showGamePopupWindow } from '../../../core/store/slices/modalWindowStateSlice';
import ContentPanel from '../../uiNext/ContentPanel';
import Popup from '../../uiNext/Popup';
import MainButton from '../../uiNext/buttons/MainButton';
import s from './style.module.scss';

export function GamePopup() {
  const dispatch = useDispatch();
  const staticText = STATIC_TEXT[PageNames.GamePopup];
  const { text } = useRequestStaticText(PageNames.GamePopup);
  const navigate = useNavigate();

  function handleGoToGuides() {
    navigate(RoutesName.GuidesPage);
    dispatch(showGamePopupWindow(false));
  }

  return (
    <Popup onClose={() => dispatch(showGamePopupWindow(false))}>
      <div className={s.popup}>
        <img className={s.popup__image} src={MascotWithBoxImg} alt='mascot with box' />
        <div className={s.content}>
          <p className={s.content__title}>{text?.title || staticText.title}</p>
          <p className={s.content__subtitle}>{text?.subtitle || staticText.subtitle}</p>
          <ContentPanel background='black' className={s.panel}>
            <div className={s.panel__box}>
              <img className={s.panel__icon} src={CoinImg} alt='coin' />
              <p className={s.panel__text}>{text?.reward || staticText.reward}</p>
            </div>
            <div className={cn(s.panel__box, s.panel__box__second)}>
              <img className={s.panel__icon} src={CoinImg} alt='coin' />
              <p className={s.panel__text}>{staticText.gPoints}</p>
            </div>
            <img className={s.panel__stars} src={StarsImg} alt='golden start' />
          </ContentPanel>
          <img
            className={s.content__image}
            src={MascotWithBoxImg}
            alt='mascot with box'
          />
          <MainButton className={s.content__button} onClick={handleGoToGuides}>
            {text?.button || staticText.button}
          </MainButton>
        </div>
      </div>
    </Popup>
  );
}
