import cn from 'classnames';
import ball from '../../../../assets/images/stickers/ball.png';
import bangOrange from '../../../../assets/images/stickers/bangOrange.png';
import bangWhite from '../../../../assets/images/stickers/bangWhite.png';
import google from '../../../../assets/images/stickers/google.png';
import metamaskSmiley from '../../../../assets/images/stickers/metamask.png';
import smiley from '../../../../assets/images/stickers/smiley.png';
import telegram from '../../../../assets/images/stickers/telegram.png';
import twitter from '../../../../assets/images/stickers/twitter.png';
import { LoaderTypes } from '../../../../core/constants/loaderTypes';
import s from './style.module.scss';

const iconsWithMetamask = [smiley, ball, bangOrange, metamaskSmiley, bangWhite];
const iconsWithGoogle = [smiley, ball, bangOrange, google, bangWhite];
const iconsWithTwitter = [smiley, ball, bangOrange, twitter, bangWhite];
const iconsWithTelegram = [smiley, ball, bangOrange, telegram, bangWhite];

const iconsToLoaderTypes = {
  [LoaderTypes.Metamask]: iconsWithMetamask,
  [LoaderTypes.Google]: iconsWithGoogle,
  [LoaderTypes.Twitter]: iconsWithTwitter,
  [LoaderTypes.Telegram]: iconsWithTelegram,
};

export function LoaderMascotWithStikers({
  className,
  mascotImg,
  mascotSize,
  spinnerSize,
  spinnerTopPosition,
  type = LoaderTypes.Metamask,
}) {
  return (
    <div
      className={cn(s.loader, className, {
        [s.typeMetamask]: type === LoaderTypes.Metamask,
      })}>
      <div className={cn(s.spinner)}>
        {iconsToLoaderTypes[type].map((item, index) => (
          <div
            className={s.spinner__img}
            key={index}
            style={{
              transformOrigin: `0 ${spinnerSize}`,
              marginTop: spinnerTopPosition,
            }}>
            <img src={item} alt='sticker' />
          </div>
        ))}
      </div>
      <div className={s.loader__mascot} style={mascotSize}>
        {mascotImg && <img src={mascotImg} alt={'mascot'} />}
      </div>
    </div>
  );
}
