/* eslint-disable no-magic-numbers */
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import coin1 from '../../../assets/images/coins/1.svg';
import coin2 from '../../../assets/images/coins/2.svg';
import coin3 from '../../../assets/images/coins/3.svg';
import coin4 from '../../../assets/images/coins/4.svg';
import coin5 from '../../../assets/images/coins/5.svg';
import coin6 from '../../../assets/images/coins/6.svg';
import coin7 from '../../../assets/images/coins/7.svg';
import { isMobileSize } from '../../../core/helpers/isMobileDevice';

const coinsImages = [coin1, coin2, coin3, coin4, coin5, coin6, coin7];

const MOBILE_Z_INDEX = 1000;
const DESKTOP_Z_INDEX = -1;
const GENERATING_INTERVAL = 500;
const ANIMATION_TIME = 5000;
const COINS_PER_GENERATING = 2;

export function FallingCoins() {
  const [coins, setCoins] = useState([]);
  const stopAddingRef = useRef(false);
  const isMobile = isMobileSize();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!stopAddingRef.current) {
        setCoins((prevCoins) => [...prevCoins, ...generateCoins(COINS_PER_GENERATING)]);
      }
    }, GENERATING_INTERVAL);

    setTimeout(() => {
      stopAddingRef.current = true;
    }, ANIMATION_TIME);

    return () => clearInterval(intervalId);
  }, []);

  function generateCoins(number) {
    return Array.from({ length: number }).map(() => {
      const randomIndex = Math.floor(Math.random() * coinsImages.length);
      const coinImage = coinsImages[randomIndex];
      const randomOpacity = Math.random() * 0.5 + 0.5;

      return {
        id: Math.random(),
        imgSrc: coinImage,
        x: Math.random() * window.innerWidth,
        scale: Math.random() * 0.5 + 1.2,
        duration: Math.random() * 1.3 + 2,
        opacity: randomOpacity,
        y: -50 - Math.random() * 50,
      };
    });
  }

  const handleAnimationComplete = (id) => {
    setCoins((prevCoins) => prevCoins.filter((coin) => coin.id !== id));
  };

  return (
    <div
      style={{
        zIndex: isMobile ? MOBILE_Z_INDEX : DESKTOP_Z_INDEX,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        pointerEvents: 'none',
      }}>
      <AnimatePresence>
        {coins.map((coin) => (
          <motion.img
            key={coin.id}
            src={coin.imgSrc}
            initial={{ y: coin.y, x: coin.x, scale: coin.scale, opacity: coin.opacity }}
            animate={{ y: window.innerHeight + 100, x: coin.x, opacity: 1 }}
            exit={{ y: window.innerHeight + 100, opacity: 0 }} // Плавное исчезновение
            transition={{
              duration: coin.duration,
              ease: 'linear',
            }}
            style={{
              position: 'absolute',
              width: 50 * coin.scale,
              pointerEvents: 'none',
            }}
            onAnimationComplete={() => handleAnimationComplete(coin.id)}
          />
        ))}
      </AnimatePresence>
    </div>
  );
}
