import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useDisconnect } from 'wagmi';
import { LocalStorageItems } from '../constants/LocalStorageItems';
import { CookieNames } from '../constants/cookies';
import { getCookie, setCookie } from '../helpers/cookie';
import { authToken } from '../store/auth/slice';

export function useCheckAuthorization() {
  const token = useSelector(authToken);
  const { disconnect } = useDisconnect();

  useEffect(() => {
    if (token) {
      localStorage.setItem(LocalStorageItems.AuthorizationToken, token);
      if (!getCookie(CookieNames.SessionID)) {
        const sessionId = uuidv4();
        setCookie(CookieNames.SessionID, sessionId, { expires: 1, path: '/' });
      }
    } else {
      disconnect();
    }
  }, [token]);

  return { isUserAuthorized: token ? true : false };
}
