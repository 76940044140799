import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mascotBody from '../../../assets/images/mascot/mascotBodyStands.png';
import mascotHands from '../../../assets/images/mascot/mascotHandsStands.png';
import mascotStands from '../../../assets/images/mascot/mascotStands.png';
import { MetamaskConnection } from '../../../components/Auth/Metamask';
import { SubtitleWithBorderButton } from '../../../components/Subtitle';
import { AuthorizationTypes } from '../../../core/constants/AuthorizationTypes';
import { PageNames } from '../../../core/constants/PageNames';
import { Size } from '../../../core/constants/Size';
import { MetamaskConnectionStatuses } from '../../../core/constants/StatusTypes';
import { isMobileDevice } from '../../../core/helpers/isMobileDevice';
import { staticTextHelper } from '../../../core/helpers/staticTextHelper';
import { useCheckAuthorization } from '../../../core/hooks/useCheckAuthorization';
import { useRequestStaticText } from '../../../core/hooks/useRequestStaticText';
import { setCurrentAuthorizationType } from '../../../core/store/auth/slice';
import {
  metamaskStore,
  setFirstHighlightedItem,
  setMetamaskConnectionStatus,
} from '../../../core/store/metamask/slice';
import { LoaderMascotWithStikers } from '../../ui/loaders/LoaderMascotWithStikers';
import s from './style.module.scss';

const firstItem = 0;

export function MetamaskView({ authType }) {
  const dispatch = useDispatch();
  const { text } = useRequestStaticText(PageNames.Metamask);
  const { connectionStatus, firstHighlightedItem } = useSelector(metamaskStore);
  const { isUserAuthorized } = useCheckAuthorization();
  const [currentText, setCurrentText] = useState(null);

  useEffect(() => {
    if (
      (connectionStatus !== MetamaskConnectionStatuses.NoWallet ||
        connectionStatus !== MetamaskConnectionStatuses.NoWalletAuth) &&
      !window.ethereum
    ) {
      if (isUserAuthorized) {
        dispatch(setMetamaskConnectionStatus(MetamaskConnectionStatuses.NoWallet));
      } else {
        dispatch(setMetamaskConnectionStatus(MetamaskConnectionStatuses.NoWalletAuth));
      }
      dispatch(setFirstHighlightedItem(firstItem));
    }
  }, []);

  useEffect(() => {
    if (text) {
      const highlightedText = staticTextHelper.setHighlightedText(
        text[connectionStatus],
        firstHighlightedItem
      );
      setCurrentText(highlightedText);
    }
  }, [connectionStatus, text]);

  function handleClick() {
    if (
      connectionStatus === MetamaskConnectionStatuses.NoWallet ||
      connectionStatus === MetamaskConnectionStatuses.NoWalletAuth
    ) {
      if (!isUserAuthorized) {
        dispatch(setCurrentAuthorizationType(AuthorizationTypes.NotChosen));
      } else {
        const link = isMobileDevice()
          ? 'https://youtu.be/yNhLKqCk-qk?si=bwNjDJXhG3hzaDw9'
          : 'https://www.youtube.com/watch?v=krP9BZXyKtk';
        window.open(link, '_blank');
      }
    } else if (connectionStatus === MetamaskConnectionStatuses.Error) {
      dispatch(setMetamaskConnectionStatus(MetamaskConnectionStatuses.Connecting));
    }
  }

  return (
    <>
      {currentText && (
        <div className={s.connectWindow}>
          <div className={cn(s.connectWindow__header, s.header)}>
            <MetamaskConnection
              status={connectionStatus}
              text={currentText.statusText}
              authType={authType}
            />
          </div>
          <LoaderMascotWithStikers
            className={s.connectWindow__spinner}
            spinnerSize={'200px'}
          />
          <div className={cn(s.connectWindow__mascot, s.mascot)}>
            <LoaderMascotWithStikers
              className={s.connectWindow__spinner2}
              spinnerSize={'200px'}
              mascotImg={mascotStands}
            />
            <img className={s.mascot__body} src={mascotBody} alt={'mascot'} />
            <img className={s.mascot__hands} src={mascotHands} alt={'mascot'} />
            <SubtitleWithBorderButton
              className={cn(s.mascot__text, {
                [s.mascot__text_noAnswer]: !currentText.buttonText,
              })}
              text={currentText.descriptionText}
              buttonText={currentText.buttonText}
              buttonOnClick={handleClick}
              size={Size.L}
            />
          </div>
          <div className={s.connectWindow__descriptionText}></div>
        </div>
      )}
    </>
  );
}
