import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AudioAutoplay, AudioState } from '../constants/AudioSettings';
import { LocalStorageItems } from '../constants/LocalStorageItems';
import { RoutesName } from '../constants/Routes';
import { audioSettingsStore, setAudioState } from '../store/slices/audioSettingsSlice';

export function useAudioPage(audioSrc) {
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const audioIdRef = useRef(null);
  const path = useLocation().pathname;
  const isGuideChatPage = path.includes(RoutesName.GuidesChat);

  const { audioState, audioVolume, audioRate } = useSelector(audioSettingsStore);
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    if (audioSrc) {
      audioRef.current = new Audio(audioSrc);
      audioRef.current.id = Math.random();

      if (Number(localStorage[LocalStorageItems.AudioAutoplay]) === AudioAutoplay.On) {
        audioRef.current.autoplay = true;
      }

      audioRef.current.onloadedmetadata = () => {
        setAudio(audioRef.current);
      };

      audioRef.current.onplay = () => {
        if (isGuideChatPage) {
          audioRef.current.pause();
        } else {
          dispatch(setAudioState(AudioState.Play));
        }
      };

      audioRef.current.onpause = () => {
        dispatch(setAudioState(AudioState.Stop));
      };

      audioRef.current.onended = () => {
        dispatch(setAudioState(AudioState.Stop));
      };
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        dispatch(setAudioState(AudioState.Stop));
      }
    };
  }, [audioSrc]);

  useEffect(() => {
    if (audioSrc) {
      if (isGuideChatPage) {
        audioRef.current.pause();
      }
    }
  }, [isGuideChatPage]);

  useEffect(() => {
    if (audioSrc) {
      audioRef.current.playbackRate = audioRate;
    }
  }, [audioRate]);

  useEffect(() => {
    if (audioSrc) {
      audioRef.current.volume = audioVolume;
    }
  }, [audioVolume]);

  function changeAudioState() {
    if (audioState === AudioState.Stop) {
      audioRef.current.play().catch(() => {
        dispatch(setAudioState(AudioState.Stop));
      });
    } else if (audioState === AudioState.Play) {
      audioRef.current.pause();
      dispatch(setAudioState(AudioState.Pause));
    } else if (audioState === AudioState.Pause) {
      audioRef.current.play().catch(() => {
        dispatch(setAudioState(AudioState.Stop));
      });
    }
  }

  return {
    audio: audio,
    audioId: audioIdRef.current, //unused outside
    audioState: audioState, //from store
    changeAudioState: changeAudioState, //func
  };
}
