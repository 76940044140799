import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as LanguageIcon } from '../../../assets/images/menu/globe.svg';
import { LANGUAGES } from '../../../core/constants/languages';
import { useCheckAuthorization } from '../../../core/hooks/useCheckAuthorization';
import { setLanguage, userState } from '../../../core/store/user/slice';
import { userRequest } from '../../../core/store/user/thunk';
import s from './style.module.scss';

export function LanguageSelector({ className }) {
  const dispatch = useDispatch();
  const menuRef = useRef(null);
  const { language } = useSelector(userState);
  const { isUserAuthorized } = useCheckAuthorization();
  const [isMenuOpened, changeIsMenuOpened] = useState(false);
  const languageText = LANGUAGES.find((lang) => lang.locale === language)?.shortLabel;

  const toggleMenu = () => {
    changeIsMenuOpened(!isMenuOpened);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      changeIsMenuOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={menuRef} className={cn(s.selector, className)}>
      <div
        className={cn(
          s.selector__container,
          { [s.selector__container_open]: isMenuOpened },
          'iconDropdownArrow_after'
        )}
        role='button'
        onClick={toggleMenu}>
        <LanguageIcon />
        {language && <p className={cn(s.selector__text)}>{languageText}</p>}
      </div>
      {isMenuOpened && (
        <ul className={cn(s.selector__list)}>
          {LANGUAGES.map((item) => (
            <li
              className={cn(s.selector__item, s.item)}
              key={item.locale}
              role='button'
              onClick={() => {
                if (isUserAuthorized) {
                  dispatch(userRequest.changeLanguage(item.locale));
                } else {
                  dispatch(setLanguage(item.locale));
                }
                changeIsMenuOpened(false);
              }}>
              <LazyLoadImage
                className={s.item__icon}
                src={item.icon}
                alt={'language flag'}
              />
              <div className={s.item__title}>{item.lang}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
