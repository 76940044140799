import { createSlice } from '@reduxjs/toolkit';
import { StatusTypes } from '../../constants/StatusTypes';
import { getStaticText } from './thunk';
// import { getStaticText } from './thunk';

const initialState = {
  // savedStaticText: [],
};

export const staticTextSlice = createSlice({
  name: 'staticText',
  initialState,
  reducers: {
    resetStaticTextState: () => initialState,
    removeUnusedStaticText: (state, action) => {
      const statusState = 'staticTextStatus' + action.payload;
      const textState = 'staticText' + action.payload;
      const errorState = 'staticTextError' + action.payload;

      state[statusState] = null;
      state[textState] = null;
      state[errorState] = null;
    },
  },

  extraReducers: (builder) => {
    const firstItem = 0;

    builder
      .addCase(getStaticText.helloPage.pending, (state) => {
        state.errorHello = null;
      })
      .addCase(getStaticText.helloPage.fulfilled, (state, action) => {
        state.statusHello = StatusTypes.Resolved;
        state.staticDataHello = action.payload;
      })
      .addCase(getStaticText.helloPage.rejected, (state, action) => {
        state.statusHello = StatusTypes.Rejected;
        state.errorHello = action.payload;
      })

      .addMatcher(
        (action) => /staticText\/.*\/pending$/.test(action.type),
        (state, action) => {
          const stateName =
            action.meta.arg['keys']?.[firstItem].match(/[^_.]*/)[firstItem];
          const errorState = 'staticTextError' + stateName;
          const statusState = 'staticTextStatus' + stateName;

          state[statusState] = StatusTypes.Loading;
          state[errorState] = null;
        }
      )
      .addMatcher(
        (action) => /staticText\/.*\/fulfilled$/.test(action.type),
        (state, action) => {
          const stateName =
            action.meta.arg['keys']?.[firstItem].match(/[^_.]*/)[firstItem];
          const textState = 'staticText' + stateName;
          const statusState = 'staticTextStatus' + stateName;

          state[statusState] = StatusTypes.Resolved;
          state[textState] = action.payload;
        }
      )
      .addMatcher(
        (action) => /staticText\/.*\/rejected$/.test(action.type),
        (state, action) => {
          const stateName =
            action.meta.arg['keys']?.[firstItem].match(/[^_.]*/)[firstItem];
          const errorState = 'staticTextError' + stateName;
          const statusState = 'staticTextStatus' + stateName;

          state[statusState] = StatusTypes.Rejected;
          state[errorState] = action.payload;
        }
      );
  },
});

export const { resetStaticTextState, removeUnusedStaticText } = staticTextSlice.actions;
export const staticText = (state) => state.staticText;

export default staticTextSlice.reducer;
