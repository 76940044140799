import cn from 'classnames';
import { useEffect, useMemo } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import richMascot from '../../../assets/animation/richMascot.gif';
import { PageNames } from '../../../core/constants/PageNames';
import { StatusTypes } from '../../../core/constants/StatusTypes';
import { STATIC_TEXT } from '../../../core/constants/staticText';
import { useRequestStaticText } from '../../../core/hooks/useRequestStaticText';
import { rewardsState } from '../../../core/store/rewards/slice';
import { rewardsRequest } from '../../../core/store/rewards/thunk';
import { guideRequest } from '../../../core/store/showcase/thunk';
import {
  showClaimWindow,
  showRewardWindow,
} from '../../../core/store/slices/modalWindowStateSlice';
import { userRequest } from '../../../core/store/user/thunk';
import BounceAnimation from '../../animated/BounceAnimation';
import ContentPanel from '../../uiNext/ContentPanel';
import Popup from '../../uiNext/Popup';
import MainButton from '../../uiNext/buttons/MainButton';
import s from './style.module.scss';

const staticText = STATIC_TEXT[PageNames.Reward];
const gPointsName = 'G-Points';

const INDEX_0 = 0;
const ONLY_G_POINTS_RECEIVED_LENGTH = 1;
const DEFAULT_Z_INDEX = 1600;
const ONE = 1;

export function RewardPopup({ id, onStartAnimation }) {
  const dispatch = useDispatch();
  const { rewards, signRewardStatus, data } = useSelector(rewardsState);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { text } = useRequestStaticText(PageNames.Reward);

  const isOnlyGPointsReceived = useMemo(
    () =>
      rewards?.length === ONLY_G_POINTS_RECEIVED_LENGTH &&
      rewards[INDEX_0].rewardName === gPointsName,
    [rewards]
  );

  useEffect(() => {
    dispatch(rewardsRequest.getRewards({ questId: id }));
  }, []);

  async function handleTakeReward() {
    if (isOnlyGPointsReceived) {
      if (!executeRecaptcha) {
        return;
      }
      const token = await executeRecaptcha('receive_token');
      dispatch(rewardsRequest.claim({ token, questId: id }));
    } else {
      dispatch(
        showClaimWindow({
          opened: true,
          id: id,
          chainId: rewards.find((reward) => !!reward.chainId).chainId,
        })
      );
      dispatch(
        showRewardWindow({
          opened: false,
          id: null,
        })
      );
    }
  }

  useEffect(() => {
    if (
      signRewardStatus === StatusTypes.Resolved &&
      data.signReward?.state.type === 'SUCCESS' &&
      isOnlyGPointsReceived
    ) {
      onStartAnimation();
      dispatch(userRequest.getPoints());
      dispatch(guideRequest.getData());
      dispatch(
        showRewardWindow({
          opened: false,
          id: null,
        })
      );
    }
  }, [signRewardStatus, isOnlyGPointsReceived]);

  return (
    <Popup
      onClose={() => {
        dispatch(guideRequest.getData());
        dispatch(
          showRewardWindow({
            opened: false,
            id: null,
          })
        );
      }}>
      <img className={s.mascot} src={richMascot} alt='richGydde' />
      <div className={s.content}>
        <h4 className={cn(s.content__text, s.content__title)}>
          {text?.title || staticText.title}
        </h4>
        <p className={s.content__text}>{text?.description || staticText.description}</p>
        <div className={s.content__rewardBox}>
          <BounceAnimation>
            <ContentPanel className={s.content__rewardBox__panel} background='secondary'>
              <div className={s.content__rewardBox__container}>
                {(rewards || []).map((reward, index) => (
                  <div
                    key={reward.name}
                    className={s.content__rewardBox__item}
                    style={{
                      zIndex: DEFAULT_Z_INDEX + index + ONE,
                    }}>
                    <img
                      className={s.content__rewardBox__icon}
                      src={reward.logo}
                      alt='coin'
                    />
                    <p
                      className={cn(
                        s.content__rewardBox__text,
                        reward.rewardName === gPointsName &&
                          s.content__rewardBox__text__gold
                      )}>{`${reward.rewardValue} ${reward.rewardName}`}</p>
                  </div>
                ))}
              </div>
            </ContentPanel>
          </BounceAnimation>
        </div>
        <img className={s.content__mascot} src={richMascot} alt='richGydde' />
        <MainButton className={s.content__button} onClick={handleTakeReward}>
          {text?.button?.take || staticText.button.take}
        </MainButton>
      </div>
    </Popup>
  );
}
