import { useNavigate } from 'react-router-dom';
import { AccentButton } from '../../components/ui/buttons/Button';
import { PageNames } from '../../core/constants/PageNames';
import { RoutesName } from '../../core/constants/Routes';
import { STATIC_TEXT } from '../../core/constants/staticText';
import s from './style.module.scss';

export function PageNotFound() {
  const navigate = useNavigate();

  return (
    <main className={s.content}>
      <div className={s.content__container}>
        <div className={s.content__info}>
          <div className={s.content__title}>
            {STATIC_TEXT[PageNames.PageNotFound].title}
          </div>
          <div className={s.content__subtitle}>
            {STATIC_TEXT[PageNames.PageNotFound].subtitle}
          </div>
          <AccentButton
            className={s.content__button}
            onClick={() => navigate(RoutesName.Root)}>
            {STATIC_TEXT[PageNames.PageNotFound].button}
          </AccentButton>
        </div>
      </div>
    </main>
  );
}
