import { useMemo } from 'react';
import mascotError from '../../../assets/images/mascot/mascotError2.svg';
import mascotSmiles from '../../../assets/images/mascot/mascotSmiles.svg';
import { PageNames } from '../../../core/constants/PageNames';
import { TransactionStatuses } from '../../../core/constants/StatusTypes';
import { STATIC_TEXT } from '../../../core/constants/staticText';
import { useRequestStaticText } from '../../../core/hooks/useRequestStaticText';
import { LoaderMascotWithStikers } from '../../ui/loaders/LoaderMascotWithStikers';
import ContentPanel from '../../uiNext/ContentPanel';
import Link from '../../uiNext/Link';
import Popup from '../../uiNext/Popup';
import PopupActions from '../../uiNext/PopupActions';
import PopupText from '../../uiNext/PopupText';
import PopupTitle from '../../uiNext/PopupTitle';
import s from './style.module.scss';

const staticText = STATIC_TEXT[PageNames.TransactionStatus];

const visualDataByStatus = {
  [TransactionStatuses.Initiated]: {
    sticker: '⌛',
    borderColor: 'secondary',
    titleColor: 'default',
  },
  [TransactionStatuses.Pending]: {
    sticker: '⌛',
    borderColor: 'secondary',
    titleColor: 'default',
  },
  [TransactionStatuses.Success]: {
    sticker: '✅',
    borderColor: 'green',
    titleColor: 'green',
  },
  [TransactionStatuses.Rejected]: {
    sticker: '❌️️',
    borderColor: 'red',
    titleColor: 'red',
  },
  [TransactionStatuses.Error]: {
    sticker: '❌️️',
    borderColor: 'red',
    titleColor: 'red',
  },
};

export function TxStatusPopup({ status, explorerUrl, onClose }) {
  const { text } = useRequestStaticText(PageNames.TransactionStatus);
  const statusText = useMemo(() => text?.[status] || staticText[status], [text, status]);

  const isConnectionError = useMemo(
    () => [TransactionStatuses.Error, TransactionStatuses.Rejected].includes(status),
    [status]
  );

  return (
    <Popup onClose={onClose}>
      <div className={s.mascot}>
        <LoaderMascotWithStikers
          mascotImg={isConnectionError ? mascotError : mascotSmiles}
          spinnerSize={'200px'}
          spinnerTopPosition={'-25%'}
        />
      </div>
      <div className={s.content}>
        <PopupTitle
          label={statusText?.title}
          color={visualDataByStatus[status].titleColor}
        />
        <p className={s.content__sticker}>{visualDataByStatus[status].sticker}</p>
        <div className={s.content__infoBox}>
          <ContentPanel
            className={s.content__infoBox__panel}
            background={'secondary'}
            borderColor={visualDataByStatus[status].borderColor}>
            <PopupText className={s.content__text}>{statusText?.text}</PopupText>
          </ContentPanel>
        </div>
        <div className={s.content__mascot}>
          <LoaderMascotWithStikers
            mascotImg={isConnectionError ? mascotError : mascotSmiles}
            spinnerSize={'200px'}
            spinnerTopPosition={'-25%'}
          />
        </div>
        <PopupActions className={s.content__actions}>
          {[TransactionStatuses.Error, TransactionStatuses.Success].includes(status) &&
            explorerUrl && (
              <Link
                href={explorerUrl}
                target='_blank'
                label={text?.seeTransaction || staticText?.seeTransaction}
                rel='noopener noreferrer'
              />
            )}
        </PopupActions>
      </div>
    </Popup>
  );
}
