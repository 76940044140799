import { createAsyncThunk } from '@reduxjs/toolkit';
import { ItemNum } from '../../constants/ItemNum';
import { NETWORKS_BY_ID } from '../../constants/networks';
import mainRequest, { mainPublicRequest } from '../../utils/mainRequestUtils';
import { setAuthorizationToken } from '../auth/slice';

export const metamaskRequest = {
  getAccount: createAsyncThunk(
    'metamask/getAccount',
    async function (web3, { rejectWithValue, getState }) {
      const state = getState();
      const walletAddress = state.user.userData.walletAddress;

      try {
        if (!walletAddress) {
          await window.ethereum.request({
            method: 'wallet_requestPermissions',
            params: [{ eth_accounts: {} }],
          });
        }
        const account = await web3.eth.requestAccounts();
        const balance = await web3.eth.getBalance(account[ItemNum.First]);
        const etherBalance = web3.utils.fromWei(balance, 'ether');
        const networkId = await web3.eth.net.getId();

        if (walletAddress) {
          const isSameWallet = account[ItemNum.First] === walletAddress;

          if (!isSameWallet && walletAddress) {
            throw new Error('Not same wallet');
          }
        }

        return {
          account: account[ItemNum.First],
          balance: etherBalance == ItemNum.None ? balance.toString() : etherBalance,
          networkId: networkId.toString(),
        };
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  signMessage: createAsyncThunk(
    'metamask/signMessage',
    async function (web3, { rejectWithValue, getState }) {
      const state = getState();
      const message = state.metamask.message;
      const account = state.metamask.account;

      try {
        const result = await mainPublicRequest.get(`/auth/metamask/message/${message}`);
        const response = await web3.eth.personal.sign(result.data.token, account, '');

        return response;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
  sendAuthData: createAsyncThunk(
    'metamask/sendAuthData',
    async function ({ data, mentorId }, { rejectWithValue, dispatch }) {
      try {
        const response = await mainPublicRequest.post(
          '/auth/metamask',
          JSON.stringify(data),
          {
            params: { referralData: mentorId },
          }
        );

        dispatch(setAuthorizationToken(response.data.token));

        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  linkMetamask: createAsyncThunk(
    'metamask/linkMetamask',
    async function (data, { rejectWithValue }) {
      try {
        const response = await mainRequest.post(
          '/link/auth/metamask',
          JSON.stringify(data)
        );

        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  getTransactions: createAsyncThunk(
    'metamask/getTransactions',
    async function (_, { rejectWithValue }) {
      try {
        const response = await mainRequest.get('/transaction/find-all');

        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  addNewToken: createAsyncThunk(
    'metamask/addNewToken',
    async function ({ tokenAddress, neededNetworkId }, { rejectWithValue }) {
      try {
        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: NETWORKS_BY_ID[neededNetworkId].type,
            options: {
              address: tokenAddress,
            },
          },
        });
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  switchNetwork: createAsyncThunk(
    'metamask/switchNetwork',
    async function ({ networkId }, { rejectWithValue }) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: NETWORKS_BY_ID[networkId].chainId }],
        });
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  addNewNetwork: createAsyncThunk(
    'metamask/addNewNetwork',
    async function ({ networkId }, { rejectWithValue }) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: NETWORKS_BY_ID[networkId].chainId,
              chainName: NETWORKS_BY_ID[networkId].chainName,
              rpcUrls: [NETWORKS_BY_ID[networkId].rpcUrl],
              nativeCurrency: {
                symbol: NETWORKS_BY_ID[networkId].symbol,
                decimals: NETWORKS_BY_ID[networkId].decimals,
              },
              blockExplorerUrls: [NETWORKS_BY_ID[networkId].blockExplorerUrl],
            },
          ],
        });
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  getChainId: createAsyncThunk(
    'metamask/getChainId',
    async function (_, { rejectWithValue }) {
      try {
        const response = await window.ethereum.request({ method: 'eth_chainId' });

        return response;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
  getAccounts: createAsyncThunk(
    'metamask/getAccounts',
    async function (_, { rejectWithValue }) {
      try {
        const response = await window.ethereum.request({ method: 'eth_requestAccounts' });

        return response;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
  revokePermits: createAsyncThunk(
    'metamask/revokePermits',
    async function (_, { rejectWithValue }) {
      try {
        await window.ethereum.request({
          method: 'wallet_requestPermissions',
          params: [{ eth_accounts: {} }],
        });
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
};
