import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalAuthorization: false,
  modalProfile: false,
  modalUsernameEdit: false,
  modalClaim: {
    opened: false,
    id: null,
    chainId: null,
  },
  modalReferralLink: false,
  modalTransactionStatus: false,
  modalSupport: false,
  modalTemporaryMobile: false,
  modalPreferredSocials: false,
  modalSkipStep: false,
  modalLeaderboard: false,
  modalResults: false,
  modalReward: {
    opened: false,
    id: null,
  },
  modalGuideInfo: {
    opened: false,
    id: null,
  },
  modalGamePopup: false,
};

export const modalWindowStateSlice = createSlice({
  name: 'modalWindowState',
  initialState,
  reducers: {
    showAuthorizationWindow: (state, action) => {
      state.modalAuthorization = action.payload;
    },
    showUsernameEditWindow: (state, action) => {
      state.modalUsernameEdit = action.payload;
    },
    showClaimWindow: (state, action) => {
      state.modalClaim = action.payload;
    },
    showRewardWindow: (state, action) => {
      state.modalReward.opened = action.payload.opened;
      state.modalReward.id = action.payload.id;
    },
    showReferralLinkWindow: (state, action) => {
      state.modalReferralLink = action.payload;
    },
    showTransactionStatusWindow: (state, action) => {
      state.modalTransactionStatus = action.payload;
    },
    showSupportWindow: (state, action) => {
      state.modalSupport = action.payload;
    },
    showTemporaryMobile: (state, action) => {
      state.modalTemporaryMobile = action.payload;
    },
    showPreferredSocialsWindow: (state, action) => {
      state.modalPreferredSocials = action.payload;
    },
    showWarningSkipStepWindow: (state, action) => {
      state.modalSkipStep = action.payload;
    },
    showLeaderboardWindow: (state, action) => {
      state.modalLeaderboard = action.payload;
    },
    showGuideInfoWindow: (state, action) => {
      state.modalGuideInfo.opened = action.payload.opened;
      state.modalGuideInfo.id = action.payload.id;
    },
    showResultsWindow: (state, action) => {
      state.modalResults = action.payload;
    },
    showGamePopupWindow: (state, action) => {
      state.modalGamePopup = action.payload;
    },
  },
});

export const {
  showAuthorizationWindow,
  showUsernameEditWindow,
  showClaimWindow,
  showReferralLinkWindow,
  showRewardWindow,
  showTransactionStatusWindow,
  showSupportWindow,
  showTemporaryMobile,
  showPreferredSocialsWindow,
  showWarningSkipStepWindow,
  showLeaderboardWindow,
  showGuideInfoWindow,
  showResultsWindow,
  showGamePopupWindow,
} = modalWindowStateSlice.actions;

/**
 *
 * @param {*} state
 * @returns {initialState}
 */
export const modalWindowState = (state) => state.modalWindowState;

export default modalWindowStateSlice.reducer;
