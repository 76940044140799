import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TelegramImg from '../../../assets/images/icons/telegram.svg';
import TwitterImg from '../../../assets/images/icons/twitter.svg';
import { PageNames } from '../../../core/constants/PageNames';
import { STATIC_TEXT } from '../../../core/constants/staticText';
import copyText from '../../../core/helpers/copyText';
import { useRequestStaticText } from '../../../core/hooks/useRequestStaticText';
import { referralsState } from '../../../core/store/referrals/slice';
import { referralsRequest } from '../../../core/store/referrals/thunk';
import { showReferralLinkWindow } from '../../../core/store/slices/modalWindowStateSlice';
import { ModalWithBorderShadow, ModalWithClose } from '../../ui/modals/windows';
import { SocialButton } from '../../uiNext/buttons/SocialButton';
import s from './style.module.scss';

export function ReferralLinkPopup() {
  const dispatch = useDispatch();
  const { link } = useSelector(referralsState);
  const { text } = useRequestStaticText(PageNames.ReferralLink);
  const staticText = STATIC_TEXT[PageNames.ReferralLink];

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  useEffect(() => {
    dispatch(referralsRequest.getLink());
  }, []);

  function handleRedirectToTwitter() {
    const url = encodeURIComponent(link);
    const linkText = encodeURIComponent(
      `${text?.linkMessage || staticText.linkMessage}\n` +
        `${text?.linkMessageDescription || staticText.linkMessageDescription}`
    );
    const shareLink = `https://twitter.com/intent/tweet?text=${linkText}&url=${url}
    `;

    window.open(shareLink);
  }

  function handleRedirectToTelegram() {
    const url = encodeURIComponent(link);
    const linkText = encodeURIComponent(
      `${text?.linkMessage || staticText.linkMessage}\n` +
        `${text?.linkMessageDescription || staticText.linkMessageDescription}`
    );
    const shareLink = `https://t.me/share/url?url=${url}&text=${linkText}`;

    window.open(shareLink);
  }

  return (
    <ModalWithClose
      Component={ModalWithBorderShadow}
      onClose={() => dispatch(showReferralLinkWindow(false))}
      className={s.modal}>
      <div>
        <div className={cn(s.title)}>{text?.title || staticText.title}</div>
        <div className={cn(s.link, isLinkCopied && s.link_done)}>
          <div className={cn(s.link__text)}>{link}</div>
          <button
            disabled={isLinkCopied}
            className={cn(s.link__copy, !isLinkCopied && 'iconCopy')}
            onClick={() => {
              setIsLinkCopied(true);
              copyText(link);
            }}
          />
        </div>
        <div className={cn(s.socials)}>
          <SocialButton
            onClick={handleRedirectToTwitter}
            logo={TwitterImg}
            name={staticText.twitter}
            className={cn(s.socials__button)}
          />
          <SocialButton
            onClick={handleRedirectToTelegram}
            logo={TelegramImg}
            name={staticText.telegram}
            className={cn(s.socials__button)}
          />
        </div>
        {/* <div className={cn(s.socials)}>
          {socials.map((item) => (
            <a
              href='#'
              className={cn(s.socials__link, item.name)}
              key={item.name}
              style={{ fontSize: item?.size }}></a>
          ))}
        </div> */}
      </div>
    </ModalWithClose>
  );
}
