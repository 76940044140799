import { createSlice } from '@reduxjs/toolkit';
import { StatusTypes } from '../../constants/StatusTypes';
import { leaderboardRequest } from './thunk';

const initialState = {
  data: null,
  status: null,
  error: null,
};

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    resetLeaderboardState: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(leaderboardRequest.getData.pending, (state) => {
        state.error = null;
        state.status = StatusTypes.Loading;
      })
      .addCase(leaderboardRequest.getData.fulfilled, (state, action) => {
        state.status = StatusTypes.Resolved;
        state.data = action.payload;
      })
      .addCase(leaderboardRequest.getData.rejected, (state, action) => {
        state.error = action.payload.error;
        state.status = StatusTypes.Rejected;
      });
  },
});

export const { resetLeaderboardState } = leaderboardSlice.actions;
export const leaderboardState = (state) => state.leaderboard;

export default leaderboardSlice.reducer;
