const TIME_TO_RECONNECT = 5000;

export const setupWebSocketErrorHandler = () => {
  const originalWebSocket = window.WebSocket;

  window.WebSocket = function (...args) {
    const wsInstance = new originalWebSocket(...args);

    wsInstance.addEventListener('error', (error) => {
      console.error('WebSocket Error:', error);
    });

    wsInstance.addEventListener('close', (event) => {
      console.warn('WebSocket Closed:', event);
      setTimeout(() => {
        new window.WebSocket(...args);
      }, TIME_TO_RECONNECT);
    });

    return wsInstance;
  };
};
