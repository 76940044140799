import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';

import { useRef } from 'react';

const ZERO = 0;
const TOUCH_AREA = 100;

function DrawerUI({ isOpen, onClose, children }) {
  const startYRef = useRef(ZERO);
  const endYRef = useRef(ZERO);

  const handleTouchStart = (e) => {
    startYRef.current = e.touches[ZERO].clientY;
  };

  const handleTouchEnd = (e) => {
    endYRef.current = e.changedTouches[ZERO].clientY;
    if (endYRef.current - startYRef.current > TOUCH_AREA) {
      onClose();
    }
  };

  return (
    <Drawer isOpen={isOpen} placement='bottom' onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent
        borderRadius='14px 14px 0px 0px'
        marginBottom='68px'
        borderTop='1px solid #868686'
        backgroundColor='gray.700'
        p='8px 0px'
        color='white.default'
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}>
        <Center>
          <Box
            w='36px'
            h='5px'
            bg='rgba(255, 255, 255, 0.5)'
            role='button'
            borderRadius='4px'
            sx={{
              '&:active': {
                bg: '#9DE874',
              },
            }}
          />
        </Center>
        <DrawerBody p='0px'>
          <Center flexDirection='column'>{children}</Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerUI;
