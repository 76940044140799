import cn from 'classnames';
import s from './style.module.scss';

/*
 formats:
   - referral
   - auth
   - link 
   - wallet
*/

// export function SocialButton({ className, logo, name, onClick, format = 'referral' }) {
export function SocialButton({ logo, name, onClick, format = 'referral' }) {
  return (
    <div
      onClick={onClick}
      className={cn(
        s.socialButton,
        format === 'referral' && s.referral,
        format === 'auth' && s.auth,
        format === 'link' && s.link,
        format === 'wallet' && s.wallet
      )}>
      <img className={cn(s.socialButton__logo)} src={logo} alt='social logo' />
      <p className={cn(s.socialButton__name)}>{name}</p>
    </div>
  );
}
