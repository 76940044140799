import { createAsyncThunk } from '@reduxjs/toolkit';
import { googleAuthRequestParams } from '../../constants/Socials';
import { collectSearchParams } from '../../helpers/collectSearchParams';
import googleRequest from '../../utils/googleRequestUtils';
import mainRequest, { mainPublicRequest } from '../../utils/mainRequestUtils';

const AUTH_HEADER = 'Authorization-Link';
const GOOGLE_AUTH_TITLE = 'OAuth';

export const authRequest = {
  getIdTokenGoogle: createAsyncThunk(
    'authorization/getIdTokenGoogle',
    async (code, { rejectWithValue }) => {
      const params = collectSearchParams({
        ...googleAuthRequestParams,
        code: code,
      });
      try {
        const response = await googleRequest.post('', params);

        return response.data.id_token;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  google: createAsyncThunk(
    'authorization/google',
    async ({ mentorId }, { rejectWithValue, getState }) => {
      const state = getState();
      const idTokenGoogle = state.authorization.idTokenGoogle;
      const token = `${GOOGLE_AUTH_TITLE} ${idTokenGoogle}`;
      try {
        const response = await mainPublicRequest.get('/auth/gmail', {
          headers: {
            Authorization: token,
          },
          params: { referralData: mentorId },
        });

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  linkGoogle: createAsyncThunk(
    'authorization/linkGoogle',
    async (_, { rejectWithValue, getState }) => {
      const state = getState();
      const idTokenGoogle = state.authorization.idTokenGoogle;
      const token = `${GOOGLE_AUTH_TITLE} ${idTokenGoogle}`;
      try {
        const response = await mainRequest.get('/link/auth/gmail', {
          headers: {
            [AUTH_HEADER]: token,
          },
        });

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  twitter: createAsyncThunk(
    'authorization/twitter',
    async function ({ code, mentorId }, { rejectWithValue }) {
      try {
        const body = {
          params: { code: code, referralData: mentorId },
        };

        const response = await mainPublicRequest.get('/auth/twitter', body);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  linkTwitter: createAsyncThunk(
    'authorization/linkTwitter',
    async function ({ code }, { rejectWithValue }) {
      try {
        const body = {
          params: { code: code },
        };

        const response = await mainRequest.get('/link/auth/twitter', body);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  telegram: createAsyncThunk(
    'authorization/telegram',
    async function ({ authData, mentorId }, { rejectWithValue }) {
      try {
        const body = {
          params: { authData: authData, referralData: mentorId },
        };

        const response = await mainPublicRequest.get('/auth/telegram', body);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  telegramDesktop: createAsyncThunk(
    'authorization/telegramDesktop',
    async function ({ data, mentorId }, { rejectWithValue }) {
      try {
        const body = {
          params: { ...data, referralData: mentorId },
        };

        const response = await mainPublicRequest.get('/auth/telegram/desktop', body);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  linkTelegram: createAsyncThunk(
    'authorization/linkTelegram',
    async function ({ authData }, { rejectWithValue }) {
      try {
        const body = {
          params: { authData: authData },
        };

        const response = await mainRequest.get('/link/auth/telegram', body);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  linkTelegramDesktop: createAsyncThunk(
    'authorization/linkTelegramDesktop',
    async function ({ data }, { rejectWithValue }) {
      try {
        const body = {
          params: data,
        };

        const response = await mainRequest.get('/link/auth/telegram/desktop', body);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  getSignMessage: createAsyncThunk(
    'authorization/getSignMessage',
    async function ({ uuid }, { rejectWithValue }) {
      try {
        const response = await mainPublicRequest.get(
          `/auth/connect-wallet/message/${uuid}`
        );

        return response.data.token;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  connectWallet: createAsyncThunk(
    'authorization/connectWallet',
    async function (
      { uuid, signature, mentorId, name, id, icon, uid },
      { rejectWithValue }
    ) {
      try {
        const data = { signature, uuid, name, id, icon, uid };
        const response = await mainPublicRequest.post('/auth/connect-wallet', data, {
          params: { referralData: mentorId },
        });

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  linkWallet: createAsyncThunk(
    'authorization/linkWallet',
    async function ({ uuid, signature, name, id, icon, uid }, { rejectWithValue }) {
      try {
        const data = { signature, uuid, name, id, icon, uid };
        const response = await mainRequest.post('/link/auth/connect-wallet', data);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  getTelegramLink: createAsyncThunk(
    'authorization/getTelegramLink',
    async function (_, { rejectWithValue }) {
      try {
        const response = await mainRequest.get('/user/telegram/link');

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
};
