import cn from 'classnames';
import { useDispatch } from 'react-redux';
import IconM from '../../assets/images/icons/metamask.svg';
import TelegramImg from '../../assets/images/icons/telegram.svg';
import TwitterImg from '../../assets/images/icons/twitter.svg';
import walletConnectIcon from '../../assets/images/icons/wallet.svg';
import { AuthorizationTypes } from '../../core/constants/AuthorizationTypes';
import { PageNames } from '../../core/constants/PageNames';
import { STATIC_TEXT } from '../../core/constants/staticText';
import { isMobileDevice } from '../../core/helpers/isMobileDevice';
import { useStaticText } from '../../core/hooks/useStaticText';
import { useWalletConnectStatus } from '../../core/hooks/useWalletConnectStatus';
import { setCurrentAuthorizationType } from '../../core/store/auth/slice';
import { SocialButton } from '../uiNext/buttons/SocialButton';
import { ConnectWalletAuthProvider } from './providers/ConnectWalletAuthProvider';
import { TelegramAuthProvider } from './providers/TelegramAuthProvider';
import { TwitterAuthProvider } from './providers/TwitterAuthProvider';
import s from './style.module.scss';

export function AllAuthorizaitions() {
  const { text } = useStaticText(PageNames.Auth);
  const { isConnected } = useWalletConnectStatus();

  const dispatch = useDispatch();

  return (
    <>
      {text && (
        <div className={s.auth__body}>
          <div>
            <div className={s.socials__title}>{text.socialsTitle}</div>
            <div className={s.socials__body}>
              <TwitterAuthProvider
                setAuthType={() =>
                  setCurrentAuthorizationType(AuthorizationTypes.AuthTwitter)
                }>
                <SocialButton logo={TwitterImg} format={'auth'} name={'Twitter'} />
              </TwitterAuthProvider>
              <TelegramAuthProvider
                setAuthType={() =>
                  setCurrentAuthorizationType(AuthorizationTypes.AuthTelegram)
                }>
                <SocialButton logo={TelegramImg} format={'auth'} name={'Telegram'} />
              </TelegramAuthProvider>
            </div>
          </div>
          <div>
            <div className={s.socials__title}>
              {text.walletTitle || STATIC_TEXT[PageNames.Auth].walletTitle}
            </div>
            {!isMobileDevice() && !isConnected && (
              <div className={cn(s.socials__body, s.metamask)}>
                <SocialButton
                  logo={IconM}
                  format={'auth'}
                  name={'Metamask'}
                  onClick={() =>
                    dispatch(setCurrentAuthorizationType(AuthorizationTypes.AuthMetaMask))
                  }
                />
              </div>
            )}
            {isConnected && (
              <ConnectWalletAuthProvider
                setAuthType={() =>
                  setCurrentAuthorizationType(AuthorizationTypes.AuthConnectWallet)
                }>
                <SocialButton
                  format={'wallet'}
                  logo={walletConnectIcon}
                  name={'WalletConnect'}
                />
              </ConnectWalletAuthProvider>
            )}
          </div>
        </div>
      )}
    </>
  );
}
