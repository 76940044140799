import { createAsyncThunk } from '@reduxjs/toolkit';
import mainRequest from '../../utils/mainRequestUtils';

export const referralsRequest = {
  getReferrals: createAsyncThunk(
    'referrals/getReferrals',
    async function (_, { rejectWithValue }) {
      try {
        const response = await mainRequest.get('/referrals/find-all');

        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
  getLink: createAsyncThunk('referral/getLink', async function (_, { rejectWithValue }) {
    try {
      const response = await mainRequest.get('/user/referral');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }),
};
