import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { AudioRate as AudioRateEnum } from '../../../core/constants/AudioSettings';
import {
  audioSettingsStore,
  setAudioRate,
} from '../../../core/store/slices/audioSettingsSlice';
import { Button } from '../../ui/buttons/Button';
import s from './style.module.scss';

export function AudioRate({ className }) {
  const dispatch = useDispatch();

  const { audioRate } = useSelector(audioSettingsStore);

  const switchRate = () => {
    switch (audioRate) {
      case AudioRateEnum.Normal:
        dispatch(setAudioRate(AudioRateEnum.Medium));
        break;
      case AudioRateEnum.Medium:
        dispatch(setAudioRate(AudioRateEnum.Fast));
        break;
      case AudioRateEnum.Fast:
        dispatch(setAudioRate(AudioRateEnum.Normal));
        break;
    }
  };

  return (
    <Button className={cn(s.audioRate, className)} onClick={switchRate}>
      {audioRate + 'x'}
    </Button>
  );
}
