import cn from 'classnames';
import { AudioState } from '../../../core/constants/AudioSettings';
import { Button } from '../../ui/buttons/Button';
import s from './style.module.scss';

export function PlayButton({ className, audioState, isCurrentAudio, onClick, ...props }) {
  const classes = cn(
    s.soundSwitch,
    'iconPlay',
    {
      ['iconPlay']:
        (audioState === AudioState.Pause || audioState === AudioState.Stop) &&
        isCurrentAudio,
    },
    { ['iconPause']: audioState === AudioState.Play && isCurrentAudio },
    { [s.soundSwitch_off]: audioState === AudioState.Play && isCurrentAudio },
    className
  );

  return <Button className={classes} onClick={onClick} {...props} />;
}
