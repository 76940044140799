import * as Sentry from '@sentry/react';
import { Component } from 'react';
import { ErrorFallback } from '../ErrorFallback';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    window.addEventListener('error', this.handleGlobalError);
    window.addEventListener('unhandledrejection', this.handleGlobalPromiseRejection);
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.handleGlobalError);
    window.removeEventListener('unhandledrejection', this.handleGlobalPromiseRejection);
  }

  componentDidCatch(error, errorInfo) {
    const eventId = Sentry.captureException(error, { extra: errorInfo });
    this.setState({ hasError: true, eventId });

    console.error('[APP:COMPONENT:ERROR] Uncaught error : ', error, errorInfo);
  }

  handleGlobalError = (event) => {
    console.error('[APP:GLOBAL:ERROR] Uncaught error : ', event.reason);

    Sentry.captureException(event.error || new Error(event.message));
    event.preventDefault();
    this.setState({ hasError: true, eventId: Sentry.lastEventId() });
  };

  handleGlobalPromiseRejection = (event) => {
    console.error('[APP:GLOBAL:PROMISE:ERROR] Uncaught error : ', event.reason);

    Sentry.captureException(event.reason);
    if (
      event.reason &&
      event.reason.message &&
      this.isWebSocketError(event.reason.message)
    ) {
      console.warn('WebSocket error ignored:', event.reason);

      return;
    }
    event.preventDefault();
    this.setState({ hasError: true, eventId: Sentry.lastEventId() });
  };

  isWebSocketError = (message) => {
    const webSocketErrorPhrases = [
      'WebSocket',
      'connection failed',
      'websocket connection failed',
      'WebSocket connection',
      'WebSocket connection failed',
      'WebSocket Error',
    ];

    return webSocketErrorPhrases.some((phrase) => message.includes(phrase));
  };

  render() {
    if (this.state.hasError) {
      return <ErrorFallback />;
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
