import cn from 'classnames';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import coin from '../../assets/images/icons/coin.svg';
import { formatPoints } from '../../core/helpers/formatPoints';
import { useCheckAuthorization } from '../../core/hooks/useCheckAuthorization';
import { userState } from '../../core/store/user/slice';
import { userRequest } from '../../core/store/user/thunk';
import s from './style.module.scss';

export function UserPoints({ className }) {
  const dispatch = useDispatch();
  const { isUserAuthorized } = useCheckAuthorization();
  const { userPoints } = useSelector(userState);

  const formattedPoints = useMemo(() => formatPoints(userPoints), [userPoints]);
  useEffect(() => {
    if (isUserAuthorized) {
      dispatch(userRequest.getPoints());
    }
  }, [isUserAuthorized]);

  return (
    <div className={cn(s.container, className)}>
      <img className={s.container__img} src={coin} alt='points' />
      <p className={s.container__points}>{formattedPoints}</p>
    </div>
  );
}
