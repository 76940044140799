import { createSlice } from '@reduxjs/toolkit';
import { StatusTypes } from '../../constants/StatusTypes';
import { rewardsRequest } from './thunk';

const initialState = {
  data: {
    signReward: null,
  },
  rewards: null,
  transactionStatus: null,
  transactionErrorType: null,

  signRewardStatus: null,
  signRewardError: null,

  getRewardsStatus: null,
  getRewardsError: null,

  updateStatusStatus: null,
  updateStatusError: null,
};

export const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    resetRewardsState: () => initialState,
    resetSignRewardState: (state) => {
      state.data.signReward = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(rewardsRequest.updateTransactionStatus.pending, (state) => {
        state.updateStatusStatus = StatusTypes.Loading;
        state.updateStatusError = null;
      })
      .addCase(rewardsRequest.updateTransactionStatus.fulfilled, (state) => {
        state.updateStatusStatus = StatusTypes.Resolved;
      })
      .addCase(rewardsRequest.updateTransactionStatus.rejected, (state, action) => {
        state.updateStatusStatus = StatusTypes.Rejected;
        state.updateStatusError = action.payload;
      })
      .addCase(rewardsRequest.getRewards.pending, (state) => {
        state.getRewardsStatus = StatusTypes.Loading;
        state.getRewardsError = null;
      })
      .addCase(rewardsRequest.getRewards.fulfilled, (state, action) => {
        state.getRewardsStatus = StatusTypes.Resolved;
        state.rewards = action.payload;
      })
      .addCase(rewardsRequest.getRewards.rejected, (state, action) => {
        state.getRewardsStatus = StatusTypes.Rejected;
        state.getRewardsError = action.payload;
      })
      .addCase(rewardsRequest.claim.pending, (state) => {
        state.signRewardStatus = StatusTypes.Loading;
        state.signRewardError = null;
      })
      .addCase(rewardsRequest.claim.fulfilled, (state, action) => {
        state.signRewardStatus = StatusTypes.Resolved;
        state.data.signReward = action.payload;
      })
      .addCase(rewardsRequest.claim.rejected, (state, action) => {
        state.signRewardStatus = StatusTypes.Rejected;
        state.signRewardError = action.payload;
      })
      .addCase(rewardsRequest.signClaimRewards.pending, (state) => {
        state.signRewardStatus = StatusTypes.Loading;
        state.signRewardError = null;
      })
      .addCase(rewardsRequest.signClaimRewards.fulfilled, (state, action) => {
        state.signRewardStatus = StatusTypes.Resolved;
        state.data.signReward = action.payload;
      })
      .addCase(rewardsRequest.signClaimRewards.rejected, (state, action) => {
        state.signRewardStatus = StatusTypes.Rejected;
        state.signRewardError = action.payload;
      });
  },
});

export const { resetRewardsState, resetSignRewardState } = rewardsSlice.actions;
export const rewardsState = (state) => state.rewards;

export default rewardsSlice.reducer;
