/**
 * Transform an object into a valid "search" string
 * @param { Object } locationQuery - An object with the search keys and values
 * @returns { String } A valid search string with ?key=value
 */
export const toString = (locationQuery) => {
  const cleanLocationQuery = Object.entries(locationQuery)
    .filter(
      ([key, value]) => value && value !== 'null' && value !== 'undefined' && key !== '_k'
    )
    .reduce((temp, [key, value]) => {
      temp[key] = value;

      return temp;
    }, {});

  const searchParams = new URLSearchParams(cleanLocationQuery);

  return searchParams.toString() ? `?${searchParams.toString()}` : '';
};

/**
 * Transform a search string into an object
 * @param {String} search - A String with pairs of params (key=value)
 * @returns {Object} An object with all the pairs in the search param
 */

export const toObject = (search) => {
  const searchParams = new URLSearchParams(search);
  const locationQuery = {};

  for (const [key, value] of searchParams) {
    locationQuery[key] = value;
  }

  return locationQuery;
};
