const TIME_TO_RELOAD = 5000;

const loadReCaptchaScript = () => {
  const scriptId = 'google-recaptcha-script';
  const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;

  if (!document.getElementById(scriptId)) {
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://www.google.com/recaptcha/api.js?&render=${recaptchaKey}`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.recaptchaScriptLoaded = true;
    };
    script.onerror = () => {
      console.error('Failed to load reCAPTCHA script');
      setTimeout(loadReCaptchaScript, TIME_TO_RELOAD);
    };
    document.head.appendChild(script);
  }
};

const checkReCaptchaLoaded = () => {
  if (window.grecaptcha && window.grecaptcha.execute) {
    window.recaptchaScriptLoaded = true;
  } else {
    loadReCaptchaScript();
  }
};

export { checkReCaptchaLoaded };
