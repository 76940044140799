export const LocalStorageItems = {
  AuthorizationToken: 'AuthorizationToken',
  AudioVolume: 'AudioVolume',
  AudioRate: 'AudioRate',
  AudioAutoplay: 'AudioAutoplay',
  Language: 'Language',
  MentorID: 'MentorID',
  IsAccountNew: 'IsAccountNew',
};

Object.freeze(LocalStorageItems);
