export const StatusCode = {
  Informational: 100,
  Successful: 200,
  Created: 201,
  Redirection: 300,
  ClientError: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  ServerError: 500,
  UnrecognizedChainId: 4902,
  RejectedRequest: 4001,
};

export const StatusTypes = {
  Loading: 'Loading',
  Resolved: 'Resolved',
  Rejected: 'Rejected',
};

export const MetamaskConnectionStatuses = {
  NoWallet: 'NoWallet',
  NoWalletAuth: 'NoWalletAuth',
  Connecting: 'Connecting',
  Error: 'Error',
  Sign: 'Sign',
  Connected: 'Connected',
  Finish: 'Finish',
  Claim: 'Claim',
};

export const ConnectWalletStatus = {
  Connected: 'connected',
  Disonnected: 'disconnected',
};

export const UserReferralStatus = {
  Referral: 'Referral',
  NoReferral: 'NoReferral',
};
export const TransactionStatuses = {
  Initiated: 'Initiated',
  Rejected: 'Rejected',
  Pending: 'Pending',
  Success: 'Success',
  Error: 'Error',
  ErrorVerification: 'ErrorVerification',
  NoWallet: 'NoWallet',
};

Object.freeze(TransactionStatuses);
Object.freeze(StatusCode);
Object.freeze(StatusTypes);
Object.freeze(MetamaskConnectionStatuses);
Object.freeze(ConnectWalletStatus);
Object.freeze(UserReferralStatus);
