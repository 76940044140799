import { AvailableGuideInfoIds, guideInfoFields } from './Guide';
import { PageNames } from './PageNames';
import { Socials } from './Socials';
import { TransactionStatuses } from './StatusTypes';

export const TEXT_KEYS = {
  [PageNames.Metamask]: [
    `${PageNames.Metamask}_NoWallet.statusText`,
    `${PageNames.Metamask}_NoWallet.descriptionTextHighlightedPart1`,
    `${PageNames.Metamask}_NoWallet.descriptionTextHighlightedPart2`,
    `${PageNames.Metamask}_NoWallet.buttonText`,
    `${PageNames.Metamask}_NoWalletAuth.statusText`,
    `${PageNames.Metamask}_NoWalletAuth.descriptionText`,
    `${PageNames.Metamask}_NoWalletAuth.buttonText`,
    `${PageNames.Metamask}_Connecting.statusText`,
    `${PageNames.Metamask}_Connecting.descriptionTextHighlightedPart1`,
    `${PageNames.Metamask}_Connecting.descriptionTextHighlightedPart2`,
    `${PageNames.Metamask}_Connecting.descriptionTextHighlightedPart3`,
    `${PageNames.Metamask}_Connecting.descriptionTextHighlightedPart4`,
    `${PageNames.Metamask}_Error.statusText`,
    `${PageNames.Metamask}_Error.descriptionText`,
    `${PageNames.Metamask}_Error.buttonText`,
    `${PageNames.Metamask}_Sign.statusText`,
    `${PageNames.Metamask}_Sign.descriptionTextHighlightedPart1`,
    `${PageNames.Metamask}_Sign.descriptionTextHighlightedPart2`,
    `${PageNames.Metamask}_Sign.descriptionTextHighlightedPart3`,
    `${PageNames.Metamask}_Connected.statusText`,
    `${PageNames.Metamask}_Connected.descriptionText`,
  ],
  [PageNames.Auth]: [
    `${PageNames.Auth}.title`,
    `${PageNames.Auth}.socialsTitle`,
    `${PageNames.Auth}.walletTitle`,
    `${PageNames.Auth}.twitter`,
    `${PageNames.Auth}.google`,
    `${PageNames.Auth}.email`,
    `${PageNames.Auth}.metamask`,
  ],
  [PageNames.Navigation]: [
    `${PageNames.Navigation}.game`,
    `${PageNames.Navigation}.guides`,
    `${PageNames.Navigation}.referrals`,
    `${PageNames.Navigation}.leaderboard`,
    `${PageNames.Navigation}.myGuides`,
  ],
  [PageNames.Hello]: [
    `${PageNames.Hello}.mascotTextHighlightedPart1`,
    `${PageNames.Hello}.mascotTextHighlightedPart2`,
    `${PageNames.Hello}.answerButtonText`,
  ],
  [PageNames.MailAuthorization]: [
    `${PageNames.MailAuthorization}.mailLabel`,
    `${PageNames.MailAuthorization}.mailErrorText_pattern`,
    `${PageNames.MailAuthorization}.mailPlaceholder`,
    `${PageNames.MailAuthorization}.passwordLabel`,
    `${PageNames.MailAuthorization}.passwordErrorText`,
    `${PageNames.MailAuthorization}.passwordPlaceholder`,
    `${PageNames.MailAuthorization}.button`,
  ],
  [PageNames.Header]: [
    `${PageNames.Header}.buttonLogin`,
    `${PageNames.Header}.buttonLogout`,
    `${PageNames.Header}.menuChat`,
    `${PageNames.Header}.menuGuides`,
    `${PageNames.Header}.menuReferralRewards`,
    `${PageNames.Header}.menuGuidesRewards`,
    `${PageNames.Header}.menuLeaderboard`,
    `${PageNames.Header}.menuWallet`,
    `${PageNames.Header}.menuAccountSettings`,
  ],
  [PageNames.ProfileGuides]: [
    `${PageNames.ProfileGuides}.btnText`,
    `${PageNames.ProfileGuides}.rewardsTitle`,
    `${PageNames.ProfileGuides}.ReferralTitle`,
    `${PageNames.ProfileGuides}.earningsTitle`,
    `${PageNames.ProfileGuides}.subtitle`,
    `${PageNames.ProfileGuides}.buttonText`,
  ],
  [PageNames.ProfileReferrals]: [
    `${PageNames.ProfileReferrals}.btnText`,
    `${PageNames.ProfileReferrals}.back`,
    `${PageNames.ProfileReferrals}.invite`,
    `${PageNames.ProfileReferrals}.howItWorks`,
    `${PageNames.ProfileReferrals}.rewardsTitle`,
    `${PageNames.ProfileReferrals}.ReferralTitle`,
    `${PageNames.ProfileReferrals}.earningsTitle`,
    `${PageNames.ProfileReferrals}_Referral.subtitle`,
    `${PageNames.ProfileReferrals}_NoReferral.subtitle`,
    `${PageNames.ProfileReferrals}.leftButtonText`,
    `${PageNames.ProfileReferrals}.rightButtonText`,
    `${PageNames.ProfileReferrals}.referralLinkTitle`,
    `${PageNames.ProfileReferrals}.adTitle`,
    `${PageNames.ProfileReferrals}.rewardForCompletion`,
    `${PageNames.ProfileReferrals}.referralReward`,
    `${PageNames.ProfileReferrals}.friendReward`,
  ],
  [PageNames.Profile]: [
    `${PageNames.Profile}.ReferralTab`,
    `${PageNames.Profile}.guidesTab`,
    `${PageNames.Profile}.walletTab`,
    `${PageNames.Profile}.settingsTab`,
    `${PageNames.Profile}.leaderboardTab`,
  ],
  [PageNames.ProfileSettings]: [
    `${PageNames.ProfileSettings}.userNameTitle`,
    `${PageNames.ProfileSettings}.socialTitle`,
    `${PageNames.ProfileSettings}.connectMail`,
    `${PageNames.ProfileSettings}.connectTwitter`,
    `${PageNames.ProfileSettings}.connectTelegram`,
    `${PageNames.ProfileSettings}.connectGoogle`,
    `${PageNames.ProfileSettings}.connectWallet`,
    `${PageNames.ProfileSettings}.setPass`,
    `${PageNames.ProfileSettings}.changePass`,
    `${PageNames.ProfileSettings}.logout`,
    `${PageNames.ProfileSettings}.editMailTitle`,
    `${PageNames.ProfileSettings}.editNameTitle`,
    `${PageNames.ProfileSettings}.setPassTitle`,
    `${PageNames.ProfileSettings}.changePassTitle`,
    `${PageNames.ProfileSettings}.fieldName`,
    `${PageNames.ProfileSettings}.fieldMail`,
    `${PageNames.ProfileSettings}.mailErrorText_pattern`,
    `${PageNames.ProfileSettings}.fieldPass`,
    `${PageNames.ProfileSettings}.passwordErrorText`,
    `${PageNames.ProfileSettings}.fieldEnterPass`,
    `${PageNames.ProfileSettings}.passwordSetErrorText`,
    `${PageNames.ProfileSettings}.fieldRepeatPass`,
    `${PageNames.ProfileSettings}.passwordRepeatErrorText`,
    `${PageNames.ProfileSettings}.fieldLastPass`,
    `${PageNames.ProfileSettings}.passwordLastErrorText`,
    `${PageNames.ProfileSettings}.fieldNewPass`,
    `${PageNames.ProfileSettings}.passwordNewErrorText`,
    `${PageNames.ProfileSettings}.connectSocial`,
    `${PageNames.ProfileSettings}.socialGoogle`,
    `${PageNames.ProfileSettings}.btnSave`,
    `${PageNames.ProfileSettings}.chooseSocialTitle`,
    `${PageNames.ProfileSettings}.btnChange`,
    `${PageNames.ProfileSettings}.preferredSocialTitle`,
    `${PageNames.ProfileSettings}.usernameError_required`,
    `${PageNames.ProfileSettings}.usernameError_general`,
    `${PageNames.ProfileSettings}.usernameError_maxLength`,
    `${PageNames.ProfileSettings}.usernameError_sameName`,
  ],
  [PageNames.ProfileWallet]: [
    `${PageNames.ProfileWallet}.title`,
    `${PageNames.ProfileWallet}.subtitleNoWallet`,
    `${PageNames.ProfileWallet}.balanceTitle`,
    `${PageNames.ProfileWallet}.statusWallet_connected`,
    `${PageNames.ProfileWallet}.statusWallet_disconnected`,
    `${PageNames.ProfileWallet}.networkTitle`,
    `${PageNames.ProfileWallet}.networkName_mainnet`,
    `${PageNames.ProfileWallet}.connectButton_connected`,
    `${PageNames.ProfileWallet}.connectButton_disconnected`,
    `${PageNames.ProfileWallet}.subtitle`,
    `${PageNames.ProfileWallet}.buttonText`,
    `${PageNames.ProfileWallet}.buttonShowMe`,
  ],
  [PageNames.GuidesGallery]: [
    `${PageNames.GuidesGallery}.subtitle`,
    `${PageNames.GuidesGallery}.buttonText`,
    `${PageNames.GuidesGallery}.tabs_active`,
    `${PageNames.GuidesGallery}.tabs_ended`,
    `${PageNames.GuidesGallery}.archiveButton`,
    `${PageNames.GuidesGallery}.guideStatus_inProgress`,
    `${PageNames.GuidesGallery}.guideStatus_completed`,
    `${PageNames.GuidesGallery}.guideStatus_new`,
    `${PageNames.GuidesGallery}.guideLevel_beginner`,
    `${PageNames.GuidesGallery}.guideLevel_medium`,
    `${PageNames.GuidesGallery}.guideLevel_advanced`,
    `${PageNames.GuidesGallery}.guideTime`,
    `${PageNames.GuidesGallery}.guideTimeTitle`,
    `${PageNames.GuidesGallery}.guideRewardTitle`,
    `${PageNames.GuidesGallery}.startButtonText_inProgress`,
    `${PageNames.GuidesGallery}.startButtonText_completed`,
    `${PageNames.GuidesGallery}.startButtonText_claim`,
    `${PageNames.GuidesGallery}.startButtonText_new`,
    `${PageNames.GuidesGallery}.timer_days`,
    `${PageNames.GuidesGallery}.timer_hours`,
    `${PageNames.GuidesGallery}.timer_minutes`,
    `${PageNames.GuidesGallery}.clueText`,
    `${PageNames.GuidesGallery}.progressBarLabel`,
    `${PageNames.GuidesGallery}.leftLabel`,
    `${PageNames.GuidesGallery}.concludingResults`,
    `${PageNames.GuidesGallery}.tryToClaimLater`,
    `${PageNames.GuidesGallery}.takeReward`,
  ],
  [PageNames.GuideResults]: [
    `${PageNames.GuideResults}.resultsTitle`,
    `${PageNames.GuideResults}.rewardPool`,
    `${PageNames.GuideResults}.totalPoints`,
    `${PageNames.GuideResults}.totalParticipants`,
    `${PageNames.GuideResults}.usernameColumn`,
    `${PageNames.GuideResults}.rewardColumn`,
    `${PageNames.GuideResults}.pointsColumn`,
    `${PageNames.GuideResults}.explorerColumn`,
  ],
  [PageNames.GuidesChat]: [
    `${PageNames.GuidesChat}.helpMe`,
    `${PageNames.GuidesChat}.help`,
    `${PageNames.GuidesChat}.guideStatus_inProgress`,
    `${PageNames.GuidesChat}.guideStatus_completed`,
    `${PageNames.GuidesChat}.guideStatus_new`,
    `${PageNames.GuidesChat}.actionText_next`,
    `${PageNames.GuidesChat}.actionText_select`,
    `${PageNames.GuidesChat}.inputPlaceholder`,
    `${PageNames.GuidesChat}.searchPlaceholder`,
    `${PageNames.GuidesChat}.searchTitle`,
    `${PageNames.GuidesChat}.errorMsg`,
    `${PageNames.GuidesChat}.errorMsgDescription_general`,
    `${PageNames.GuidesChat}.errorMsgDescription_captcha`,
    `${PageNames.GuidesChat}.errorMsgDescription_noSocial`,
    `${PageNames.GuidesChat}.errorMsgDescription_noWallet`,
    `${PageNames.GuidesChat}.errorMsgDescription_noTelegram`,
    `${PageNames.GuidesChat}.errorMsgDescription_noTwitter`,
    `${PageNames.GuidesChat}.errorMsgDescription_noLike`,
    `${PageNames.GuidesChat}.errorMsgDescription_noSubscription`,
    `${PageNames.GuidesChat}.errorMsgDescription_notClaimed`,
    `${PageNames.GuidesChat}.errorMsgDescription_linkClick`,
    `${PageNames.GuidesChat}.action_twitter`,
    `${PageNames.GuidesChat}.action_telegram`,
    `${PageNames.GuidesChat}.action_wallet`,
    `${PageNames.GuidesChat}.action_reward`,
    `${PageNames.GuidesChat}.action_referral`,
    `${PageNames.GuidesChat}.action_token`,
    `${PageNames.GuidesChat}.action_final`,
    `${PageNames.GuidesChat}.label_reward`,
    `${PageNames.GuidesChat}.label_minutes`,
    `${PageNames.GuidesChat}.button_letsGo`,
    `${PageNames.GuidesChat}.complexity_EASY`,
    `${PageNames.GuidesChat}.complexity_MEDIUM`,
    `${PageNames.GuidesChat}.complexity_HARD`,
    `${PageNames.GuidesChat}.leaderboardClue`,
    `${PageNames.GuidesChat}.leaderboardRewardClue`,
    `${PageNames.GuidesChat}.skipStepTitle`,
    `${PageNames.GuidesChat}.skipStepText`,
    `${PageNames.GuidesChat}.skipStepBtnDo`,
    `${PageNames.GuidesChat}.skipStepBtnSkip`,
    `${PageNames.GuidesChat}.previewText`,
  ],
  [PageNames.Claim]: [
    `${PageNames.Claim}.title`,
    `${PageNames.Claim}.transactionText`,
    `${PageNames.Claim}.geoText`,
    `${PageNames.Claim}.payFeeFrom`,
    `${PageNames.Claim}.btnText`,
  ],
  [PageNames.History]: [
    `${PageNames.History}.title`,
    `${PageNames.History}.text`,
    `${PageNames.History}.status_pending`,
    `${PageNames.History}.status_active`,
    `${PageNames.History}.status_failed`,
  ],
  [PageNames.ClaimReward]: [
    `${PageNames.ClaimReward}.title_noWallet`,
    `${PageNames.ClaimReward}.title_connecting`,
    `${PageNames.ClaimReward}.title_error`,
    `${PageNames.ClaimReward}.title_wrongNetwork`,
    `${PageNames.ClaimReward}.noWallet`,
    `${PageNames.ClaimReward}.walletNotConnected`,
    `${PageNames.ClaimReward}.switchNetwork`,
    `${PageNames.ClaimReward}.walletConnected`,
    `${PageNames.ClaimReward}.wrongWallet`,
    `${PageNames.ClaimReward}.connecting`,
    `${PageNames.ClaimReward}.canceledConnection`,
    `${PageNames.ClaimReward}.alreadyLinked`,
    `${PageNames.ClaimReward}.claimButton`,
    `${PageNames.ClaimReward}.connectButton`,
    `${PageNames.ClaimReward}.addButton`,
    `${PageNames.ClaimReward}.tryAgainButton`,
  ],
  [PageNames.TransactionStatus]: [
    `${PageNames.TransactionStatus}_${TransactionStatuses.Pending}.title`,
    `${PageNames.TransactionStatus}_${TransactionStatuses.Error}.title`,
    `${PageNames.TransactionStatus}_${TransactionStatuses.ErrorVerification}.title`,
    `${PageNames.TransactionStatus}_${TransactionStatuses.Initiated}.title`,
    `${PageNames.TransactionStatus}_${TransactionStatuses.Rejected}.title`,
    `${PageNames.TransactionStatus}_${TransactionStatuses.Success}.title`,

    `${PageNames.TransactionStatus}_${TransactionStatuses.Pending}.text`,
    `${PageNames.TransactionStatus}_${TransactionStatuses.Error}.text`,
    `${PageNames.TransactionStatus}_${TransactionStatuses.ErrorVerification}.text`,
    `${PageNames.TransactionStatus}_${TransactionStatuses.Initiated}.text`,
    `${PageNames.TransactionStatus}_${TransactionStatuses.Rejected}.text`,
    `${PageNames.TransactionStatus}_${TransactionStatuses.Success}.text`,

    `${PageNames.TransactionStatus}_${TransactionStatuses.Pending}.btnText`,
    `${PageNames.TransactionStatus}_${TransactionStatuses.Error}.btnText`,
    `${PageNames.TransactionStatus}_${TransactionStatuses.ErrorVerification}.btnText`,
    `${PageNames.TransactionStatus}.seeTransaction`,
  ],
  [PageNames.SocialsOauth]: [
    `${PageNames.SocialsOauth}.error`,
    `${PageNames.SocialsOauth}.errorDescription`,
    `${PageNames.SocialsOauth}.errorButton`,
    `${PageNames.SocialsOauth}_${Socials.Google}.error`,
    `${PageNames.SocialsOauth}_${Socials.Twitter}.error`,
    `${PageNames.SocialsOauth}_${Socials.Telegram}.error`,
  ],
  [PageNames.Support]: [
    `${PageNames.Support}.title`,
    `${PageNames.Support}.subtitle`,
    `${PageNames.Support}.text1`,
    `${PageNames.Support}.text2`,
    `${PageNames.Support}.text3`,
  ],
  [PageNames.GuideInfo]: [
    ...guideInfoFields.map((field) => `${PageNames.GuideInfo}.${field}`),
    ...AvailableGuideInfoIds.flatMap((id) =>
      guideInfoFields.map((field) => `${PageNames.GuideInfo}.q${id}_${field}`)
    ),
  ],
  [PageNames.ReferralLink]: [
    `${PageNames.ReferralLink}.title`,
    `${PageNames.ReferralLink}.linkMessage`,
    `${PageNames.ReferralLink}.linkMessageDescription`,
  ],
  [PageNames.Game]: [`${PageNames.Game}.energy`],
  [PageNames.TemporaryMobileError]: [
    `${PageNames.TemporaryMobileError}.title`,
    `${PageNames.TemporaryMobileError}.description`,
  ],
  [PageNames.Reward]: [
    `${PageNames.Reward}.title`,
    `${PageNames.Reward}.description`,
    `${PageNames.Reward}.button_claim`,
    `${PageNames.Reward}.button_take`,
  ],
  [PageNames.Referrals]: [
    `${PageNames.Referrals}.title`,
    `${PageNames.Referrals}.totalPoints`,
    `${PageNames.Referrals}.yourFriends`,
    `${PageNames.Referrals}.avgDaily`,
    `${PageNames.Referrals}.inviteButton`,
    `${PageNames.Referrals}.noFriends`,
    `${PageNames.Referrals}.noFriendsDescription`,
    `${PageNames.Referrals}.totalPointsTooltip`,
    `${PageNames.Referrals}.avgDailyTooltip`,
    `${PageNames.Referrals}.avgDailyTooltipIncludes`,
    `${PageNames.Referrals}.avgDailyTooltipCalculated`,
  ],
  [PageNames.GamePopup]: [
    `${PageNames.GamePopup}.title`,
    `${PageNames.GamePopup}.subtitle`,
    `${PageNames.GamePopup}.reward`,
    `${PageNames.GamePopup}.button`,
  ],
};
