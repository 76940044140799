import { createSlice } from '@reduxjs/toolkit';
import {
  AudioAutoplay,
  AudioRate,
  AudioState,
  AudioVolume,
} from '../../constants/AudioSettings';
import { LocalStorageItems } from '../../constants/LocalStorageItems';

const initialState = {
  audioRate: Number(localStorage[LocalStorageItems.AudioRate]) || AudioRate.Normal,
  audioVolume: Number(localStorage[LocalStorageItems.AudioVolume]) || AudioVolume.On,
  audioAutoplay:
    Number(localStorage[LocalStorageItems.AudioAutoplay]) || AudioAutoplay.Off,
  audioState: AudioState.Stop,
  audioQueue: [],
  currentAudio: null,
  prevAudio: null,
  pageAudio: null,
  userAction: false,
};

export const audioSettingsSlice = createSlice({
  name: 'audioSettings',
  initialState,
  reducers: {
    setAudioRate: (state, action) => {
      state.audioRate = action.payload;
      localStorage.setItem(LocalStorageItems.AudioRate, JSON.stringify(action.payload));
    },
    setAudioVolume: (state, action) => {
      state.audioVolume = action.payload;
      localStorage.setItem(LocalStorageItems.AudioVolume, JSON.stringify(action.payload));
    },
    setAudioState: (state, action) => {
      state.audioState = action.payload;
      if (action.payload !== AudioState.Stop) {
        state.audioAutoplay = action.payload;
        localStorage.setItem(
          LocalStorageItems.AudioAutoplay,
          JSON.stringify(action.payload)
        );
      }
    },

    setAudioQueue: (state, action) => {
      state.audioQueue = [...state.audioQueue, action.payload];
    },
    clearAudioQueue: (state) => {
      state.audioQueue = [];
    },
    setCurrentAudio: (state, action) => {
      state.currentAudio = action.payload;
    },
    setPrevAudio: (state, action) => {
      state.prevAudio = action.payload;
    },
    setPageAudio: (state, action) => {
      state.pageAudio = action.payload;
    },

    setUserAction: (state) => {
      state.userAction = true;
    },

    resetAudioSettingsState: (state) => {
      state.currentAudio = null;
      state.prevAudio = null;
      state.audioQueue = [];
      state.audioState = AudioState.Stop;
    },
    clearAudio: (state) => {
      state.currentAudio = null;
      state.prevAudio = null;
      state.audioQueue = [];
    },
  },
});

export const {
  setAudioRate,
  setAudioVolume,
  setAudioState,
  setAudioQueue,
  clearAudioQueue,
  setCurrentAudio,
  setPrevAudio,
  setPageAudio,
  resetAudioSettingsState,
  clearAudio,
} = audioSettingsSlice.actions;
export const audioSettingsStore = (state) => state.audioSettings;
export const userAction = (state) => state.audioSettings.userAction;

export default audioSettingsSlice.reducer;
