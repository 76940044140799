import { createAsyncThunk } from '@reduxjs/toolkit';
import mainRequest from '../../utils/mainRequestUtils';

export const gameRequest = {
  getConfig: createAsyncThunk('game/getConfig', async function (_, { rejectWithValue }) {
    try {
      const response = await mainRequest.get('/user-points/config');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }),

  updatePoints: createAsyncThunk(
    'game/updatePoints',
    async function ({ points }, { rejectWithValue }) {
      try {
        const requestData = {
          points,
        };
        const response = await mainRequest.post(
          '/user-points/add-user-points',
          requestData
        );

        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  ),
};
