import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from '../../components/mainParts/Header';
import { NavBar } from '../../components/mainParts/NavBar';
import { ItemNum } from '../../core/constants/ItemNum';
import { RoutesName } from '../../core/constants/Routes';
import { useCheckAuthorization } from '../../core/hooks/useCheckAuthorization';
import { useScreenSizes } from '../../core/hooks/useScreenSizes';
import s from './style.module.scss';

export function LayoutMain() {
  const path = useLocation().pathname;
  const layoutRef = useRef(null);
  const isGuidePage = path === RoutesName.GuidesPage;
  const isGuideChatPage = path.includes(RoutesName.GuidesChat);
  const [headerShadow, showHeaderShadow] = useState(false);
  const { height } = useScreenSizes();
  const { isUserAuthorized } = useCheckAuthorization();

  useEffect(() => {
    if (path === RoutesName.GuidesPage) {
      window.addEventListener('scroll', toggleHeaderShadow);
    } else {
      window.removeEventListener('scroll', toggleHeaderShadow);
    }

    return () => {
      window.removeEventListener('scroll', toggleHeaderShadow);
    };
  }, [path]);

  function toggleHeaderShadow() {
    if (document.documentElement.scrollTop > ItemNum.None) {
      showHeaderShadow(true);
    } else {
      showHeaderShadow(false);
    }
  }

  return (
    <div
      ref={layoutRef}
      className={cn(s.wrapper, isUserAuthorized && s.wrapper__padding, {
        [s.wrapper_sticky]: isGuidePage,
      })}
      style={{
        height: `${isGuidePage ? '100%' : height + 'px'}`,
        overflow: isGuideChatPage && 'hidden',
      }}>
      <div
        className={cn(s.wrapper__layout, {
          [s.wrapper__lights]: isGuidePage || isGuideChatPage,
        })}
      />
      <div
        className={cn(
          s.wrapper__header,
          {
            [s.wrapper__header_shadow]: headerShadow,
            [s.wrapper__header_sticky]: isGuidePage,
          },
          isGuideChatPage && s.wrapper__chatHeader
        )}>
        <Header />
      </div>
      {isUserAuthorized && <NavBar />}
      <div className={cn(s.wrapper__container, isUserAuthorized && s.wrapper__navbar)}>
        <div className={cn(s.wrapper__content)}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
