import iconEthereum from '../../assets/images/icons/ethereum.svg';
import iconMilkomeda from '../../assets/images/icons/milkomeda.svg';

export const bobChain = {
  id: 60808,
  name: 'BOB',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: ['https://bobmainnet.svc.blueshift.fi:8445'],
      webSocket: ['wss://bob-mainnet.public.blastapi.io'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://explorer.gobob.xyz',
    },
  },
  contracts: {
    multicall3: {
      address: '0x63f8279bccDb75c0F38e0CD6B6A0c72a0a760FF9',
      blockCreated: 457045,
    },
  },
  testnet: false,
};

export const NETWORKS_BY_ID = {
  1: {
    networkId: 1,
    chainId: '0x1',
    chainName: 'Mainnet',
    symbol: 'ETH',
    decimals: 18,
    rpcUrl: 'https://cloudflare-eth.com',
    blockExplorerUrl: 'https://etherscan.io',
    type: 'ERC20',
    labelName: 'Mainnet',
    networkIcon: iconEthereum,
  },
  11155111: {
    networkId: 11155111,
    chainId: '0xaa36a7',
    chainName: 'Mainnet',
    symbol: 'ETH',
    decimals: 18,
    rpcUrl: 'https://ethereum-sepolia-rpc.publicnode.com',
    blockExplorerUrl: 'https://sepolia.etherscan.io',
    type: 'ERC20',
    labelName: 'Sepolia',
    networkIcon: iconEthereum,
  },
  60808: {
    networkId: 60808,
    chainId: '0xaa36a7',
    chainName: 'BOB Mainnet',
    symbol: 'ETH',
    decimals: 18,
    rpcUrl: 'https://bob-mainnet.public.blastapi.io',
    blockExplorerUrl: 'https://explorer.gobob.xyz',
    type: 'ERC20',
    labelName: 'Sepolia',
    networkIcon: iconEthereum,
  },
  2001: {
    networkId: 2001,
    chainId: '0x7d1',
    chainName: 'Milkomeda C1 Mainnet',
    symbol: 'mADA',
    decimals: 18,
    rpcUrl: 'https://rpc-mainnet-cardano-evm.c1.milkomeda.com',
    blockExplorerUrl: 'https://explorer-mainnet-cardano-evm.c1.milkomeda.com',
    type: 'ERC20',
    labelName: 'Milkomeda',
    networkIcon: iconMilkomeda,
  },
};
