import { ChakraProvider } from '@chakra-ui/react';
import { theme } from 'core-frontend-sdk';
import ReactDOM from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app';
import { ErrorBoundary } from './components/Errors/ErrorBoundary';
import { Web3ModalProvider } from './components/providers/Web3ModalProvider';
import { store } from './core/store/store';
import { ThemeProvider } from './core/theme/ThemeProvider';
import './index.css';
import { checkReCaptchaLoaded } from './core/utils/configurations/recaptcha';
import { setupWebSocketErrorHandler } from './core/utils/configurations/websocketErrorsHandler';
import { defineZaloJSV2 } from './core/utils/configurations/zalo';

checkReCaptchaLoaded();
//TODO delete sentry? initializeSentry();
defineZaloJSV2();
setupWebSocketErrorHandler();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <ChakraProvider theme={theme}>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}>
              <Web3ModalProvider>
                <App />
              </Web3ModalProvider>
            </GoogleReCaptchaProvider>
          </ChakraProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);
