import { createSlice } from '@reduxjs/toolkit';
import { StatusTypes } from '../../constants/StatusTypes';
import { gameRequest } from './thunk';

const initialState = {
  clickerConfig: null,
  statusClickerConfig: null,
  statusUpdatePoints: null,
  errorClickerConfig: null,
  errorUpdatePoints: null,
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    resetGameState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(gameRequest.getConfig.pending, (state) => {
        state.statusClickerConfig = StatusTypes.Loading;
        state.errorClickerConfig = null;
      })
      .addCase(gameRequest.getConfig.fulfilled, (state, action) => {
        state.statusClickerConfig = StatusTypes.Resolved;
        state.clickerConfig = action.payload;
      })
      .addCase(gameRequest.getConfig.rejected, (state, action) => {
        state.statusClickerConfig = StatusTypes.Rejected;
        state.errorClickerConfig = action.payload.message;
      })
      .addCase(gameRequest.updatePoints.pending, (state) => {
        state.statusUpdatePoints = StatusTypes.Loading;
        state.errorUpdatePoints = null;
      })
      .addCase(gameRequest.updatePoints.fulfilled, (state, action) => {
        state.statusUpdatePoints = StatusTypes.Resolved;
        state.clickerConfig = action.payload;
      })
      .addCase(gameRequest.updatePoints.rejected, (state, action) => {
        state.statusUpdatePoints = StatusTypes.Rejected;
        state.errorUpdatePoints = action.payload.message;
      });
  },
});

export const { resetGameState } = gameSlice.actions;

/**
 *
 * @param {*} state
 * @returns {initialState}
 */
export const gameState = (state) => state.game;

export default gameSlice.reducer;
