export const ExternalStatuses = {
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  FINISHED_NOT_CLAIMED: 'FINISHED_NOT_CLAIMED',
  FINISHED_CLAIMED: 'FINISHED_CLAIMED',
  FINISHED_CLAIM_IN_PROGRESS: 'FINISHED_CLAIM_IN_PROGRESS',
  AVAILABLE: 'AVAILABLE',
  DISABLED: 'DISABLED',
  BANNER: 'BANNER',
  CONCLUDING_RESULTS: 'CONCLUDING_RESULTS',
};

export const GuideFieldTypes = {
  Title: 'title',
  Partner: 'partner',
  Description: 'description',
  Date: 'date',
  Reward: 'reward',
  Total: 'total',
  Investor: 'investor',
  PartnerLink: 'partnerLink',
};

export const guideInfoFields = Object.values(GuideFieldTypes);

const ONBOARDING_ID = 1;
const BOB_BLUESHIFT_ID = 3;
const BOB_MEMECORN_ID = 4;
const BOB_ID = 5;
const CLIP_FINANCE_ID = 6;
const TAIKO_ID = 12;

export const AvailableGuideInfoIds = [
  ONBOARDING_ID,
  BOB_BLUESHIFT_ID,
  BOB_MEMECORN_ID,
  BOB_ID,
  CLIP_FINANCE_ID,
  TAIKO_ID,
];

Object.freeze(ExternalStatuses);
