import { Center, chakra, Image, Text } from '@chakra-ui/react';
import { NavigationLink } from '../../../ui/Navlink';

const ChakraNavigationLink = chakra(NavigationLink);

// variants - default | drawerItem

export function NavItem({ text, icon, route, variant = 'default', ...styles }) {
  return (
    <ChakraNavigationLink
      to={route}
      sx={{
        '@media screen and (max-width: 1024px)': {
          flex: '1',
          width: '100%',
          p: variant === 'drawerItem' ? '0px' : '10px 12px',
        },
      }}
      {...styles}>
      <Center
        w={variant === 'default' ? '100%' : 'fit-content'}
        gap='8px'
        p='8px 0px 8px 12px'
        paddingRight={variant !== 'default' ? '12px' : '0'}
        borderRadius='12px'
        color='white.default'
        justifyContent='flex-start'
        sx={{
          _hover: {
            color: 'green.100',
            bg: 'linear-gradient(93deg, #2F2F2F 0%, rgba(69, 69, 69, 0.30) 48.43%, rgba(195, 195, 195, 0.00) 104.66%)',
          },
          '@media screen and (max-width: 1024px)': {
            flexDirection: variant === 'drawerItem' ? 'row' : 'column',
            w: '100%',
            h: variant === 'drawerItem' && '56px',
            p: variant === 'drawerItem' ? '0px 12px' : '0',
            gap: variant === 'drawerItem' ? '4px' : '0px',
            _hover: {
              bg: 'rgba(255, 255, 255, 0.05);',
              color: variant === 'drawerItem' ? 'green.100' : 'white.default',
            },
          },
        }}>
        <Image src={icon} alt={text} h='24px' w='24px' />
        <Text
          display={variant !== 'shortened' ? 'contents' : 'none'}
          sx={{
            fontWeight: '500',
            '@media screen and (max-width: 1024px)': {
              lineHeight: '16px',
              fontSize: '12px',
              display: 'contents',
            },
          }}>
          {text}
        </Text>
      </Center>
    </ChakraNavigationLink>
  );
}
