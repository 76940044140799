import cn from 'classnames';
import mascotError from '../../../assets/images/mascot/mascotError.svg';
import { AccentButton } from '../../ui/buttons/Button';
import s from './style.module.scss';

export function ErrorFallback() {
  return (
    <div className={s.container}>
      <div className={s.info}>
        <h2 className={cn(s.info__text, s.info__title)}>Oops...Something went wrong</h2>
        <h2 className={s.info__text}>Try reloading the page</h2>
        <AccentButton className={s.info__button} onClick={() => window.location.reload()}>
          Reload the page
        </AccentButton>
      </div>
      <img className={s.info__img} src={mascotError} alt='mascotError' />
    </div>
  );
}
