const defineZaloJSV2 = () => {
  /* eslint-disable */
  window.zaloJSV2 = {
    zalo_h5_event_handler: function (eventId, eventName, eventData) {
    },
  };
  /* eslint-enable */
};

export { defineZaloJSV2 };
