import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SocialConnectionTypes } from '../../../core/constants/AuthorizationTypes';
import { LocalStorageItems } from '../../../core/constants/LocalStorageItems';
import { setWindowOptions } from '../../../core/helpers/forWindow';
import { isMobileDevice } from '../../../core/helpers/isMobileDevice';
import { useCheckAuthorization } from '../../../core/hooks/useCheckAuthorization';
import { setAuthorizationToken } from '../../../core/store/auth/slice';
import { userRequest } from '../../../core/store/user/thunk';

export function TwitterAuthProvider({
  children,
  setAuthType,
  type = SocialConnectionTypes.AUTH,
}) {
  const dispatch = useDispatch();
  const [isConnectionDone, setIsConnectionDone] = useState(false);
  const { isUserAuthorized } = useCheckAuthorization();
  const [openWindowMode, setOpenWindowMode] = useState('_blank');
  const checkAuthIntervalId = useRef(null);
  const fetchUserDataIntervalId = useRef(null);
  const timeInterval = 1000;

  useEffect(() => {
    const isMobile = isMobileDevice();
    if (isMobile) {
      setOpenWindowMode('_self');
    }
  }, []);

  const redirectToTwitterAuthorization = () => {
    const windowOptions = setWindowOptions();
    const authUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_TWITTER_AUTH_URL}`;
    window.open(authUrl, openWindowMode, windowOptions);

    checkAuthorizationStop();
  };

  const checkAuthorizationStop = () => {
    if (!checkAuthIntervalId.current) {
      checkAuthIntervalId.current = setInterval(() => {
        if (localStorage[LocalStorageItems.AuthorizationToken]) {
          setIsConnectionDone(true);
          clearInterval(checkAuthIntervalId.current);
          checkAuthIntervalId.current = null;
        }
      }, timeInterval);
    }
  };

  useEffect(() => {
    if (isConnectionDone && type === SocialConnectionTypes.LINK) {
      if (!fetchUserDataIntervalId.current) {
        fetchUserDataIntervalId.current = setInterval(() => {
          dispatch(userRequest.getUserData()).then((userData) => {
            if (userData.payload && userData.payload.twitter) {
              clearInterval(fetchUserDataIntervalId.current);
              fetchUserDataIntervalId.current = null;
            }
          });
        }, timeInterval);
      }
    } else if (isConnectionDone && type === SocialConnectionTypes.AUTH) {
      dispatch(setAuthorizationToken(localStorage[LocalStorageItems.AuthorizationToken]));
    }

    return () => {
      if (checkAuthIntervalId.current) {
        clearInterval(checkAuthIntervalId.current);
      }
      if (fetchUserDataIntervalId.current) {
        clearInterval(fetchUserDataIntervalId.current);
      }
    };
  }, [isConnectionDone]);

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          onClick: () => {
            redirectToTwitterAuthorization();
            if (!isUserAuthorized) {
              dispatch(setAuthType());
            }
          },
        });
      })}
    </>
  );
}
