import { linea, mainnet, polygon, sepolia, taiko } from 'wagmi/chains';
import { bobChain } from './networks';

export const supportedChainsById = {
  [bobChain.id]: bobChain,
  [taiko.id]: taiko,
  [sepolia.id]: sepolia,
  [linea.id]: linea,
  [polygon.id]: polygon,
  [mainnet.id]: mainnet,
};

export const supportedChains = Object.values(supportedChainsById);
Object.freeze(supportedChains);
