import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useEffect, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useDisconnect, useSwitchChain } from 'wagmi';
import mascotError from '../../../assets/images/mascot/mascotError2.svg';
import mascotSmiles from '../../../assets/images/mascot/mascotSmiles.svg';
import { LoaderMascotWithStikers } from '../../../components/ui/loaders/LoaderMascotWithStikers';
import { SocialConnectionTypes } from '../../../core/constants/AuthorizationTypes';
import { ErrorTypes } from '../../../core/constants/ErrorMessages';
import { PageNames } from '../../../core/constants/PageNames';
import { StatusTypes, TransactionStatuses } from '../../../core/constants/StatusTypes';
import { STATIC_TEXT } from '../../../core/constants/staticText';
import walletNumberConverter from '../../../core/helpers/walletNumberConverter';
import { useCheckAuthorization } from '../../../core/hooks/useCheckAuthorization';
import { useClaimAirdrop } from '../../../core/hooks/useClaimAirdropNew';
import { useRequestStaticText } from '../../../core/hooks/useRequestStaticText';
import { allAuth } from '../../../core/store/auth/slice';
import { resetSignRewardState, rewardsState } from '../../../core/store/rewards/slice';
import { rewardsRequest } from '../../../core/store/rewards/thunk';
import { guideRequest } from '../../../core/store/showcase/thunk';
import { showClaimWindow } from '../../../core/store/slices/modalWindowStateSlice';
import { userState } from '../../../core/store/user/slice';
import { userRequest } from '../../../core/store/user/thunk';
import { ConnectWalletAuthProvider } from '../../Auth/providers/ConnectWalletAuthProvider';
import ContentPanel from '../../uiNext/ContentPanel';
import Popup from '../../uiNext/Popup';
import PopupActions from '../../uiNext/PopupActions';
import PopupText from '../../uiNext/PopupText';
import PopupTitle from '../../uiNext/PopupTitle';
import MainButton from '../../uiNext/buttons/MainButton';
import { TxStatusPopup } from '../TxStatusPopup';
import s from './style.module.scss';

const claimStates = {
  NO_WALLET: 'NO_WALLET',
  WALLET_NOT_CONNECTED: 'WALLET_NOT_CONNECTED',
  SWITCH_NETWORK: 'SWITCH_NETWORK',
  WALLET_CONNECTED: 'WALLET_CONNECTED',
  WALLET_ALREADY_EXISTS: 'WALLET_ALREADY_EXISTS',
  CONNECTED_WRONG_WALLET: 'CONNECTED_WRONG_WALLET',
  CONNECTION_IN_PROGRESS: 'CONNECTION_IN_PROGRESS',
  CANCELED_CONNECTION: 'CANCELED_CONNECTION',
};
const staticText = STATIC_TEXT[PageNames.ClaimReward];

export function ClaimRewardPopup({ guideId, rewardChainId }) {
  const dispatch = useDispatch();
  const [claimState, setClaimState] = useState(null);
  const [showTxStatusPopup, setShowTxStatusPopup] = useState(false);
  const [txStatus, setTxStatus] = useState(null);
  const { isUserAuthorized } = useCheckAuthorization();
  const { errorType } = useSelector(allAuth);
  const { text } = useRequestStaticText(PageNames.ClaimReward);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { disconnect } = useDisconnect();
  const { userData } = useSelector(userState);
  const { isConnected, isDisconnected, isConnecting, address, chainId } = useAccount();
  const { open: openModalWindow } = useWeb3Modal();
  const { switchChain } = useSwitchChain();
  const { signRewardStatus, data } = useSelector(rewardsState);

  const {
    doClaimAirdrop,
    isPending,
    isProcessing,
    isSuccess,
    isRejected,
    isError,
    explorerUrl,
  } = useClaimAirdrop();

  const handleClaim = async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha('receive_token');
    doClaimAirdrop(guideId, token);
    setTxStatus(TransactionStatuses.Initiated);
    setShowTxStatusPopup(true);
  };

  const handleCloseTransactionPopup = () => {
    setShowTxStatusPopup(false);
    dispatch(resetSignRewardState());
    dispatch(guideRequest.getData());
    dispatch(
      showClaimWindow({
        opened: false,
        id: null,
      })
    );
  };

  const dataToClaimState = useMemo(
    () => ({
      [claimStates.NO_WALLET]: {
        title: text?.title?.noWallet || staticText.title.noWallet,
        text: text?.noWallet || staticText.noWallet,
        btnText: text?.addButton || staticText.addButton,
        sticker: '🔥',
      },
      [claimStates.WALLET_NOT_CONNECTED]: {
        title: text?.title?.connecting || staticText.title.connecting,
        text: text?.walletNotConnected || staticText.walletNotConnected,
        btnText: text?.connectButton || staticText.connectButton,
        sticker: '⌛️',
      },
      [claimStates.CONNECTION_IN_PROGRESS]: {
        title: text?.title?.connecting || staticText.title.connecting,
        text: text?.connecting || staticText.connecting,
        sticker: '⌛️',
      },
      [claimStates.CONNECTED_WRONG_WALLET]: {
        title: text?.title?.error || staticText.title.error,
        text: text?.wrongWallet || staticText.wrongWallet,
        btnText: text?.tryAgainButton || staticText.tryAgainButton,
        sticker: '❌️️',
      },
      [claimStates.CANCELED_CONNECTION]: {
        title: text?.title?.error || staticText.title.error,
        text: text?.canceledConnection || staticText.canceledConnection,
        btnText: text?.tryAgainButton || staticText.tryAgainButton,
        sticker: '❌️️',
      },
      [claimStates.WALLET_ALREADY_EXISTS]: {
        title: text?.title?.error || staticText.title.error,
        text: text?.alreadyLinked || staticText.alreadyLinked,
        btnText: text?.tryAgainButton || staticText.tryAgainButton,
        sticker: '❌️️',
      },
      [claimStates.SWITCH_NETWORK]: {
        title: text?.title?.connecting || staticText.title.connecting,
        text: text?.switchNetwork || staticText.switchNetwork,
        sticker: '⌛️',
      },
      [claimStates.WALLET_CONNECTED]: {
        title: text?.title?.connecting || staticText.title.connecting,
        text: text?.walletConnected || staticText.walletConnected,
        btnText: text?.claimButton || staticText.claimButton,
        sticker: '🔥️',
      },
    }),
    [text]
  );

  const isConnectionError = useMemo(
    () =>
      [
        claimStates.CONNECTED_WRONG_WALLET,
        claimStates.CANCELED_CONNECTION,
        claimStates.WALLET_ALREADY_EXISTS,
      ].includes(claimState),
    [claimState]
  );

  useEffect(() => {
    if (userData?.walletAddress) {
      if (!isConnected) {
        if (claimState !== claimStates.CONNECTED_WRONG_WALLET) {
          setClaimState(claimStates.WALLET_NOT_CONNECTED);
        }
      } else {
        if (address.toLowerCase() === userData.walletAddress) {
          if (chainId !== rewardChainId) {
            setClaimState(claimStates.SWITCH_NETWORK);
            switchChain({ chainId: rewardChainId });
          } else {
            setClaimState(claimStates.WALLET_CONNECTED);
          }
        } else {
          setClaimState(claimStates.CONNECTED_WRONG_WALLET);
          disconnect();
        }
      }
    } else if (claimState !== claimStates.WALLET_ALREADY_EXISTS) {
      setClaimState(claimStates.NO_WALLET);
    }
  }, [userData?.walletAddress, address, chainId]);

  useEffect(() => {
    if (!isPending) return;

    setTxStatus(TransactionStatuses.Initiated);
  }, [isPending]);

  useEffect(() => {
    if (!isProcessing) return;

    setTxStatus(TransactionStatuses.Pending);
  }, [isProcessing]);

  useEffect(() => {
    if (!isSuccess) return;

    setTxStatus(TransactionStatuses.Success);
  }, [isSuccess]);

  useEffect(() => {
    if (!isRejected && !isError) return;

    const transactionStatus = isRejected
      ? TransactionStatuses.Rejected
      : TransactionStatuses.Error;

    setTxStatus(transactionStatus);

    async function updateStatus() {
      if (!executeRecaptcha) {
        return;
      }
      const token = await executeRecaptcha('receive_token');

      dispatch(
        rewardsRequest.updateTransactionStatus({
          guideId,
          token,
          status: transactionStatus,
        })
      );
    }

    updateStatus();
  }, [isRejected, isError]);

  useEffect(() => {
    if (
      signRewardStatus === StatusTypes.Rejected ||
      [ErrorTypes.CAPTCHA_ERROR, ErrorTypes.ERROR].includes(data.signReward?.state?.type)
    ) {
      setTxStatus(TransactionStatuses.Error);
    }
  }, [signRewardStatus, data.signReward]);

  useEffect(() => {
    if (isConnecting) {
      setClaimState(claimStates.CONNECTION_IN_PROGRESS);
    }
  }, [isConnecting]);

  useEffect(() => {
    if (isDisconnected && claimState === claimStates.CONNECTION_IN_PROGRESS) {
      setClaimState(claimStates.CANCELED_CONNECTION);
    }
  }, [isDisconnected, claimState]);

  useEffect(() => {
    if (errorType === ErrorTypes.WALLET_ALREADY_EXISTS) {
      setClaimState(claimStates.WALLET_ALREADY_EXISTS);
    }
  }, [errorType]);

  useEffect(() => {
    if (isUserAuthorized) {
      dispatch(userRequest.getUserData());
    }
  }, []);

  return (
    <>
      {!showTxStatusPopup && (
        <Popup onClose={() => dispatch(showClaimWindow(false))}>
          <div className={s.mascot}>
            <LoaderMascotWithStikers
              mascotImg={isConnectionError ? mascotError : mascotSmiles}
              spinnerSize={'200px'}
              spinnerTopPosition={'-25%'}
            />
          </div>
          <div className={s.content}>
            <PopupTitle
              label={dataToClaimState[claimState]?.title}
              color={isConnectionError ? 'red' : 'default'}
            />
            <p className={s.content__sticker}>{dataToClaimState[claimState]?.sticker}</p>
            <div className={s.content__infoBox}>
              <ContentPanel
                className={s.content__infoBox__panel}
                background='secondary'
                borderColor={isConnectionError ? 'red' : 'secondary'}>
                <PopupText className={s.content__text}>
                  {dataToClaimState[claimState]?.text}
                </PopupText>
                {[claimStates.CONNECTED_WRONG_WALLET].includes(claimState) && (
                  <PopupText className={s.content__infoBox__text}>
                    {userData?.walletAddress
                      ? walletNumberConverter(userData.walletAddress)
                      : ''}
                  </PopupText>
                )}
              </ContentPanel>
            </div>
            <div className={s.content__mascot}>
              <LoaderMascotWithStikers
                mascotImg={isConnectionError ? mascotError : mascotSmiles}
                spinnerSize={'190px'}
                spinnerTopPosition={'-25%'}
              />
            </div>
            <PopupActions>
              {claimState === claimStates.NO_WALLET && (
                <ConnectWalletAuthProvider type={SocialConnectionTypes.LINK}>
                  <MainButton className={s.content__button}>
                    {text?.addButton || staticText.addButton}
                  </MainButton>
                </ConnectWalletAuthProvider>
              )}
              {[
                claimStates.WALLET_NOT_CONNECTED,
                claimStates.CONNECTED_WRONG_WALLET,
                claimStates.CANCELED_CONNECTION,
                claimStates.WALLET_ALREADY_EXISTS,
              ].includes(claimState) && (
                <MainButton
                  onClick={() => openModalWindow()}
                  className={s.content__button}>
                  {dataToClaimState[claimState]?.btnText}
                </MainButton>
              )}
              {claimState === claimStates.WALLET_CONNECTED && (
                <MainButton onClick={() => handleClaim()} className={s.content__button}>
                  {dataToClaimState[claimState]?.btnText}
                </MainButton>
              )}
            </PopupActions>
          </div>
        </Popup>
      )}
      {showTxStatusPopup && (
        <TxStatusPopup
          status={txStatus}
          explorerUrl={explorerUrl}
          onClose={handleCloseTransactionPopup}
        />
      )}
    </>
  );
}
