import { configureStore } from '@reduxjs/toolkit';
import authorization from './auth/slice';
import game from './game/slice';
import guideChat from './guideChat/slice';
import leaderboard from './leaderboard/slice';
import metamask from './metamask/slice';
import referrals from './referrals/slice';
import rewards from './rewards/slice';
import guide from './showcase/slice';
import audioSettings from './slices/audioSettingsSlice';
import generalStyles from './slices/generalStylesSlice';
import modalWindowState from './slices/modalWindowStateSlice';
import soundSettings from './slices/soundSettingsSlice';
import staticAudio from './staticAudio/slice';
import staticText from './staticText/slice';
import user from './user/slice';

export const store = configureStore({
  reducer: {
    audioSettings,
    authorization,
    guide,
    guideChat,
    generalStyles,
    leaderboard,
    metamask,
    modalWindowState,
    soundSettings,
    staticText,
    staticAudio,
    rewards,
    game,
    user,
    referrals,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});
