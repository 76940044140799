export const ErrorTypes = {
  NO_SOCIAL: 'NO_SOCIAL',
  NO_WALLET: 'NO_WALLET',
  NO_TWITTER: 'NO_TWITTER',
  NO_TELEGRAM: 'NO_TELEGRAM',
  NOT_CLAIMED_GAS: 'NOT_CLAIMED_GAS',
  TWITTER_SUBSCRIPTION_NOT_FOUND: 'TWITTER_SUBSCRIPTION_NOT_FOUND',
  TELEGRAM_SUBSCRIPTION_NOT_FOUND: 'TELEGRAM_SUBSCRIPTION_NOT_FOUND',
  SUBSCRIPTION_NOT_FOUND: 'SUBSCRIPTION_NOT_FOUND',
  LIKE_NOT_FOUND: 'LIKE_NOT_FOUND',
  CAPTCHA_ERROR: 'CAPTCHA_ERROR',
  ERROR: 'ERROR',
  TWITTER_ALREADY_EXISTS: 'TWITTER_ALREADY_EXISTS',
  GMAIL_ALREADY_EXISTS: 'GMAIL_ALREADY_EXISTS',
  WALLET_ALREADY_EXISTS: 'WALLET_ALREADY_EXISTS',
  WALLET_NOT_CONNECTED: 'WALLET_NOT_CONNECTED',
  NO_LINK_CLICKED: 'NO_LINK_CLICKED',
};

export const ErrorTypesToMessages = {
  [ErrorTypes.NO_SOCIAL]: "Any social network wasn't add",
  [ErrorTypes.WALLET_NOT_CONNECTED]: 'Wallet not connected',
  [ErrorTypes.NO_WALLET]: "Wallet address wasn't add",
  [ErrorTypes.NO_TWITTER]: "TWITTER wasn't add",
  [ErrorTypes.NO_TELEGRAM]: "TELEGRAM wasn't add",
  [ErrorTypes.NOT_CLAIMED_GAS]: 'User not claimed gas',
  [ErrorTypes.SUBSCRIPTION_NOT_FOUND]: 'User action not found SUBSCRIPTION',
  [ErrorTypes.TWITTER_SUBSCRIPTION_NOT_FOUND]: 'User action not found TWITTER',
  [ErrorTypes.TELEGRAM_SUBSCRIPTION_NOT_FOUND]: 'User action not found TELEGRAM',
  [ErrorTypes.LIKE_NOT_FOUND]: 'User action not found LIKE',
  [ErrorTypes.CAPTCHA_ERROR]: 'Captcha failed',
  [ErrorTypes.TWITTER_ALREADY_EXISTS]: 'Twitter id already registered',
  [ErrorTypes.GMAIL_ALREADY_EXISTS]: 'Gmail already registered',
  [ErrorTypes.WALLET_ALREADY_EXISTS]: 'Wallet address already registered',
  [ErrorTypes.NO_LINK_CLICKED]: 'Invalid user action',
};
