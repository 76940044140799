import cn from 'classnames';
import s from './style.module.scss';

function DefaultButton({ children, onClick, className }) {
  return (
    <button className={cn(s.button, className)} onClick={onClick}>
      <p>{children}</p>
    </button>
  );
}

export default DefaultButton;
