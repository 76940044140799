import { useWeb3Modal } from '@web3modal/wagmi/react';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import { SocialConnectionTypes } from '../../../core/constants/AuthorizationTypes';
import { StatusTypes } from '../../../core/constants/StatusTypes';
import useLocationQuery from '../../../core/helpers/useLocationQueryHelper';
import { useCheckAuthorization } from '../../../core/hooks/useCheckAuthorization';
import {
  allAuth,
  clearStatusesAndErrors,
  clearSignMessage,
  setReadyToSignMessage,
} from '../../../core/store/auth/slice';
import { authRequest } from '../../../core/store/auth/thunk';
import { userState } from '../../../core/store/user/slice';
import { userRequest } from '../../../core/store/user/thunk';

const generateUUID = () => crypto.randomUUID();

export function ConnectWalletAuthProvider({
  children,
  setAuthType,
  type = SocialConnectionTypes.AUTH,
}) {
  const dispatch = useDispatch();
  const [uuid, setUuid] = useState(null);
  const { isUserAuthorized } = useCheckAuthorization();
  const { isConnected, connector } = useAccount();
  const { signMessage, data: signature, reset: resetSignature } = useSignMessage();
  const { open: openModalWindow } = useWeb3Modal();
  const {
    signMessage: signMessageToken,
    linkStatus,
    isReadyToSignMessage,
  } = useSelector(allAuth);
  const { mentorId } = useLocationQuery();
  const { userData, userDataStatus } = useSelector(userState);
  const { disconnect } = useDisconnect();

  const clearConnection = () => {
    setUuid(null);
    disconnect();
    dispatch(clearStatusesAndErrors());
    dispatch(clearSignMessage());
    resetSignature();
  };

  const handleClickButton = useCallback(() => {
    dispatch(setReadyToSignMessage(true));
    if (!isConnected) {
      openModalWindow();
    }
    if (!isUserAuthorized) {
      dispatch(setAuthType(type));
    }
  }, [openModalWindow, isUserAuthorized, isConnected, isReadyToSignMessage]);

  useEffect(() => {
    if (
      signMessageToken &&
      !signature &&
      !userData?.walletAddress &&
      userDataStatus !== StatusTypes.Loading
    ) {
      signMessage({ message: signMessageToken });
    }
  }, [signMessageToken]);

  useEffect(() => {
    if (
      isConnected &&
      !uuid &&
      !userData?.walletAddress &&
      userDataStatus !== StatusTypes.Loading
    ) {
      setUuid(generateUUID());
    }
    if (
      isConnected &&
      uuid &&
      !userData?.walletAddress &&
      userDataStatus !== StatusTypes.Loading &&
      linkStatus !== StatusTypes.Rejected &&
      isReadyToSignMessage
    ) {
      dispatch(authRequest.getSignMessage({ uuid }));
    }
  }, [isConnected, uuid, userData?.walletAddress, isReadyToSignMessage]);

  useEffect(() => {
    if (signature && uuid && connector) {
      if (type === SocialConnectionTypes.AUTH) {
        dispatch(
          authRequest.connectWallet({
            uuid,
            signature,
            mentorId,
            name: connector.name,
            id: connector.id,
            icon: connector.icon,
            uid: connector.uid,
          })
        );
      } else if (type === SocialConnectionTypes.LINK) {
        dispatch(
          authRequest.linkWallet({
            uuid,
            signature,
            name: connector.name,
            id: connector.id,
            icon: connector.icon,
            uid: connector.uid,
          })
        );
      }
    }
  }, [signature]);

  useEffect(() => {
    if (linkStatus === StatusTypes.Rejected) {
      clearConnection();
    } else {
      if (isUserAuthorized && linkStatus === StatusTypes.Resolved) {
        dispatch(userRequest.getUserData());
      }
    }
  }, [linkStatus]);

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          onClick: handleClickButton,
        });
      })}
    </>
  );
}
