import { useContext } from 'react';
import { Web3ModalContext } from '../../components/providers/Web3ModalProvider';

export function useWalletConnectStatus() {
  const { isConnected, error } = useContext(Web3ModalContext);
  if (!isConnected) {
    return { isConnected: false, error: 'WalletConnect not initialized' };
  }

  return {
    isConnected,
    error,
  };
}
