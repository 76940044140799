import cn from 'classnames';
import { Button } from '../../ui/buttons/Button';
import s from './style.module.scss';

function Popup({ className, children, title, onClose }) {
  return (
    <div className={cn(s.popup, className)}>
      <div className={s.container}>
        <div className={s.header}>
          {title && <p>title</p>}
          <Button className={cn(s.close, 'iconClose')} onClick={onClose} />
        </div>
        <div className={s.body}>{children}</div>
      </div>
    </div>
  );
}

export default Popup;
