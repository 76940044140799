import cn from 'classnames';
import s from './style.module.scss';

/*
 variants 
 - primary (green)
 - secondary (greyed)
 custom:
 - gradient
*/

function MainButton({ className, children, onClick, variant = 'primary' }) {
  return (
    <button
      className={cn(
        s.button,
        variant === 'secondary' && s.secondary,
        variant === 'gradient' && s.gradient,
        className
      )}
      onClick={onClick}>
      {children}
    </button>
  );
}

export default MainButton;
