import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { PageNames } from '../../../core/constants/PageNames';
import { RoutesName } from '../../../core/constants/Routes';
import { STATIC_TEXT } from '../../../core/constants/staticText';
import { isMobileSize } from '../../../core/helpers/isMobileDevice';
import { useCheckAuthorization } from '../../../core/hooks/useCheckAuthorization';
import { useRequestStaticText } from '../../../core/hooks/useRequestStaticText';
import { showAuthorizationWindow } from '../../../core/store/slices/modalWindowStateSlice';
import { UserPoints } from '../../UserPoints';
import { NavigationLink } from '../../ui/Navlink';
import DefaultButton from '../../uiNext/buttons/DefaultButton';
import logo from './../../../assets/images/logo.svg';
import shortLogo from './../../../assets/images/shortLogo.svg';
import { MenuBurger } from './MenuBurger';

import s from './style.module.scss';

export function Header({ className }) {
  const dispatch = useDispatch();
  const { text } = useRequestStaticText(PageNames.Header);
  const { isUserAuthorized } = useCheckAuthorization();
  const isMobile = isMobileSize('767px');

  return (
    <header className={cn(s.header, !isUserAuthorized && s.header__withLogo, className)}>
      <NavigationLink
        activeClassName={s.navigation__link_active}
        to={RoutesName.GuidesPage}>
        <img
          src={isMobile ? shortLogo : logo}
          alt='logo'
          className={cn(s.header__logo, !isUserAuthorized && s.header__logo__shown)}
        />
      </NavigationLink>
      <div className={cn(s.header__option, s.option)}>
        {isUserAuthorized && <UserPoints className={s.option__points} />}
        {isUserAuthorized && <MenuBurger className={s.option__menu} text={text} />}
        {!isUserAuthorized && (
          <DefaultButton
            className={s.option__login}
            onClick={() => dispatch(showAuthorizationWindow(true))}>
            {text?.buttonLogin || STATIC_TEXT[PageNames.Header].buttonLogin}{' '}
          </DefaultButton>
        )}
      </div>
    </header>
  );
}
