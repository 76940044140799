import { LocalStorageItems } from '../constants/LocalStorageItems';

export function clearLocalStorageItems() {
  Object.values(LocalStorageItems).forEach((item) => {
    localStorage.removeItem(item);
  });
}

export function clearRestLocalStorageItems() {
  const itemsToKeep = new Set(Object.values(LocalStorageItems));
  Object.keys(localStorage).forEach((key) => {
    if (!itemsToKeep.has(key)) {
      localStorage.removeItem(key);
    }
  });
}
