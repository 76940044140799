import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useAudioPage } from '../../core/hooks/useAudioPage';
import { userAction } from '../../core/store/slices/audioSettingsSlice';
import { AudioRate } from '../soundControls/AudioRate';
import { PlayButton } from '../soundControls/PlayButton/PlayButton';
import { SubtitleBlock } from '../ui/tags/SubtitleBlock';
import { TextWithBorder } from '../ui/tags/TextWithBorder';
import s from './style.module.scss';

export function BaseSubtitle({
  className,
  mascotVoice,
  mascotText,
  answerToMascot,
  audioBlock,
  audioSrc,
  text,
  keyText,
}) {
  const isUserAction = useSelector(userAction);
  const { audioState, changeAudioState, audio } = useAudioPage(audioSrc, isUserAction);

  return (
    <Subtitle
      className={cn(s.baseSubtitle, { [s.baseSubtitle_noAudio]: !audioBlock }, className)}
      mascotVoice={
        mascotVoice || (
          <>
            {audioBlock && (
              <div className={s.baseSubtitle__mascotVoice}>
                <PlayButton
                  className={cn(s.baseSubtitle__mascotVoice_play)}
                  onClick={() => changeAudioState()}
                  audioState={audioState}
                  isCurrentAudio={true}
                  disabled={!audio}
                />
                <AudioRate className={cn(s.baseSubtitle__mascotVoice_rate)} />
              </div>
            )}
          </>
        )
      }
      mascotText={
        mascotText || (
          <div className={s.baseSubtitle__mascotText}>
            <TextWithBorder text={text} keyText={keyText} />
          </div>
        )
      }
      answerToMascot={answerToMascot}
    />
  );
}

export function Subtitle({ className, mascotVoice, mascotText, answerToMascot }) {
  return (
    <SubtitleBlock className={className}>
      {mascotVoice}
      {mascotText}
      {answerToMascot}
    </SubtitleBlock>
  );
}
