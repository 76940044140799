import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusTypes } from '../constants/StatusTypes';
import { supportedChainsById } from '../constants/SupportedChains';
import { NETWORKS_BY_ID } from '../constants/networks';
import { rewardsState } from '../store/rewards/slice';
import { rewardsRequest } from '../store/rewards/thunk';
import { useClaimReward } from './contracts/referralProgram';

const STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  SUCCESS: 'success',
  ERROR: 'error',
};

export function useClaimAirdrop() {
  const dispatch = useDispatch();

  const [chainId, setChainId] = useState(undefined);

  // 'idle' | 'pending' | 'in_progress' | 'error' | 'success'
  const [status, setStatus] = useState(STATUS.IDLE);
  const isPendingClaim = status === STATUS.PENDING;
  const isProcessingClaim = status === STATUS.IN_PROGRESS;
  const isSuccessClaim = status === STATUS.SUCCESS;
  const isErrorClaim = status === STATUS.ERROR;

  const {
    data: { signReward },
    signRewardStatus,
  } = useSelector(rewardsState);

  const {
    call: callClaimReward,
    hashTx,
    receiptTx,
    isIdle,
    isPending,
    isSuccess,
    isRejected,
    isError,
    error,
  } = useClaimReward(chainId);

  const explorerUrl = useMemo(() => {
    if (NETWORKS_BY_ID[chainId] && hashTx) {
      return `${NETWORKS_BY_ID[chainId].blockExplorerUrl}/tx/${hashTx}`;
    } else if (supportedChainsById[chainId] && hashTx) {
      return `${supportedChainsById[chainId].blockExplorers.default.url}/tx/${hashTx}`;
    } else {
      return null;
    }
  }, [chainId, hashTx]);

  const doSignClaim = useCallback((questId, token) => {
    setStatus(STATUS.PENDING);
    dispatch(rewardsRequest.claim({ questId, token }));
  }, []);

  useEffect(() => {
    if (
      signRewardStatus === StatusTypes.Resolved &&
      signReward &&
      signReward.networkId &&
      chainId !== signReward.networkId
    ) {
      setChainId(signReward.networkId);
    }
  }, [signRewardStatus, signReward, chainId]);

  // call contract after sign claim
  useEffect(() => {
    if (!isPendingClaim || !chainId || !signReward) {
      return;
    }

    callClaimReward(signReward);
  }, [isPendingClaim, chainId, signReward]);

  // user has confirmed tx and tx is processing
  useEffect(() => {
    if (!hashTx) {
      return;
    }

    setStatus(STATUS.IN_PROGRESS);
  }, [hashTx]);

  // success
  useEffect(() => {
    if (isIdle || isPending || !isSuccess || !hashTx || !receiptTx) {
      return;
    }

    setStatus(STATUS.SUCCESS);
  }, [isIdle, isPending, isSuccess, hashTx, receiptTx]);

  // error
  useEffect(() => {
    if (isIdle || isPending || !isError) {
      return;
    }

    console.error('[CLAIM:AIRDROP:ERROR] Error : ', error.message);
    setStatus(STATUS.ERROR);
  }, [isIdle, isPending, isError]);

  return {
    doClaimAirdrop: doSignClaim,
    isPending: isPendingClaim,
    isProcessing: isProcessingClaim,
    isSuccess: isSuccessClaim,
    isRejected,
    isError: isErrorClaim,
    status,
    explorerUrl,
  };
}
