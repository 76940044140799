import { Box, Center, Flex, Image, useDisclosure } from '@chakra-ui/react';
import { Fragment, useMemo } from 'react';
import { PageNames } from '../../../core/constants/PageNames';
import { RoutesName } from '../../../core/constants/Routes';
import { STATIC_TEXT } from '../../../core/constants/staticText';
import { isMobileSize } from '../../../core/helpers/isMobileDevice';
import { useCheckAuthorization } from '../../../core/hooks/useCheckAuthorization';
import { useRequestStaticText } from '../../../core/hooks/useRequestStaticText';
import Drawer from '../../uiNext/Drawer';
import iconChat from './../../../assets/images/icons/chat.svg';
import iconCup from './../../../assets/images/icons/cup.svg';
import iconGame from './../../../assets/images/icons/game.svg';
import iconGuides from './../../../assets/images/icons/guides.svg';
import iconMore from './../../../assets/images/icons/more.svg';
import iconReferrals from './../../../assets/images/icons/referrals.svg';
import logo from './../../../assets/images/logo.svg';

import { NavItem } from './NavItem';

const ONE = 1;
const INDEX_TWO = 2;

const navItemTypes = {
  GAME: 'GAME',
  GUIDES: 'GUIDES',
  REFERRALS: 'REFERRALS',
  LEADERBOARD: 'LEADERBOARD',
  GUIDES_CHAT: 'GUIDES_CHAT',
};

const mobileItemTypes = [navItemTypes.GAME, navItemTypes.GUIDES, navItemTypes.REFERRALS];
const hiddenMobileItems = [navItemTypes.LEADERBOARD, navItemTypes.GUIDES_CHAT];
const publicItems = [navItemTypes.GAME, navItemTypes.GUIDES, navItemTypes.LEADERBOARD];

const staticText = STATIC_TEXT[PageNames.Navigation];

export function NavBar() {
  const { isUserAuthorized } = useCheckAuthorization();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { text } = useRequestStaticText(PageNames.Navigation);
  const isMobile = isMobileSize('480px');

  const navItems = useMemo(
    () => ({
      [navItemTypes.GAME]: {
        icon: iconGame,
        text: text?.game || staticText.game,
        route: RoutesName.GamePage,
      },
      [navItemTypes.GUIDES]: {
        icon: iconGuides,
        text: text?.guides || staticText.guides,
        route: RoutesName.GuidesPage,
      },
      [navItemTypes.REFERRALS]: {
        icon: iconReferrals,
        text: text?.referrals || staticText.referrals,
        route: RoutesName.ReferralPage,
      },
      [navItemTypes.LEADERBOARD]: {
        icon: iconCup,
        text: text?.leaderboard || staticText.leaderboard,
        route: RoutesName.ProfileLeaderboard,
      },
      [navItemTypes.GUIDES_CHAT]: {
        icon: iconChat,
        text: text?.myGuides || staticText.myGuides,
        route: RoutesName.GuidesChat,
      },
    }),
    [text]
  );

  const renderItem = (data, index, variant) => (
    <NavItem
      key={index}
      text={data.text}
      icon={data.icon}
      route={data.route}
      variant={variant || 'default'}
    />
  );

  return (
    <Box
      bg='background.900'
      w='200px'
      h='100%'
      sx={{
        position: 'fixed',
        bottom: '0',
        left: '0',
        zIndex: '1500',
        borderRight: '1px solid',
        borderColor: 'gray.600',
        '@media screen and (max-width: 1024px)': {
          w: '100%',
          h: 'fit-content',
          bottom: 0,
          borderRight: 'none',
          borderTop: '1px solid #636363',
        },
      }}>
      <Image
        p='16px  8px'
        src={logo}
        alt='logo'
        sx={{
          '@media screen and (max-width: 1024px)': {
            display: 'none',
          },
        }}
      />
      <Flex
        direction='column'
        p='8px'
        gap='8px'
        sx={{
          '@media screen and (max-width: 1024px)': {
            flexDirection: 'row',
            w: '100%',
            p: '0px',
          },
        }}>
        {!isUserAuthorized &&
          publicItems.map((type, index) => renderItem(navItems[type], index))}
        {isMobile &&
          isUserAuthorized &&
          mobileItemTypes.map((type, index) => (
            <Fragment key={index}>
              {renderItem(navItems[type], index)}
              {index === mobileItemTypes.length - ONE && (
                <Center w='100%' flex={1} role='button' onClick={() => onOpen()}>
                  <Image src={iconMore} alt='more' />{' '}
                </Center>
              )}
            </Fragment>
          ))}
        {!isMobile &&
          isUserAuthorized &&
          Object.values(navItems).map((item, index) => (
            <Fragment key={index}>
              {renderItem(item, index)}
              {index === INDEX_TWO && (
                <Box borderBottom='1px solid' borderColor='gray.600' />
              )}
            </Fragment>
          ))}
      </Flex>
      <Drawer isOpen={isOpen} onClose={onClose}>
        {hiddenMobileItems.map((type, index) =>
          renderItem(navItems[type], index, 'drawerItem')
        )}
      </Drawer>
    </Box>
  );
}
