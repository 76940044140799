import { useLocation } from 'react-router-dom';
import { toObject } from './locationQuery';

/**
 * React Hook to get the location query from react-router
 */
const useLocationQuery = () => {
  const location = useLocation();

  return toObject(location.search);
};

export default useLocationQuery;
