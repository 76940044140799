export const RoutesName = {
  Back: -1,
  Root: '/',
  GoogleOauthPage: '/oauth',
  TelegramOauthPage: '/auth/telegram',
  TwitterOauthPage: '/auth/callback/twitter',
  GuidesPage: '/',
  GuidesChat: '/chat',
  GuidesChatWithId: '/chat/:guideId',
  ProfileSettings: '/profile/settings',
  ProfileLeaderboard: '/profile/leaderboard',
  TermsAndConditions: '/terms-and-conditions',
  ReferralPage: '/referrals',
  GamePage: '/game',
};

Object.freeze(RoutesName);
