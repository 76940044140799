import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalStylesState, setScreenHeight } from '../store/slices/generalStylesSlice';

export function useScreenSizes() {
  const dispatch = useDispatch();
  const { height } = useSelector(generalStylesState);

  useEffect(() => {
    window.addEventListener('resize', setScreenSizes);
  }, []);

  function setScreenSizes() {
    dispatch(setScreenHeight(document.documentElement.clientHeight));
  }

  return { height };
}
