import cn from 'classnames';
import s from './style.module.scss';

/*colors
 - default (white)
 - error (red)
*/

function Link({ className, target, href, label, rel }) {
  return (
    <a className={cn(s.link, className)} target={target} href={href} rel={rel}>
      {label}
      <span className={'iconLink'} />
    </a>
  );
}

export default Link;
