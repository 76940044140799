import { PageNames } from './PageNames';
import { Socials } from './Socials';
import { TransactionStatuses } from './StatusTypes';

export const STATIC_TEXT = {
  [PageNames.Auth]: {
    title: 'Log in to Gydde',
    socialsTitle: 'Log in with social account',
    walletTitle: 'Log in with wallet',
    twitter: 'Twitter',
    google: 'Google',
    email: 'Email',
    metamask: 'Metamask',
  },
  [PageNames.Navigation]: {
    game: 'Game',
    guides: 'Guides',
    referrals: 'Referrals',
    leaderboard: 'Leaderboard',
    myGuides: 'My Guides',
  },
  [PageNames.Header]: {
    buttonLogin: 'Log in',
    buttonLogout: 'Log out',
    menuChat: 'Chat',
    menuGuides: 'Guides',
    menuReferralRewards: 'Referral rewards',
    menuGuidesRewards: 'Guides rewards',
    menuLeaderboard: 'Leaderboard',
    menuWallet: 'Wallet',
    menuAccountSettings: 'Account settings',
  },
  [PageNames.Hello]: {
    mascotTextHighlightedPart1: "Hello! I'm",
    mascotTextHighlightedPart2: 'Gydde',
    answerButtonText: 'Hi, Gydde!',
  },
  [PageNames.Game]: {
    energy: 'Energy',
  },
  [PageNames.GuidesGallery]: {
    subtitle:
      "Do you know what an exchange is? Don't worry, this is not a test and it g won't affect any result Don't dfhhhdfj Don't worry",
    buttonText: 'Get started',
    startButtonText: {
      inProgress: 'CONTINUE',
      completed: 'COMPLETED',
      new: 'Get Started',
      claim: 'Claim',
    },
    tabs: {
      active: 'Active',
      ended: 'Ended',
    },
    archiveButton: 'see results',
    guideStatus: {
      inProgress: 'in progress',
      completed: 'completed',
      new: 'new',
      claim: 'claim',
    },
    guideLevel: { beginner: 'beginner', medium: 'medium', advanced: 'advanced' },
    guideTime: 'min',
    guideTimeTitle: 'Time',
    guideRewardTitle: 'Your own reward',
    guideRewardCount: 'left',
    complexity: {
      EASY: 'easy',
      MEDIUM: 'medium',
      HARD: 'hard',
    },
    timer: {
      days: 'days',
      hours: 'hours',
      minutes: 'minutes',
    },
    btnShare: 'share',
    clueText: 'Uh-oh, complete this guide first',
    progressBarLabel: 'Days left',
    leftLabel: 'left',
    concludingResults: 'Concluding results',
    tryToClaimLater: 'Please try again to claim reward a bit later',
    takeReward: 'Take your reward',
  },
  [PageNames.GuideResults]: {
    resultsTitle: 'Results',
    rewardPool: 'Reward pool',
    totalPoints: 'Total points',
    totalParticipants: 'Total participants',
    usernameColumn: 'Username',
    usdtColumn: 'USDT',
    pointsColumn: 'G-points',
    explorerColumn: 'Explorer',
    rewardColumn: 'Reward',
  },
  [PageNames.GuidesChat]: {
    help: 'Help',
    helpMe: 'Help me',
    guideStatus: { inProgress: 'In Progress', completed: 'Completed', new: 'New' },
    actionText: { next: 'Next', select: 'Select an option' },
    inputPlaceholder: 'Enter a text',
    searchPlaceholder: 'Enter a text',
    searchTitle: 'My guides',
    leaderboardClue: '🌟 This is your place in the rating in the guide',
    leaderboardRewardClue: '🌟 This is real tokens',
    skipStepTitle: 'Not done',
    skipStepText:
      "Are you seriously thinking of skipping? You'd be totally missing out on a rad chance to score some points!",
    skipStepBtnDo: "I'll do it",
    skipStepBtnSkip: 'Skip',
    previewText: 'Select guide from left side',
    errorMsg: 'Uh oh. Seems like you broke it…',
    errorMsgDescription: {
      linkClick:
        'Just kidding! It looks like you missed the link. Click it and give it another try!',
      noSubscription:
        'Just kidding! You haven’t subscribed on proposed channel, or your attempt was unsuccessful. Please give it another go!',
      noLike:
        'Just kidding! You haven’t liked the proposed post, or your attempt was unsuccessful. Please give it another go!',
      notClaimed:
        'Just kidding! You haven’t claimed gas, or your attempt was unsuccessful. Please give it another go!',
      noWallet:
        'Just kidding! You haven’t added any social networks yet, or your attempt was unsuccessful. Please give it another go!',
      noTelegram:
        'Just kidding! You haven’t added your Telegram account yet, or your attempt was unsuccessful. Please give it another go!',
      noTwitter:
        'Just kidding! You haven’t added your Twitter account yet, or your attempt was unsuccessful. Please give it another go!',
      noSocial:
        'Just kidding! You haven’t added any social networks yet, or your attempt was unsuccessful. Please give it another go!',
      captcha:
        "Just kidding! You didn’t pass the reCAPTCHA verification. Please try reloading the page, ensure your VPN is turned off, and clear your browser's cache before giving it another shot!",
      AddTwitter:
        'Just kidding. We’re experiencing a problem on our end. Please try to connect Twitter again!',
      AddTelegram:
        'Just kidding. We’re experiencing a problem on our end. Please try to connect Telegram again!',
      SubscribeOnTwitter:
        'Just kidding. We’re experiencing a problem on our end. Please try to check Twitter subscription again!',
      SubscribeOnTelegram:
        'Just kidding. We’re experiencing a problem on our end. Please try to check Telegram subscription again!',
      LikeTwitterPost:
        'Just kidding. We’re experiencing a problem on our end. Please try to check for Twitter like again!',
      SendGas:
        'Just kidding. We’re experiencing a problem on our end. Please try to send gas again!',
      AddWallet:
        'Just kidding. We’re experiencing a problem on our end. Please try to connect wallet again!',
    },
    action: {
      twitter: 'Connect Twitter',
      telegram: 'Connect Telegram',
      wallet: 'Add wallet',
      referral: 'Invite friend',
      reward: 'View rewards',
      token: 'Add token',
    },
    label: {
      reward: 'reward',
      minutes: 'minutes',
    },
    button: {
      letsGo: "Let's go!",
    },
    complexity: {
      EASY: 'easy',
      MEDIUM: 'medium',
      HARD: 'hard',
    },
  },
  [PageNames.MailAuthorization]: {
    mailLabel: 'Email address',
    mailErrorText_pattern: 'Please enter a valid email address',
    mailPlaceholder: 'Enter email address',
    passwordLabel: 'Password',
    passwordErrorText: 'Incorrect email or password',
    passwordPlaceholder: 'Enter password',
    button: 'Log in',
  },
  [PageNames.Metamask]: {
    NoWallet: {
      statusText: 'No wallet detected',
      descriptionTextHighlightedPart1: 'I will show you',
      descriptionTextHighlightedPart2: 'how to create your first wallet.',
      buttonText: 'Show me!',
    },
    NoWalletAuth: {
      statusText: 'No wallet detected',
      descriptionText: 'Go back and try logging in with a different method',
      buttonText: 'Go back',
    },
    Connecting: {
      statusText: 'Connecting...',
      descriptionTextHighlightedPart1: 'Open the',
      descriptionTextHighlightedPart2: 'MetaMask',
      descriptionTextHighlightedPart3: 'browser extension and confirm connection to',
      descriptionTextHighlightedPart4: 'Gydde.com',
    },
    Error: {
      statusText: 'Connection error',
      descriptionText: 'Something went wrong',
      buttonText: 'Try again',
    },
    Sign: {
      statusText: 'Connecting...',
      descriptionTextHighlightedPart1: 'Please',
      descriptionTextHighlightedPart2: 'sign',
      descriptionTextHighlightedPart3: 'the message request in your wallet to continue.',
    },
    Connected: { statusText: 'Connected', descriptionText: 'Logging in' },
  },
  [PageNames.Profile]: {
    guidesTab: 'Guides rewards',
    ReferralTab: 'Referral rewards',
    settingsTab: 'Account settings',
    walletTab: 'Wallet',
    leaderboardTab: 'Leaderboard',
  },
  [PageNames.ProfileReferrals]: {
    adTitle: 'Invite friends and earn together',
    back: 'Back',
    rewardForCompletion: 'Reward for Guide completion',
    referralReward: '50% for each referral reward',
    friendReward: "25% for each referral's friend reward",
    howItWorks: 'How it works?',
    invite: 'Invite',
    Referral: {
      subtitle: 'If you invite more friends, you will get more rewards!',
    },
    NoReferral: {
      subtitle:
        "Imagine you invite 10 friends who also invite 10 friends and they complete 10 guides each. You will earn 900$. That's AWESOME!",
    },
    btnText: 'Claim',
    rewardsTitle: 'Unclaimed rewards',
    ReferralTitle: 'Referral guide completions',
    earningsTitle: 'Total rewards earned',
  },
  [PageNames.ProfileGuides]: {
    btnText: 'Claim',
    rewardsTitle: 'Unclaimed rewards',
    ReferralTitle: 'Referral guide completions',
    earningsTitle: 'Lifetime earnings',
    subtitle:
      "Well, if you didn't know, we still have a lot of guides that you haven't completed yet. Go ahead for knowledge!",
    buttonText: 'New guides',
  },
  [PageNames.ProfileSettings]: {
    userNameTitle: 'User name',
    socialTitle: 'Уour social account',
    connectGoogle: 'Connect Google Account',
    connectMail: 'Connect Email Address',
    connectTwitter: 'Connect Twitter Account',
    connectTelegram: 'Connect Telegram Account',
    connectWallet: 'Connect Wallet',
    setPass: 'Set password',
    changePass: 'Change password',
    logout: 'Log out',
    editMailTitle: 'Enter email address',
    editNameTitle: 'Name editing',
    setPassTitle: 'Set password',
    changePassTitle: 'Change password',
    fieldName: 'Enter your name',
    fieldMail: 'Enter email address',
    mailErrorText_pattern: 'Please enter a valid email address',
    fieldPass: 'Enter password',
    passwordErrorText:
      'Password must be at least 8 characters (latin letters, numbers or symbols)',
    fieldEnterPass: 'Enter password',
    passwordSetErrorText:
      'Password must be at least 8 characters (latin letters, numbers or symbols)',
    fieldRepeatPass: 'Repeat password',
    passwordRepeatErrorText: 'Passwords must be identical',
    fieldLastPass: 'Enter last password',
    passwordLastErrorText: 'Invalid password',
    fieldNewPass: 'Enter new password',
    passwordNewErrorText:
      'Password must be at least 8 characters (latin letters, numbers or symbols)',
    connectSocial: 'Connect with social account',
    socialGoogle: 'Google',
    btnSave: 'Save',
    preferredSocialTitle: 'Preferred social network',
    chooseSocialTitle: 'Choose preferred social network',
    btnChange: 'Change',
    usernameError: {
      general: 'Impossible to use this nickname. Please, try another one',
      required: 'This field is required',
      maxLength: 'Please use 16 symbols maximum',
      sameName: 'This name is already in use',
    },
  },
  [PageNames.ProfileWallet]: {
    title: 'Your wallet address',
    statusWallet: { connected: 'Connected', disconnected: 'Disconnect' },
    balanceTitle: 'Balance ETH',
    balanceValue: '0',
    networkTitle: 'Connected network',
    networkName: { mainnet: 'Ethereum', unknown: 'Unknown' },
    subtitle:
      'Every time I transfer a reward to your wallet, I write it down for you. So, nothing will be lost :)',
    subtitleNoWallet:
      "Well, you don't have a wallet connected. If you don't know how to connect a wallet just tell me and I will show you how to do it :)",
    connectButton: { connected: 'Disconnect', disconnected: 'Connect wallet' },
    buttonText: 'History',
    buttonShowMe: 'Show me',
  },
  [PageNames.ClaimReward]: {
    title: {
      noWallet: 'No wallet detected',
      connecting: 'Connecting...',
      error: 'Connection error',
      wrongNetwork: 'Switching network...',
    },
    noWallet: 'Link your wallet, please',
    walletNotConnected: 'Connect your wallet, please',
    switchNetwork: 'Switch your network, please',
    wrongWallet: 'Please connect linked wallet: ',
    connecting: 'Connection in progress',
    canceledConnection: 'You canceled your wallet connection',
    alreadyLinked: 'This wallet is linked to other account',
    walletConnected:
      'You have successfully connected your wallet! Now you can claim your reward',
    connectButton: 'Connect wallet',
    addButton: 'Link wallet',
    claimButton: 'Claim reward',
    tryAgainButton: 'Try Again',
  },
  [PageNames.Claim]: {
    title: 'Claim amount',
    transactionText: 'Transaction fee compensation',
    geoText: 'What is commission paid for?',
    payFeeFrom: 'Pay transaction fee from my wallet',
    btnText: 'Claim',
  },
  [PageNames.History]: {
    title: 'Transaction history',
    status: { pending: 'Pending', active: 'Confirmed', failed: 'Failed' },
    text: "You haven't had any transactions yet",
  },
  [PageNames.TransactionStatus]: {
    [TransactionStatuses.Initiated]: {
      title: 'Transaction initiated',
      text: 'Please, confirm transaction in your wallet',
    },
    [TransactionStatuses.Pending]: {
      title: 'Transaction is sent',
      text: 'You can check transaction status in history on Wallet page',
      btnText: 'Check',
    },
    [TransactionStatuses.Success]: {
      title: 'Transaction successful',
      text: 'Transaction was successfully processed on chain',
      btnText: 'Check',
    },
    [TransactionStatuses.Rejected]: {
      title: 'Transaction was rejected',
      text: 'Transaction was not send to chain',
      btnText: 'Check',
    },
    [TransactionStatuses.Error]: {
      title: 'Error',
      text: 'Something went wrong',
      btnText: 'Try again',
    },
    [TransactionStatuses.ErrorVerification]: {
      title: 'Error',
      text: "You have not passed the verification process. Don't worry, it happens to everyone",
      btnText: 'Try again',
    },
    [TransactionStatuses.NoWallet]: {
      title: 'Error',
      text: 'Your wallet is not connected.',
      btnText: 'Connect',
    },
    seeTransaction: 'See transaction',
  },
  [PageNames.Support]: {
    title: 'Support',
    subtitle:
      "Let's work together to identify and resolve your issue. Here's how you can help me to assist you:",
    text1:
      '1.Take a screenshot on the page where you see an error message or encounter a problem.',
    text2:
      '2.Open a chat with our support team and briefly describe the issue in your own words.',
    text3: '3.Attach the screenshot and copy the text bellow:',
  },
  [PageNames.SocialsOauth]: {
    error: 'Something went wrong',
    errorDescription: 'Please check your connection and try again.',
    errorButton: 'Go back',
    [Socials.Google]: {
      error: "Sorry, Google isn't loading right now.",
    },
    [Socials.Twitter]: {
      error: "Sorry, Twitter isn't loading right now.",
    },
    [Socials.Telegram]: {
      error: "Sorry, Telegram isn't loading right now.",
    },
  },
  [PageNames.ReferralLink]: {
    title: 'Share referral link',
    twitter: 'Twitter',
    telegram: 'Telegram',
    linkMessage: 'Exclusive way to get free G-Points and earn airdrops!',
    linkMessageDescription:
      'Complete quests, invite friends and explore the Web3 together',
  },
  [PageNames.PageNotFound]: {
    title: '404',
    subtitle: 'page not found',
    button: 'Go back home',
  },
  [PageNames.Reward]: {
    title: 'Your reward',
    description: 'You made good work 🔥',
    button: {
      claim: 'Claim reward',
      take: 'Take reward',
    },
  },
  [PageNames.Referrals]: {
    title: "Invite friends and get 50% of each friend's G-points",
    totalPoints: 'Total referrer G-points',
    yourFriends: 'Your friends',
    avgDaily: 'Avg daily G-points',
    inviteButton: 'Invite friends',
    noFriends: 'You don’t have any referral friends yet.',
    noFriendsDescription:
      "Invite friends using your link and get a bonus — 50% for every point they earn! Yes, you'll earn 50% of the G-points your friends receive. This systemis endless!",
    totalPointsTooltip:
      'Total amount of G-points you have earned by participating in the Referral program',
    avgDailyTooltip:
      'The average amount of G-points you earn per day by participating in the Referral program.',
    avgDailyTooltipIncludes:
      'Includes rewards for inviting friends and % of their G-points.',
    avgDailyTooltipCalculated: '*Calculated over the last month',
  },
  [PageNames.GamePopup]: {
    title: 'Get more points!',
    subtitle: 'Compete guides and MULTIPLY rewards! 🔥',
    reward: 'Reward x10',
    gPoints: '0 G-points',
    button: 'Go to guides',
  },
};
