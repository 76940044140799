import { lazy, Suspense } from 'react';
import ReactGA from 'react-ga4';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PageWrapper } from '../components/PageWrapper/PageWrapper';
import { RoutesName } from '../core/constants/Routes';
import { Socials } from '../core/constants/Socials';
import { useCheckAuthorization } from '../core/hooks/useCheckAuthorization';
import { usePageTracking } from '../core/hooks/usePageTracking';
import { LayoutMain } from '../layouts/LayoutMain';
import { PageNotFound } from '../pages/PageNotFound/PageNotFound';
import { TermsAndConditions } from '../pages/TermsAndConditions';
import './style.scss';

const GuidesPage = lazy(() => import('../pages/GuidesPage'));
const GuidesChat = lazy(() => import('../pages/GuidesChatPage'));
const GamePage = lazy(() => import('../pages/GamePage'));

const ProfileSettingsPage = lazy(() => import('../pages/profile/ProfileSettingsPage'));
const ProfileLeaderboardPage = lazy(
  () => import('../pages/profile/ProfileLeaderboardPage')
);
const ReferralPage = lazy(() => import('../pages/ReferralPage'));
const SocialsOauthPage = lazy(() => import('../pages/auth/SocialsOauthPage'));

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);

const privatePages = [
  { path: RoutesName.GuidesChat, component: GuidesChat },
  { path: RoutesName.GuidesChatWithId, component: GuidesChat },
  { path: RoutesName.ProfileSettings, component: ProfileSettingsPage },
  { path: RoutesName.ProfileLeaderboard, component: ProfileLeaderboardPage },
  { path: RoutesName.ReferralPage, component: ReferralPage },
  { path: RoutesName.GamePage, component: GamePage },
];

const socialPages = [
  {
    path: RoutesName.GoogleOauthPage,
    component: SocialsOauthPage,
    social: Socials.Google,
  },
  {
    path: RoutesName.TwitterOauthPage,
    component: SocialsOauthPage,
    social: Socials.Twitter,
  },
  {
    path: RoutesName.TelegramOauthPage,
    component: SocialsOauthPage,
    social: Socials.Telegram,
  },
];

export function App() {
  const { isUserAuthorized } = useCheckAuthorization();
  usePageTracking();

  return (
    <>
      <div id={'app'}>
        <Routes>
          <Route path={RoutesName.Root} element={<LayoutMain />}>
            <Route
              index
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <PageWrapper>
                    <GuidesPage />
                  </PageWrapper>
                </Suspense>
              }
            />
            {privatePages.map(({ path, component: Component }, index) => (
              <Route
                key={index}
                path={path}
                element={
                  isUserAuthorized ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <PageWrapper>
                        <Component />
                      </PageWrapper>
                    </Suspense>
                  ) : (
                    <Navigate to={RoutesName.Root} replace />
                  )
                }
              />
            ))}
          </Route>
          {socialPages.map(({ path, component: Component, social }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Component social={social} />{' '}
                </Suspense>
              }
            />
          ))}
          <Route path={RoutesName.TermsAndConditions} element={<TermsAndConditions />} />
          <Route path={'*'} element={<PageNotFound />} />
        </Routes>
      </div>
    </>
  );
}
