import { createSlice } from '@reduxjs/toolkit';
import { ItemNum } from '../../constants/ItemNum';
import { MetamaskConnectionStatuses, StatusTypes } from '../../constants/StatusTypes';
import { metamaskRequest } from './thunk';

const initialState = {
  connectionStatus: MetamaskConnectionStatuses.Connecting,
  account: null,
  balance: null,
  message: null,
  signedMessage: null,
  error: null,
  firstHighlightedItem: 1,

  transactions: [],
  transactionsStatus: null,
  transactionsError: null,

  chainId: null,
  networkId: null,
  neededChainId: null,
  neededNetworkId: null,
  tokenAddress: null,

  chainIdStatus: null,
  chainIdError: null,
  networkStatus: null,
  networkError: null,
  tokenStatus: null,
  tokenError: null,

  currentAction: null,
  targetAction: null,

  accounts: [],
  accountsStatus: null,
  accountsError: null,
};

export const metamaskSlice = createSlice({
  name: 'metamask',
  initialState,
  reducers: {
    setMetamaskConnectionStatus: (state, action) => {
      state.connectionStatus = action.payload;
    },
    setMetamaskMessage: (state, action) => {
      state.message = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setFirstHighlightedItem: (state, action) => {
      state.firstHighlightedItem = action.payload;
    },
    setNeededChainId: (state, action) => {
      state.neededNetworkId = action.payload;
      state.neededChainId = action.payload
        ? `0x${action.payload.toString(ItemNum.Hexidecimal)}`
        : null;
    },
    setTokenAddress: (state, action) => {
      state.tokenAddress = action.payload;
    },
    setCurrentAction: (state, action) => {
      state.currentAction = action.payload;
    },
    setTargetAction: (state, action) => {
      state.targetAction = action.payload;
    },
    clearNetworkError: (state) => {
      state.networkError = null;
    },
    resetMetamaskState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      //getAccount
      .addCase(metamaskRequest.getAccount.pending, (state) => {
        state.error = null;
      })
      .addCase(metamaskRequest.getAccount.fulfilled, (state, action) => {
        state.connectionStatus = MetamaskConnectionStatuses.Sign;
        state.account = action.payload.account;
        state.balance = action.payload.balance;
        state.networkId = action.payload.networkId;
      })
      .addCase(metamaskRequest.getAccount.rejected, (state, action) => {
        state.connectionStatus = MetamaskConnectionStatuses.Error;
        state.error = action.payload.message;
      })

      //signMessage
      .addCase(metamaskRequest.signMessage.pending, (state) => {
        state.error = null;
      })
      .addCase(metamaskRequest.signMessage.fulfilled, (state, action) => {
        state.connectionStatus = MetamaskConnectionStatuses.Connected;
        state.signedMessage = action.payload;
      })
      .addCase(metamaskRequest.signMessage.rejected, (state, action) => {
        state.connectionStatus = MetamaskConnectionStatuses.Error;
        state.error = JSON.stringify(action.payload);
      })

      //checkUser
      .addCase(metamaskRequest.sendAuthData.pending, (state) => {
        state.error = null;
      })
      .addCase(metamaskRequest.sendAuthData.fulfilled, (state) => {
        state.connectionStatus = MetamaskConnectionStatuses.Finish;
        state.message = null;
        state.signedMessage = null;
      })
      .addCase(metamaskRequest.sendAuthData.rejected, (state, action) => {
        state.connectionStatus = MetamaskConnectionStatuses.Error;
        state.error = action.payload;
      })

      //add metamask for authorized users
      .addCase(metamaskRequest.linkMetamask.pending, (state) => {
        state.error = null;
      })
      .addCase(metamaskRequest.linkMetamask.fulfilled, (state) => {
        state.connectionStatus = MetamaskConnectionStatuses.Finish;
        state.message = null;
        state.signedMessage = null;
      })
      .addCase(metamaskRequest.linkMetamask.rejected, (state, action) => {
        state.connectionStatus = MetamaskConnectionStatuses.Error;
        state.error = action.payload;
      })

      .addCase(metamaskRequest.getTransactions.pending, (state) => {
        state.transactionsStatus = StatusTypes.Loading;
        state.transactionsError = null;
      })
      .addCase(metamaskRequest.getTransactions.fulfilled, (state, action) => {
        state.transactionsStatus = StatusTypes.Resolved;
        state.transactions = action.payload;
        state.signedMessage = null;
      })
      .addCase(metamaskRequest.getTransactions.rejected, (state, action) => {
        state.transactionsStatus = StatusTypes.Rejected;
        state.transactionsError = action.payload.message;
      })

      .addCase(metamaskRequest.getChainId.pending, (state) => {
        state.chainIdStatus = StatusTypes.Loading;
        state.chainIdError = null;
      })
      .addCase(metamaskRequest.getChainId.fulfilled, (state, action) => {
        state.chainIdStatus = StatusTypes.Resolved;
        state.chainId = action.payload;
      })
      .addCase(metamaskRequest.getChainId.rejected, (state, action) => {
        state.chainIdStatus = StatusTypes.Rejected;
        state.chainIdError = action.payload.message;
      })

      .addCase(metamaskRequest.switchNetwork.pending, (state) => {
        state.networkStatus = StatusTypes.Loading;
        state.networkError = null;
      })
      .addCase(metamaskRequest.switchNetwork.fulfilled, (state) => {
        state.networkStatus = StatusTypes.Resolved;
        state.chainId = state.neededChainId;
        state.networkId = state.neededNetworkId;
      })
      .addCase(metamaskRequest.switchNetwork.rejected, (state, action) => {
        state.networkStatus = StatusTypes.Rejected;
        state.networkError = action.payload.code;
      })

      .addCase(metamaskRequest.addNewNetwork.pending, (state) => {
        state.networkStatus = StatusTypes.Loading;
        state.networkError = null;
      })
      .addCase(metamaskRequest.addNewNetwork.fulfilled, (state) => {
        state.networkStatus = StatusTypes.Resolved;
        state.chainId = state.neededChainId;
        state.networkId = state.neededNetworkId;
      })
      .addCase(metamaskRequest.addNewNetwork.rejected, (state, action) => {
        state.networkStatus = StatusTypes.Rejected;
        state.networkError = action.payload.code;
      })

      .addCase(metamaskRequest.addNewToken.pending, (state) => {
        state.tokenStatus = StatusTypes.Loading;
        state.tokenError = null;
      })
      .addCase(metamaskRequest.addNewToken.fulfilled, (state) => {
        state.tokenStatus = StatusTypes.Resolved;
      })
      .addCase(metamaskRequest.addNewToken.rejected, (state, action) => {
        state.tokenStatus = StatusTypes.Rejected;
        state.tokenError = action.payload.code;
      })

      .addCase(metamaskRequest.getAccounts.pending, (state) => {
        state.accountsStatus = StatusTypes.Loading;
        state.accountsError = null;
      })
      .addCase(metamaskRequest.getAccounts.fulfilled, (state, action) => {
        state.accountsStatus = StatusTypes.Resolved;
        state.accounts = action.payload;
      })
      .addCase(metamaskRequest.getAccounts.rejected, (state, action) => {
        state.accountsStatus = StatusTypes.Rejected;
        state.accountsError = action.payload.code;
      })

      .addCase(metamaskRequest.revokePermits.pending, (state) => {
        state.accountsStatus = StatusTypes.Loading;
        state.accountsError = null;
      })
      .addCase(metamaskRequest.revokePermits.fulfilled, (state) => {
        state.accountsStatus = StatusTypes.Resolved;
        state.accounts = [];
      })
      .addCase(metamaskRequest.revokePermits.rejected, (state, action) => {
        state.accountsStatus = StatusTypes.Rejected;
        state.accountsError = action.payload.code;
      });
  },
});

export const {
  setMetamaskConnectionStatus,
  setMetamaskMessage,
  setError,
  setFirstHighlightedItem,
  resetMetamaskState,
  setNeededChainId,
  setTokenAddress,
  setCurrentAction,
  setTargetAction,
  clearNetworkError,
} = metamaskSlice.actions;

/**
 *
 * @param {*} state
 * @returns {initialState}
 */
export const metamaskStore = (state) => state.metamask;
export const metamaskAccount = (state) => state.metamask.account;

export default metamaskSlice.reducer;
