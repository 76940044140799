import { createSlice } from '@reduxjs/toolkit';
import { StatusTypes } from '../../constants/StatusTypes';
import { guideRequest } from './thunk';

const initialState = {
  data: {
    guidesList: [],
    guidesArchived: [],
    clicker: null,
  },
  guideClueById: null,
  statusShowcaseData: null,
  statusUpdatePoints: null,
  errorShowcaseData: null,
  guideResultsById: null,
  statusGuideResults: null,
  errorGuideResults: null,
};

const processShowcaseData = ({ data, stateData }) => {
  return {
    ...stateData,
    guidesList: data.quests,
    clicker: data.user.clickerConfig,
  };
};

export const guideSlice = createSlice({
  name: 'guide',
  initialState,
  reducers: {
    showGuideClue: (state, action) => {
      state.guideClueById = action.payload;
    },
    clearGuideResults: (state) => {
      state.guideResultsById = null;
      state.statusGuideResults = null;
      state.errorGuideResults = null;
    },
    resetShowcaseState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      //guides gallery in Home page
      .addCase(guideRequest.guidesGallery.pending, (state) => {
        state.statusShowcaseData = StatusTypes.Loading;
        state.errorShowcaseData = null;
      })
      .addCase(guideRequest.guidesGallery.fulfilled, (state, action) => {
        state.statusShowcaseData = StatusTypes.Resolved;
        state.data.guidesList = action.payload;
      })
      .addCase(guideRequest.guidesGallery.rejected, (state, action) => {
        state.statusShowcaseData = StatusTypes.Rejected;
        state.errorShowcaseData = action.payload.message;
      })
      .addCase(guideRequest.guidesAvailable.pending, (state) => {
        state.statusShowcaseData = StatusTypes.Loading;
        state.errorShowcaseData = null;
      })
      .addCase(guideRequest.guidesAvailable.fulfilled, (state, action) => {
        state.statusShowcaseData = StatusTypes.Resolved;
        state.data.guidesList = action.payload;
      })
      .addCase(guideRequest.guidesAvailable.rejected, (state, action) => {
        state.statusShowcaseData = StatusTypes.Rejected;
        state.errorShowcaseData = action.payload.message;
      })
      .addCase(guideRequest.guidesArchived.pending, (state) => {
        state.statusShowcaseData = StatusTypes.Loading;
        state.errorShowcaseData = null;
      })
      .addCase(guideRequest.guidesArchived.fulfilled, (state, action) => {
        state.statusShowcaseData = StatusTypes.Resolved;
        state.data.guidesArchived = action.payload;
      })
      .addCase(guideRequest.guidesArchived.rejected, (state, action) => {
        state.statusShowcaseData = StatusTypes.Rejected;
        state.errorShowcaseData = action.payload.message;
      })
      //all guides for unregistered users
      .addCase(guideRequest.guidesAll.pending, (state) => {
        state.statusShowcaseData = StatusTypes.Loading;
        state.errorShowcaseData = null;
      })
      .addCase(guideRequest.guidesAll.fulfilled, (state, action) => {
        state.statusShowcaseData = StatusTypes.Resolved;
        state.data.guidesList = action.payload;
      })
      .addCase(guideRequest.guidesAll.rejected, (state, action) => {
        state.statusShowcaseData = StatusTypes.Rejected;
        state.errorShowcaseData = action.payload.message;
      })
      .addCase(guideRequest.getData.pending, (state) => {
        state.statusShowcaseData = StatusTypes.Loading;
        state.errorShowcaseData = null;
      })
      .addCase(guideRequest.getData.fulfilled, (state, action) => {
        state.statusShowcaseData = StatusTypes.Resolved;
        state.data = processShowcaseData({ data: action.payload, stateData: state.data });
      })
      .addCase(guideRequest.getData.rejected, (state, action) => {
        state.statusShowcaseData = StatusTypes.Rejected;
        state.errorShowcaseData = action.payload.message;
      })
      .addCase(guideRequest.updatePoints.pending, (state) => {
        state.statusUpdatePoints = StatusTypes.Loading;
        state.errorShowcaseData = null;
      })
      .addCase(guideRequest.updatePoints.fulfilled, (state, action) => {
        state.statusUpdatePoints = StatusTypes.Resolved;
        state.data.clicker = action.payload;
      })
      .addCase(guideRequest.updatePoints.rejected, (state, action) => {
        state.statusUpdatePoints = StatusTypes.Rejected;
        state.errorShowcaseData = action.payload.message;
      })
      .addCase(guideRequest.getResultsById.pending, (state) => {
        state.statusGuideResults = StatusTypes.Loading;
        state.errorGuideResults = null;
      })
      .addCase(guideRequest.getResultsById.fulfilled, (state, action) => {
        state.statusGuideResults = StatusTypes.Resolved;
        state.guideResultsById = action.payload;
      })
      .addCase(guideRequest.getResultsById.rejected, (state, action) => {
        state.statusGuideResults = StatusTypes.Rejected;
        state.errorGuideResults = action.payload.message;
      });
  },
});

export const { showGuideClue, clearGuideResults, resetShowcaseState } =
  guideSlice.actions;

/**
 *
 * @param {*} state
 * @returns {initialState}
 */
export const guideState = (state) => state.guide;

export default guideSlice.reducer;
