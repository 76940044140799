import cn from 'classnames';
import s from './style.module.scss';

/*colors
 - default (white)
 - red
 - green
*/

function PopupTitle({ className, label, color = 'default' }) {
  return (
    <h2
      className={cn(
        s.title,
        color === 'red' && s.red,
        color === 'green' && s.green,
        className
      )}>
      {label}
    </h2>
  );
}

export default PopupTitle;
