export const AuthorizationTypes = {
  NotChosen: 'NOT_CHOSEN',
  AuthMail: 'AUTH_MAIL',
  AuthMetaMask: 'AUTH_METAMASK',
  AuthConnectWallet: 'AUTH_CONNECT_WALLET',
  AuthGoogle: 'AUTH_GOOGLE',
  AuthTwitter: 'AUTH_TWITTER',
  LinkMetamask: 'LINK_METAMASK',
  AuthTelegram: 'AUTH_TELEGRAM',
};

export const SocialConnectionTypes = {
  AUTH: 'AUTH',
  LINK: 'LINK',
};

Object.freeze(AuthorizationTypes);
Object.freeze(SocialConnectionTypes);
