import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  height: document.documentElement.clientHeight,
};

export const generalStylesSlice = createSlice({
  name: 'generalStyles',
  initialState,
  reducers: {
    setScreenHeight: (state, action) => {
      state.height = action.payload;
    },
  },
});

export const { setScreenHeight } = generalStylesSlice.actions;
export const generalStylesState = (state) => state.generalStyles;

export default generalStylesSlice.reducer;
