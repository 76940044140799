import { createSlice } from '@reduxjs/toolkit';
import { referralsRequest } from './thunk';

const initialState = {
  referralsList: [],
  link: null,
  error: null,
};

const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {
    resetReferralsState: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(referralsRequest.getReferrals.pending, (state) => {
        state.error = null;
      })
      .addCase(referralsRequest.getReferrals.fulfilled, (state, action) => {
        state.referralsList = action.payload;
      })
      .addCase(referralsRequest.getReferrals.rejected, (state, action) => {
        state.error = action.payload.error;
      })
      .addCase(referralsRequest.getLink.pending, (state) => {
        state.error = null;
      })
      .addCase(referralsRequest.getLink.fulfilled, (state, action) => {
        state.link = action.payload;
      })
      .addCase(referralsRequest.getLink.rejected, (state, action) => {
        state.error = action.payload.error;
      });
  },
});

export const { resetReferralsState } = referralsSlice.actions;
export const referralsState = (state) => state.referrals;

export default referralsSlice.reducer;
