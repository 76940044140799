/* eslint-disable no-magic-numbers */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { createContext, useEffect, useState } from 'react';

import { WagmiProvider } from 'wagmi';
import { supportedChains } from '../../../core/constants/SupportedChains';
import { clearRestLocalStorageItems } from '../../../core/helpers/clearLocalStorage';

export const Web3ModalContext = createContext();

const projectId = process.env.REACT_APP_WALLET_CONNECT_ID;

const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const wagmiConfig = defaultWagmiConfig({
  chains: supportedChains,
  projectId,
  metadata,
});

export function Web3ModalProvider({ children }) {
  const [web3Modal, setWeb3Modal] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const queryClient = new QueryClient();
  useEffect(() => {
    function initializeWeb3Modal() {
      try {
        const modal = createWeb3Modal({
          wagmiConfig,
          projectId,
          enableAnalytics: true,
          enableOnramp: true,
          themeVariables: {
            '--w3m-z-index': 1700,
          },
        });
        setWeb3Modal(modal);
        setRetryCount(0);
        setIsConnected(true);
      } catch (error) {
        clearRestLocalStorageItems();
        setError(error.message || 'Failed to initialize WalletConnect');
        console.error('Failed to initialize WalletConnect:', error);
        setIsConnected(false);
        const nextRetryCount = retryCount + 1;
        const retryDelay = Math.min(10000, 1000 * 2 ** nextRetryCount); // Exponential backoff

        setTimeout(() => {
          setRetryCount(nextRetryCount);
          initializeWeb3Modal();
        }, retryDelay);
      }
    }

    initializeWeb3Modal();
  }, [retryCount]);

  return (
    <Web3ModalContext.Provider value={{ web3Modal, isConnected, error }}>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </WagmiProvider>
    </Web3ModalContext.Provider>
  );
}
